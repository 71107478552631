import React from "react";
import { Redirect } from "react-router-dom";
import { AuthenticationComponent } from "../../components/AuthenticationComponent";
import Logo from "../../assets/img/iconsGradient.png";
import { connect } from "react-redux";
import LoginFormSendValidation from "../../components/Forms/Login";
import LoginFormValidationWithPassword from "../../components/Forms/Login/validationwithPassword";
import ModalGlobal from "../../components/Modals";

const Login = ({ isAuthenticated, success, validate, error }) => {
  if (isAuthenticated) {
    return <Redirect to="/Main" />;
  }

  return (
    <AuthenticationComponent>
      <div className="login-aside w-50 d-flex align-items-center justify-content-center bg-white">
        <div className="container container-login container-transparent animated fadeIn">
          <div className="login-form">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: 20,
                marginTop: -50,
              }}
            >
              <img width="100" height="100" src={Logo} alt="logo" />
            </div>

            <h1 className="text-center">Novem</h1>
            {validate ? (
              <p>esta cargando</p>
            ) : error ? (
              <p>hay un error</p>
            ) : success ? (
              <LoginFormValidationWithPassword />
            ) : (
              <LoginFormSendValidation />
            )}
          </div>
        </div>
      </div>
      <ModalGlobal />
    </AuthenticationComponent>
  );
};

const mapStateToProps = (state) => ({
  validate: state.user.validation,
  success: state.user.validationSuccess,
  error: state.user.validationError,
  isAuthenticated: state.user.isAuthenticated,
});

export default connect(mapStateToProps)(Login);
