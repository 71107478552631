import React, { Component } from "react";

import PanelHeader from "./PanelHeader";
import UserRegistry from "./UserStatics/UserRegistry";
import UsersLocation from "./UsersLocation";
import DashboardCards from "./DashboardCards";

class Main extends Component {
  render() {
    return (
      <div className="content">
        <div className="panel-header bg-primary-gradient">
          <PanelHeader />
        </div>
        <div className="page-inner mt--5">
          <DashboardCards />
          <UserRegistry />
          <UsersLocation />
          
        </div>
      </div>
    );
  }
}

export default Main;
