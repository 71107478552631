import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";

import { connect } from "react-redux";
import { closeModal } from "../../actions/modal_actions";
import { bindActionCreators } from "redux";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { submit, reset } from "redux-form";

const override = css`
  display: block;
  margin: 0 auto;
`;

function index({
  modalProps,
  closeModal,
  title,
  children,
  isFeching,
  submit,
  className,
  send,
  errorProp,
  message,
  resetValue,
  reset,
  onClose,
  itemClassName
}) {
  const [modal, setmodal] = useState(false);
  const [loading, setloading] = useState(false);
  const [error, seterror] = useState(false);
  useEffect(() => {
    if (modalProps !== modal) setmodal(modalProps);

    if (errorProp !== error) {
      seterror(errorProp);
    }
    if (isFeching !== loading) {
      setloading(isFeching);
    }
  }, [modalProps, isFeching, errorProp]);

  return (
    <Modal
      show={modal}
      onHide={() => {
        if (onClose) onClose();

        closeModal();
      }}
      aria-labelledby="example-modal-sizes-title-xl"
      dialogClassName={className}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          <h4
            style={{
              color: "#19769f",
              fontWeight: "bold"
            }}
          >
            {title}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {error ? (
          <div> {message} </div>
        ) : loading ? (
          <GridLoader css={override} color={"#19769F"} loading={loading} />
        ) : (
          children
        )}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            className="btn btn-link"
            style={{ backgroundColor: "#cecece" }}
            onClick={() => {
              if (onClose) onClose();
              closeModal();
              if (resetValue) reset("notificationForm");
            }}
          >
            Cancelar
          </button>
          {!loading && send && (
            <button
              className="btn btn-sm"
              style={{ backgroundColor: "#19769F" }}
              onClick={() => {
                submit("notificationForm");
              }}
            >
              Enviar
            </button>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
}
const mapStateToProps = state => ({
  modalProps: state.modal.modal,
  title: state.modal.title,
  className: state.modal.className,
  send: state.modal.send,
  resetValue: state.modal.reset,
  isFeching: state.modal.isFeching,
  errorProp: state.modal.error,
  message: state.modal.message,
  itemClassName: state.modal.itemClassName
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ closeModal, submit, reset }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(index);
