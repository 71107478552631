import * as api from "../api/api_admin";
import { transactionConstants } from "../constansts/transaction.constants";
import { loading, getSuccess, getFailure } from "../actions/modal_actions";

export const getTransaction = data => {
  return { type: transactionConstants.TRANSTATION_LIST, data };
};

export const transactionAll = () => {
  let url = "transaction/all";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestToken(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getTransaction(result.data));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const transactionApproveOrReject = (body, type) => {
  let url = "transaction/" + type;

  console.log(url, "sja");
  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(body, url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          let noticeList = [...getState().transaction.data];
          let status;
          switch (type) {
            case "approve":
              status = "approved";

              break;
            case "reject":
              status = "rejected";

              break;

            default:
              status = "Por definir";
              break;
          }

          let resultNotice = noticeList.findIndex(value => {
            return value._id === body.transaction_id;
          });

          noticeList[resultNotice].status = status;

          dispatch(getTransaction(noticeList));

          dispatch(getSuccess());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {
        dispatch(getFailure(e));
      }
    });
  };
};
