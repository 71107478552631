import { medicalConstants } from "../constansts/medica.constants";
import { getSuccess, getPaginator } from "./paginator_actions";
import * as api from "../api/api_admin";
import { getfilter, filterLoading, getfilterTotal } from "./filter_actions";

import {
  loading,
  getSuccess as getSuccessModal,
} from "../actions/modal_actions";

export const getMedicalList = (data) => {
  return { type: medicalConstants.MEDICAL_LIST, data };
};

export const loadingMedicalList = () => {
  return { type: medicalConstants.LOADING_MEDICAL_LIST };
};

export const getMedicalAll = (data) => {
  return { type: medicalConstants.MEDICAL_ALL, data };
};
export const listMedicals = (body) => {
  let url = "medical/list";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medicalListNew = result.data.list_medical
          dispatch(getMedicalList(medicalListNew));
          dispatch(getMedicalAll(result.data.total));

          dispatch(getPaginator(result.data));
        }
      } catch (e) {}
    });
  };
};

export const filterListMedicals = (data) => {
  let url = "medical/list";
  return (dispatch, getState) => {
    dispatch(filterLoading());
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          let medicalListNew = result.data.list_medical

          dispatch(getfilterTotal(result.data.total));
          dispatch(getfilter(medicalListNew));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const listMedicalPagination = (data) => {
  let url = "medical/list";
  return (dispatch, getState) => {
    let token = getState().user.token;

    let listMedical = getState().medical.medicalList;

    const request = api.requestOfUserAdmin(data, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          let listPush = [...listMedical];

          listPush = listPush.concat(result.data.list_medical);

          result.data.list_medical = listPush;

          let medicalListNew = result.data.list_medical
          dispatch(getMedicalList(medicalListNew));
          dispatch(getSuccess());
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const verificateOffice = (data, medical_id) => {
  let body = {
    office_id: data._id,
    medical_id: medical_id,
  };
  let url = "office/verificate";

  return (dispatch, getState) => {
    let token = getState().user.token;
    let medicalList = getState().medical.medicalList;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medical = [...medicalList];
          let resultMedical = medicalList.findIndex((value) => {
            return value._id === medical_id;
          });

          let resultOffice = medical[resultMedical].medical.offices.findIndex(
            (value) => {
              return value._id === data._id;
            }
          );

          medical[resultMedical].medical.offices[resultOffice] = result.data;
          dispatch(getMedicalList(medical));
        }
      } catch (e) {}
    });
  };
};

export const rejectOffice = (data, medical_id) => {
  let body = {
    office_id: data._id,
    medical_id: medical_id,
  };
  let url = "office/reject";

  return (dispatch, getState) => {
    let token = getState().user.token;
    let medicalList = getState().medical.medicalList;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medical = [...medicalList];
          let resultMedical = medicalList.findIndex((value) => {
            return value._id === medical_id;
          });

          let resultOffice = medical[resultMedical].medical.offices.findIndex(
            (value) => {
              return value._id === data._id;
            }
          );

          medical[resultMedical].medical.offices[resultOffice] = result.data;
          dispatch(getMedicalList(medical));
        }
      } catch (e) {}
    });
  };
};

export const verificateRejectSpecialties = (data, medical_id, type) => {
  let body = {
    specialty_id: data.name._id,
    medical_id: medical_id,
  };

  let url = "specialty/" + type;

  return (dispatch, getState) => {
    let token = getState().user.token;
    let medicalList = getState().medical.medicalList;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medical = [...medicalList];
          let resultMedical = medicalList.findIndex((value) => {
            return value._id === medical_id;
          });

          let resultSpecialty = medical[
            resultMedical
          ].medical.specialties.findIndex((value) => {
            return value.name._id === data.name._id;
          });

          medical[resultMedical].medical.specialties[resultSpecialty] =
            result.data;
          dispatch(getMedicalList(medical));
        }
      } catch (e) {}
    });
  };
};

export const verificateRejectSchedules = (data, medical_id, type) => {
  let body = {
    schedule_id: data._id,
    medical_id: medical_id,
  };

  let url = "schedule/" + type;

  return (dispatch, getState) => {
    let token = getState().user.token;
    let medicalList = getState().medical.medicalList;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medical = [...medicalList];
          let resultMedical = medicalList.findIndex((value) => {
            return value._id === medical_id;
          });

          let resultSchedules = medical[
            resultMedical
          ].medical.schedules.findIndex((value) => {
            return value._id === data._id;
          });

          medical[resultMedical].medical.schedules[resultSchedules] =
            result.data;
          dispatch(getMedicalList(medical));
        }
      } catch (e) {}
    });
  };
};

export const verificateRejectDocuments = (data, medical_id, type) => {
  let body = {
    document_id: data._id,
    medical_id: medical_id,
  };

  let url = "document/" + type;
  return (dispatch, getState) => {
    let token = getState().user.token;
    let medicalList = getState().medical.medicalList;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medical = [...medicalList];
          let resultMedical = medicalList.findIndex((value) => {
            return value._id === medical_id;
          });

          let resultDocuments = medical[
            resultMedical
          ].medical.documents.findIndex((value) => {
            return value._id === data._id;
          });

          medical[resultMedical].medical.documents[resultDocuments] =
            result.data;
          dispatch(getMedicalList(medical));
        }
      } catch (e) {}
    });
  };
};

export const verificateRejectMedical = (body, type) => {
  let url = "medical/" + type;

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;
    let medicalList = getState().medical.medicalList;
    let filterList = getState().filter.filterList;
    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let medical = [...medicalList];
          let resultMedical = medicalList.findIndex((value) => {
            return value._id === body.medical_id;
          });

          medical[resultMedical].medical = result.data;

          if (filterList.length > 0) {
            let dataFilter = [...filterList];
            let resultFilter = dataFilter.findIndex((value) => {
              return value._id === body.medical_id;
            });
            dataFilter[resultFilter].medical = result.data;
            dispatch(getfilter(dataFilter));
          }

          dispatch(getMedicalList(medical));
          dispatch(getSuccessModal());
        }
      } catch (e) {}
    });
  };
};
