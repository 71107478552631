import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { onSubmitSuspend } from "./onSubmit";

function suspendUser(props) {
  const { handleSubmit, data, change, type } = props;

  const [state, setstate] = useState("rechazar");
  useEffect(() => {
    if (data._id) {
      change("_id", data._id);
      change("type", type);

      if (type === "verificate") setstate("verificar");
    }
  }, [data]);
  return (
    <form onSubmit={handleSubmit}>
      <div>
        ¿Estás seguro de que deseas {state} al doctor {data.name}{" "}
        {data.lastname}?
      </div>
    </form>
  );
}

export default reduxForm({
  form: "notificationForm",
  onSubmit: onSubmitSuspend,
})(suspendUser);
