import React, { useState, useEffect } from "react";
import { connect } from "react-redux"; 
import { compose } from "redux";
import { bindActionCreators } from "redux";
import {
  listUsersPagination,
  arrayPagination,
} from "../../actions/user_actions";
import { listMedicalPagination } from "../../actions/medical_actions";
import { getPagSelect, getNotSuccess } from "../../actions/paginator_actions";
const pagination = ({
  listUsersPagination,
  getPagSelect,
  isFeching,
  arrayPagination,
  search,
  getNotSuccess,
  pag,
  success,
  filterList,
  data,
  all,
  filterAll,
  type,
  listMedicalPagination,
}) => {
  const limitSearch = 30;

  const [state, setstate] = useState(0);

  const [loading, setloading] = useState(false);
  const [paginas, setpaginas] = useState(9);
  const [list, setlist] = useState(data);
  const [listAllPaginator, setlistAllPaginator] = useState(all);

  useEffect(() => {
    let listAll = all;
    if (success) {
      listAll = filterAll;
    } else {
      listAll = all;
    }
    setlistAllPaginator(listAll);
    let validate = Math.ceil(listAll / 10);

    if (Math.ceil(listAll / 10) !== paginas) {
      if (validate < paginas) {
        setpaginas(Math.ceil(listAll / 10));
      } else if (paginas < 9) {
        if (validate !== paginas) {
          setpaginas(9);
        }
      }
    }

    if (search.length === 1) {
      if (type === "user") listUsersPagination(search[0]);
      else {
        listMedicalPagination(search[0]);
      }
    } else if (isFeching !== loading) {
      setloading(isFeching);
      if (search.length >= 1) {
        if (isFeching) {
          getNotSuccess();
          if (type === "user") listUsersPagination(search[0]);
          else {
            listMedicalPagination(search[0]);
          }
        }
      } else {
        getNotSuccess();
      }
    } else if (success) {
      setlist(filterList);
    }
  }, [isFeching, search, data, all]);
  const newData = (index) => {
    if (!success) {
      let validate = index * limitSearch;

      if (validate > state) { 
        arrayPagination(validate - state);
        setstate(validate);
      } else {
      }
    }
    getPagSelect(index);
  };

  const btnCurrent = list.length > 0 && (
    <li
      className="paginate_button page-item previous"
      id="multi-filter-select_previous"
    >
      <button className="page-link" onClick={() => {}}>
        <i className="fas fa-angle-double-left" style={{ color: "#009688" }} />
      </button>
    </li>
  );

  const btnSiguiente = list.length !== paginas - 1 && (
    <li
      className="paginate_button page-item next"
      id="multi-filter-select_next"
    >
      <button className="page-link" onClick={() => {}}>
        <i className="fas fa-angle-double-right" style={{ color: "#009688" }} />
      </button>
    </li>
  );

  const button_number_function = () => {
    const button_number = [];

    let init = paginas - 9;
    if (init < 0) init = 0;

    for (let i = init; i < paginas; i++) {
      button_number.push(
        <li
          className={
            pag === i + 1
              ? "paginate_button page-item  active"
              : "paginate_button page-item "
          }
          key={i}
        >
          <button
            className="page-link"
            onClick={() => {
              if (pag !== i + 1) {
                if (i + 1 > paginas - 5) {
                  if (i + 5 < Math.ceil(listAllPaginator / 10))
                    setpaginas(i + 5);
                } else if (i + 1 <= paginas - 5) {
                  let restar = paginas - 5 - i;
                  let all = paginas - restar;
                  if (all < 9) all = 9;
                  if (Math.ceil(listAllPaginator / 10) < all)
                    all = Math.ceil(listAllPaginator / 10);
                  setpaginas(all);
                }
                newData(i + 1);
              }
            }}
          >
            {i + 1}
          </button>
        </li>
      );
    }

    return button_number;
  };

  return (
    <ul className="pagination">
      {Math.ceil(listAllPaginator / 10) > 1 && (
        <>
          {btnCurrent}
          {button_number_function()}
          {btnSiguiente}
        </>
      )}
    </ul>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    isFeching: state.pagination.success,
    search: state.pagination.search,
    pag: state.pagination.pagSelect,
    success: state.filter.success,
    filterList: state.filter.filterList,
    filterAll: state.filter.filterAll,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      listUsersPagination,
      getPagSelect,
      arrayPagination,
      getNotSuccess,
      listMedicalPagination,
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  pagination
);
