import React, { Component } from "react";

export default class footer extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="container-fluid">
          <nav className="pull-left">
            <ul className="nav">
              <li className="nav-item">
                <a className="nav-link" href="https://dev.novemapp.com">
                  NovemApp
                </a>
              </li>
            </ul>
          </nav>
          <div className="copyright ml-auto">
            2020, Hecho con <i className="fa fa-heart heart text-danger"></i> by{" "}
            <a href="https://dev.novemapp.com">NovemApp</a>
          </div>
        </div>
      </footer>
    );
  }
}
