import React from "react";
import { Table } from "react-bootstrap";

let insurance = (props) => {
  return (
    <div>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>Contrato</th>
            <th>Aseguradora</th>
            <th>Tipo</th>
            <th>Cobertura</th>
            <th>Deducible</th>
          </tr>
        </thead>
        <tbody>
          {props.data.insurances.length > 0 &&
            props.data.insurances.map((value, index) => {
              return (
                <tr key={index}>
                  <td>{value.contract_number}</td>
                  <td>{value.carrier}</td>
                  <td>{value.type_insurance}</td>
                  <td>
                    {value.coverage} {value.currency.toUpperCase()}
                  </td>
                  <td>{value.deductible}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default insurance;
