import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Modal } from "react-bootstrap";

import DetailDoctorForm from "./detailsFormDoctor";
import OfficesDetails from "./offices";
import SpecialtiesDetails from "./specialtiesDetails";
import SchedulesDetails from "./schedulesDetails";
import DocumentsDetails from "./documentsDetails";
import moment from "moment";
import { bindActionCreators } from "redux";
import { verificateRejectMedical } from "../../actions/medical_actions";
import ModalGlobal from "../Modals";

import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { openModal } from "../../actions/modal_actions";
import SuspendActiveMedical from "./suspendActiveMedical";
import Pagination from "../GeneralInquiry/pagination";
import FilterFormModal from "../GeneralInquiry/filterAll";
import { filterReset } from "../../actions/filter_actions";
import { getPagSelectReset } from "../../actions/paginator_actions";
import ExportUser from "../GeneralInquiry/exportUser";
import SuspendActiveUser from "../GeneralInquiry/suspendActiveUser";
import { permissionsValidate } from "../../functions";

const override = css`
  display: inline-block;
  margin: 0 auto;
`;
class DoctorTable extends Component {
  componentDidMount() {
    let searchModule = permissionsValidate(this.props.permissions, "medical");
    if (searchModule >= 0)
      this.setState({
        permissions: this.props.permissions[searchModule].action,
      });
    this.props.getPagSelectReset();
    this.props.filterReset();
  }

  state = {
    item: null,
    delete: false,
    count: 0,
    success: false,
    status: false,
    medicalList: [],
    medicalAll: this.props.medicalAll,
    isFechingFilter: false,
    isFechingMedical: false,
    permissions: [],
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isFechingFilter !== prevState.isFechingMedical) {
      let obj = { isFechingMedical: nextProps.isFechingFilter };

      if (nextProps.success) {
        obj.medicalList = nextProps.filterList;
      } else {
        obj.medicalList = nextProps.medicalList;
        obj.medicalAll = nextProps.medicalAll;
      }

      return obj;
    } else if (
      nextProps.isFechingMedical !== prevState.isFechingMedical &&
      !nextProps.isFechingFilter
    ) {
      return { isFechingMedical: nextProps.isFechingMedical };
    } else if (
      nextProps.medicalList.length !== prevState.medicalList.length &&
      !nextProps.success
    ) {
      let obj = {
        medicalList: nextProps.medicalList,
        medicalAll: nextProps.medicalAll,
      };
      if (nextProps.isFechingMedical !== prevState.isFechingMedical)
        obj.isFechingMedical = nextProps.isFechingMedical;

      return obj;
    } else if (nextProps.status !== prevState.status) {
      let obj = { status: nextProps.status };
      if (nextProps.status) {
        if (!nextProps.success) {
          obj.medicalList = nextProps.medicalList;
          obj.medicalAll = nextProps.medicalAll;
        } else {
          obj.medicalList = nextProps.filterList;
        }
      }

      return obj;
    } else if (nextProps.medicalAll !== prevState.medicalAll) {
      return { medicalAll: nextProps.medicalAll };
    }

    return null;
  }

  bodyModal() {
    switch (this.state.type) {
      case "detaill":
        return <DetailDoctorForm data={this.state.item} />;
      case "office":
        return <OfficesDetails data={this.state.item} />;
      case "specialties":
        return <SpecialtiesDetails data={this.state.item} />;
      case "schedules":
        return <SchedulesDetails data={this.state.item} />;
      case "documents":
        return <DocumentsDetails data={this.state.item} />;

      case "activeMedical":
        return (
          <SuspendActiveMedical data={this.state.item} type={"verificate"} />
        );
      case "rejectedMedical":
        return <SuspendActiveMedical data={this.state.item} type={"reject"} />;

      case "filterAll":
        return <FilterFormModal type={"doctor"} />;

      case "exportUser":
        return <ExportUser data={this.state.item} type="medical" />;

      case "passwordReset":
        return <SuspendActiveUser data={this.state.item} type={"reset"} />;

      default:
        break;
    }
  }

  actionsOffice = (value, status) => {
    let type;
    switch (status) {
      case "verificated":
        type = "reject";
        break;
      case "rejected":
        type = "verificate";

        break;
      default:
        break;
    }

    this.props.verificateRejectMedical(
      value.medical.medical_presenter.medical_id,
      type
    );
  };

  render() {
    const { pag } = this.props;

    let pagInit = (pag - 1) * 10;
    let pagEnd = pag * 10;

    let action = "";

    if (this.state.permissions.includes("verificate"))
      action = action + "Aprobar";

    if (
      this.state.permissions.includes("verificate") &&
      this.state.permissions.includes("reject")
    )
      action = action + " / ";

    if (this.state.permissions.includes("reject")) action = action + "Rechazar";

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="card full-height">
            <div className="card-header">
              <div className="d-flex align-items-center">
                <h4 className="card-title">Doctores</h4>

                <div className=" ml-auto">
                  {this.state.permissions.includes("export") && (
                    <button
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        this.setState({
                          type: "exportUser",
                          itemClassName: "modal-lg",
                        });
                        this.props.openModal({
                          title: "Exportar usuarios",
                        });
                      }}
                    >
                      <i className="fa fa-file-export"></i>
                      Exportar
                    </button>
                  )}
                  {this.state.permissions.includes("list") && (
                    <button
                      className="btn btn-primary btn-round ml-auto"
                      onClick={() => {
                        this.setState({
                          type: "filterAll",
                        });
                        this.props.openModal({
                          title: "Filtrar",
                          itemClassName: "modal-lg",
                        });
                      }}
                    >
                      <i className="fa fa-plus"></i>
                      Filtrar
                    </button>
                  )}
                  {this.props.success && (
                    <button
                      className="btn btn-danger btn-round ml-auto"
                      onClick={() => {
                        this.props.getPagSelectReset();
                        this.props.filterReset();
                      }}
                    >
                      <i className="fa fa-times" />
                      Limpiar busqueda
                    </button>
                  )}
                </div>
              </div>
            </div>
            <div className="card-body p-0">
              {!this.state.permissions.includes("list") ? (
                <div>
                  <h3 className="text-center">
                    No posee los permisos correspondiente para visualizar esta
                    información
                  </h3>
                </div>
              ) : this.state.isFechingMedical ? (
                <div className="GridLoader">
                  <GridLoader
                    size={20}
                    css={override}
                    color={"#19769F"}
                    loading={true}
                  />
                </div>
              ) : (
                <div className="table-responsive">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th>Nombre y Apellido</th>
                        <th>Email</th>
                        <th>Edad</th>
                        <th>Estatus</th>
                        <th>Fecha de Registro</th>
                        {this.state.permissions.includes("view") && (
                          <th>Detalles</th>
                        )}
                        {permissionsValidate(
                          this.props.permissions,
                          "office"
                        ) >= 0 &&
                          this.props.permissions[
                            permissionsValidate(
                              this.props.permissions,
                              "office"
                            )
                          ].action.includes("list") && <th>Oficinas</th>}
                        {permissionsValidate(
                          this.props.permissions,
                          "specialty"
                        ) >= 0 &&
                          this.props.permissions[
                            permissionsValidate(
                              this.props.permissions,
                              "specialty"
                            )
                          ].action.includes("list") && <th>Especialidades</th>}
                        {permissionsValidate(
                          this.props.permissions,
                          "schedule"
                        ) >= 0 &&
                          this.props.permissions[
                            permissionsValidate(
                              this.props.permissions,
                              "schedule"
                            )
                          ].action.includes("list") && <th>Horario</th>}
                        {permissionsValidate(
                          this.props.permissions,
                          "document"
                        ) >= 0 &&
                          this.props.permissions[
                            permissionsValidate(
                              this.props.permissions,
                              "document"
                            )
                          ].action.includes("list") && <th>Documentos</th>}
                        {permissionsValidate(this.props.permissions, "user") >=
                          0 &&
                          this.props.permissions[
                            permissionsValidate(this.props.permissions, "user")
                          ].action.includes("status_update") && (
                            <th>Resetear contraseña</th>
                          )}
                        {action.length > 0 && <th>{action}</th>}
                      </tr>

                      {/* --------------------------------------------------------------- end header -----------------------------  */}

                      {this.state.medicalList &&
                      this.state.medicalList.length > 0 &&
                      this.state.medicalList.slice(pagInit, pagEnd).length >
                        0 ? (
                        this.state.medicalList
                          .slice(pagInit, pagEnd)
                          .map((value, index) => {
                            let status;
                            let classBadge;

                            switch (value.medical && value.medical.status) {
                              case "unconfirmed":
                                status = "Por confirmar";
                                classBadge = "badge-warning";
                                break;
                              case "rejected":
                                status = "Rechazado";
                                classBadge = "badge-warning";
                                break;
                              case "verificated":
                                status = "Verificado";
                                classBadge = "badge-success";

                                break;
                              default:
                                status = "Por confirmar";
                                classBadge = "badge-warning";
                                break;
                            }

                            return (
                              <tr key={index}>
                                <td className="font-weight-600">
                                  {value.name} {value.lastname}
                                </td>
                                <td>{value.email}</td>
                                <td>{value.age}</td>
                                <td>
                                  <div className={"badge" + classBadge}>
                                    {status}
                                  </div>
                                </td>
                                <td>
                                  {moment(value.medical.created_at).format(
                                    "DD-MM-YYYY"
                                  )}
                                </td>
                                {this.state.permissions.includes("view") && (
                                  <td>
                                    <button
                                      type="button"
                                      className="btn btn-primary btn-sm"
                                      onClick={() => {
                                        this.setState({
                                          item: value,
                                          delete: true,
                                          title: "Detalles del doctor",
                                          type: "detaill",

                                          itemClassName: "modal-xl",
                                        });
                                      }}
                                    >
                                      Ver
                                    </button>
                                  </td>
                                )}

                                {permissionsValidate(
                                  this.props.permissions,
                                  "office"
                                ) >= 0 &&
                                  this.props.permissions[
                                    permissionsValidate(
                                      this.props.permissions,
                                      "office"
                                    )
                                  ].action.includes("list") && (
                                    <td>
                                      {permissionsValidate(
                                        this.props.permissions,
                                        "office"
                                      ) >= 0 &&
                                      this.props.permissions[
                                        permissionsValidate(
                                          this.props.permissions,
                                          "office"
                                        )
                                      ].action.includes("view") ? (
                                        <button
                                          type="button"
                                          className={
                                            value.medical &&
                                            value.medical.offices.length > 0
                                              ? "btn btn-primary btn-sm"
                                              : "btn btn-light btn-sm"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              item: value,
                                              delete: true,
                                              title: "Lista de oficinas",
                                              type: "office",
                                              itemClassName: "modal-lg",
                                            });
                                          }}
                                          disabled={
                                            value.medical &&
                                            value.medical.offices.length > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          Ver
                                        </button>
                                      ) : value.medical &&
                                        value.medical.offices.length > 0 ? (
                                        "si posee"
                                      ) : (
                                        "no posee"
                                      )}
                                    </td>
                                  )}
                                {permissionsValidate(
                                  this.props.permissions,
                                  "specialty"
                                ) >= 0 &&
                                  this.props.permissions[
                                    permissionsValidate(
                                      this.props.permissions,
                                      "specialty"
                                    )
                                  ].action.includes("list") && (
                                    <td>
                                      {permissionsValidate(
                                        this.props.permissions,
                                        "specialty"
                                      ) >= 0 &&
                                      this.props.permissions[
                                        permissionsValidate(
                                          this.props.permissions,
                                          "specialty"
                                        )
                                      ].action.includes("view") ? (
                                        <button
                                          type="button"
                                          className={
                                            value.medical &&
                                            value.medical.specialties.length > 0
                                              ? "btn btn-primary btn-sm"
                                              : "btn btn-light btn-sm"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              item: value,
                                              delete: true,
                                              title: "Lista de especialidades",
                                              type: "specialties",
                                              itemClassName: "modal-lg",
                                            });
                                          }}
                                          disabled={
                                            value.medical &&
                                            value.medical.specialties.length > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          Ver
                                        </button>
                                      ) : value.medical &&
                                        value.medical.specialties.length > 0 ? (
                                        "si posee"
                                      ) : (
                                        "no posee"
                                      )}
                                    </td>
                                  )}

                                {permissionsValidate(
                                  this.props.permissions,
                                  "schedule"
                                ) >= 0 &&
                                  this.props.permissions[
                                    permissionsValidate(
                                      this.props.permissions,
                                      "schedule"
                                    )
                                  ].action.includes("list") && (
                                    <td>
                                      {permissionsValidate(
                                        this.props.permissions,
                                        "schedule"
                                      ) >= 0 &&
                                      this.props.permissions[
                                        permissionsValidate(
                                          this.props.permissions,
                                          "schedule"
                                        )
                                      ].action.includes("view") ? (
                                        <button
                                          type="button"
                                          className={
                                            value.medical &&
                                            value.medical.schedules.length > 0
                                              ? "btn btn-primary btn-sm"
                                              : "btn btn-light btn-sm"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              item: value,
                                              delete: true,
                                              title: "Lista de horarios",
                                              type: "schedules",
                                              itemClassName: "modal-xl",
                                            });
                                          }}
                                          disabled={
                                            value.medical &&
                                            value.medical.schedules.length > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          Ver
                                        </button>
                                      ) : value.medical &&
                                        value.medical.schedules.length > 0 ? (
                                        "Si posee"
                                      ) : (
                                        "No posee"
                                      )}
                                    </td>
                                  )}
                                {permissionsValidate(
                                  this.props.permissions,
                                  "document"
                                ) >= 0 &&
                                  this.props.permissions[
                                    permissionsValidate(
                                      this.props.permissions,
                                      "document"
                                    )
                                  ].action.includes("list") && (
                                    <td>
                                      {permissionsValidate(
                                        this.props.permissions,
                                        "document"
                                      ) >= 0 &&
                                      this.props.permissions[
                                        permissionsValidate(
                                          this.props.permissions,
                                          "document"
                                        )
                                      ].action.includes("view") ? (
                                        <button
                                          type="button"
                                          className={
                                            value.medical &&
                                            value.medical.documents.length > 0
                                              ? "btn btn-primary btn-sm"
                                              : "btn btn-light btn-sm"
                                          }
                                          onClick={() => {
                                            this.setState({
                                              item: value,
                                              delete: true,
                                              title: "Lista de documentos",
                                              type: "documents",
                                              itemClassName: "modal-lg",
                                            });
                                          }}
                                          disabled={
                                            value.medical &&
                                            value.medical.documents.length > 0
                                              ? false
                                              : true
                                          }
                                        >
                                          Ver
                                        </button>
                                      ) : value.medical &&
                                        value.medical.documents.length > 0 ? (
                                        "Si posee"
                                      ) : (
                                        "No posee"
                                      )}
                                    </td>
                                  )}
                                {permissionsValidate(
                                  this.props.permissions,
                                  "user"
                                ) >= 0 &&
                                  this.props.permissions[
                                    permissionsValidate(
                                      this.props.permissions,
                                      "user"
                                    )
                                  ].action.includes("status_update") && (
                                    <td>
                                      <button
                                        type="button"
                                        data-toggle="tooltip"
                                        title=""
                                        className="btn btn-link btn-primary btn-lg"
                                        data-original-title="Aprobar "
                                        onClick={() => {
                                          // this.actionsOffice(value, "rejected")
                                          this.setState({
                                            item: value,
                                            type: "passwordReset",
                                          });
                                          this.props.openModal({
                                            title: "Resetear contraseña",
                                            itemClassName: "modal-dialog",
                                          });
                                        }}
                                      >
                                        <i className="fas fa-redo-alt"></i>
                                      </button>
                                    </td>
                                  )}
                                <td>
                                  <div className="form-button-action">
                                    {this.state.permissions.includes(
                                      "verificate"
                                    ) &&
                                      value.medical.status !==
                                        "verificated" && (
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn btn-link btn-primary btn-lg"
                                          data-original-title="Aprobar "
                                          onClick={() => {
                                            // this.actionsOffice(value, "rejected")
                                            this.setState({
                                              item: value,
                                              type: "activeMedical",
                                            });
                                            this.props.openModal({
                                              title: "Activar usuario",
                                              itemClassName: "modal-dialog",
                                            });
                                          }}
                                        >
                                          <i className="fas fa-thumbs-up"></i>
                                        </button>
                                      )}

                                    {this.state.permissions.includes(
                                      "reject"
                                    ) &&
                                      value.medical.status !== "rejected" && (
                                        <button
                                          type="button"
                                          data-toggle="tooltip"
                                          title=""
                                          className="btn btn-link btn-danger btn-lg"
                                          data-original-title="Rechazar  "
                                          onClick={() => {
                                            //this.actionsOffice(value, "verificated")
                                            this.setState({
                                              item: value,
                                              type: "rejectedMedical",
                                            });
                                            this.props.openModal({
                                              title: "Rechazar",
                                              itemClassName: "modal-dialog",
                                            });
                                          }}
                                        >
                                          <i className="fas fa-thumbs-down"></i>
                                        </button>
                                      )}
                                  </div>
                                </td>
                              </tr>
                            );
                          })
                      ) : this.props.success &&
                        this.props.filterList.length === 0 ? (
                        <tr>
                          <td colSpan={10}>
                            <h3 className="text-center">
                              No hay resultados en esta búsqueda
                            </h3>
                          </td>
                        </tr>
                      ) : (
                        <tr>
                          <td colSpan={10}>
                            <div className="GridLoader">
                              <GridLoader
                                size={20}
                                css={override}
                                color={"#19769F"}
                                loading={true}
                              />
                            </div>
                          </td>
                        </tr>
                      )}

                      {/* ---------------------------------------------------------------foooter -----------------------------  */}
                    </tbody>
                  </table>
                  <div className="row">
                    <div className="col-sm-12 col-md-7">
                      <div
                        className="dataTables_paginate paging_simple_numbers"
                        id="add-row_paginate"
                      >
                        <Pagination
                          data={this.state.medicalList}
                          all={this.state.medicalAll}
                          type={"medical"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ModalGlobal dialogClassName={this.state.itemClassName}>
          {this.bodyModal()}
        </ModalGlobal>

        <Modal
          show={this.state.delete}
          onHide={() => this.setState({ delete: false, item: "" })}
          aria-labelledby="example-modal-sizes-title-xl"
          dialogClassName={this.state.itemClassName}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <h4
                style={{
                  color: "#19769f",
                  fontWeight: "bold",
                }}
              >
                {this.state.title}
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">{this.bodyModal()}</Modal.Body>
          <Modal.Footer>
            <div>
              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => this.setState({ delete: false, item: "" })}
              >
                Cancelar
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    medicalList: state.medical.medicalList,
    medicalAll: state.medical.medicalAll,
    isFechingMedical: state.medical.isFeching,
    pag: state.pagination.pagSelect,
    status: state.medical.status,
    filterList: state.filter.filterList,
    success: state.filter.success,
    isFechingFilter: state.filter.isFeching,
    permissions: state.user.user.user.permissions,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { verificateRejectMedical, openModal, filterReset, getPagSelectReset },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DoctorTable
);
