import React from "react"; 
import MedicamentTable from "./MedicamentTable";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { stadisticsHealthInit } from "../../../actions/stadistic_actions";
import { bindActionCreators } from "redux";
import {  reduxForm } from "redux-form"; 
import { validate } from "../../Forms/validate";
import moment from "moment";
import "moment/locale/es";
import { compose } from "redux";
import Filter from "./filter";
moment.locale("es");

const index = () => {
  return (
    <div className="content">
      <div className="panel-header bg-primary-gradient">
        <Filter />
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <MedicamentTable />
        </div>
      </div>
    </div>
  );
};
const mapDispatchToProps = dispatch =>
  bindActionCreators({ stadisticsHealthInit }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "stadisticsHealthInit",
    validate
  })
)(index);
