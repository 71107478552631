import { filterConstants } from "../constansts/filter.constants";
export const getfilter = data => {
  return { type: filterConstants.FILTER_LIST, data };
};

export const getfilterTotal = data => {
  return { type: filterConstants.FILTER_TOTAL, data };
};

export const filterLoading = () => {
  return { type: filterConstants.FILTER_LOADING_LIST };
};

export const filterReset = () => {
  return { type: filterConstants.FILTER_RESET };
};
