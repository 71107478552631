import { connect } from "react-redux";
import { stadisticsUseInit } from "../../actions/stadistic_actions";
import { bindActionCreators } from "redux";
import React, { useEffect, useState, useRef } from "react";
import SignUpTable from "./SignUpTable";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

const override = css`
  display: inline-block;
  margin: 0 auto;
`;
export const useContainerDimensions = (myRef) => {
  const getDimensions = () => {
    return {
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    };
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

const Statistics = ({ use, stadisticsUseInit, loading }) => {
  const [dataUse, setdataUse] = useState([]);
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const [heightWithUser, setheightWithUser] = useState(
    useContainerDimensions(componentRef)
  );

  useEffect(() => {
    if (use && use.length !== dataUse.length) {
      setdataUse(use);
    }

    if (use && use.length === 0) {
      let data = {
        start_date: moment().day(-1, "day").format("YYYY-MM-DD HH:mm"),
        end_date: null,
      };

      stadisticsUseInit(data);
    }

    if (width !== heightWithUser.width && width !== 0) {
      setheightWithUser({
        width,
      });
    }
  }, [use, width]);

  return (
    <div className="col">
      {dataUse.length > 0 && (
        <div
          style={{
            display: "grid",
            zIndex: 2,
            gridTemplateColumns:
              (width / 2).toString() + "px " + (width / 2).toString() + "px",
          }}
        >
          {dataUse.map((value, index) => {
            return (
              <div className="col" key={index} style={{ zIndex: 1000 }}>
                <SignUpTable
                  value={value}
                  index={index}
                  key={index}
                  width={width / 2}
                  height={height / 1.6}
                />
              </div>
            );
          })}
        </div>
      )}

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto ",
          marginTop: dataUse.length > 0 ? -height.toString() + "px" : "0px",
          zIndex: 1,
        }}
        ref={componentRef}
      >
        <div className="GridLoader">
          <GridLoader
            size={20}
            css={override}
            color={dataUse.length > 0 ? "#fff" : "#19769F"}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  use: state.stadistics.use,
  loading: state.stadistics.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ stadisticsUseInit }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
