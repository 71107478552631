import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verificateRejectSpecialties } from "../../actions/medical_actions";
import { permissionsValidate } from "../../functions";

let offices = (props) => {
  const [state, setstate] = useState(props.data);
  const [select, setselect] = useState(null);
  const [permissions, setpermissions] = useState([]);

  useEffect(() => {
    if (permissions.length === 0) {
      let searchModule = permissionsValidate(props.permissions, "specialty");
      if (searchModule >= 0)
        setpermissions(props.permissions[searchModule].action);
    }

    let resultMedical = props.medicalList.find((value) => {
      return value._id === state.medical.medical_presenter.medical_id;
    });
    if (select !== null) {
      setstate(resultMedical);
      setselect(null);
    }
  }, [props.medicalList]);
  const actionsOffice = (value, status) => {
    setselect(value);
    let type;
    switch (status) {
      case "verificated":
        type = "reject";
        break;
      case "rejected":
        type = "verificate";

        break;
      default:
        break;
    }

    props.verificateRejectSpecialties(
      value,
      state.medical.medical_presenter.medical_id,
      type
    );
  };

  return (
    <div>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Tipo</th>
            <th>Estatus</th>
            {(permissions.includes("verificate") ||
              permissions.includes("reject")) && <th>Acción</th>}
          </tr>
        </thead>
        <tbody>
          {state &&
            state.medical &&
            state.medical.specialties.map((value, index) => {
              let status;
              switch (value.status) {
                case "verificated":
                  status = "Verificado";
                  break;
                case "rejected":
                  status = "Rechazado";
                  break;
                default:
                  status = "Sin verificar";
                  break;
              }

              return (
                <tr key={index}>
                  <td>{value.name.es}</td>
                  <td>{value.type_specialty}</td>
                  <td>{status}</td>

                  <td>
                    <tr>
                      {permissions.includes("reject") &&
                        value.status !== "rejected" && (
                          <td>
                            <button
                              className={"btn btn-sm btn-danger "}
                              onClick={() =>
                                actionsOffice(value, "verificated")
                              }
                            >
                              Rechazar
                            </button>
                          </td>
                        )}

                      {permissions.includes("verificate") &&
                        value.status !== "verificated" && (
                          <td>
                            <button
                              className={"btn btn-sm  btn-success "}
                              onClick={() => actionsOffice(value, "rejected")}
                            >
                              Verificar
                            </button>
                          </td>
                        )}
                    </tr>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.user.user.user.permissions,
  medicalList: state.medical.medicalList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ verificateRejectSpecialties }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(offices);
