import React, { Component } from "react";
import User from "./User";
import Maintainer from "./maintainer";

class SideBar extends Component {
  render() {
    return (
      <div
        className="sidebar sidebar-style-2"
        style={!this.props.menu ? { width: "0px" } : {}}
      >
        <div className="sidebar-wrapper scrollbar scrollbar-inner">
          <User />
          <Maintainer />
        </div>
      </div>
    );
  }
}

export default SideBar;
