import { notificationsConstants } from "../constansts/notifications.constants";

const initialState = {
  usersList: [],
  isFeching: true,
  status: false,
  usersComplete: [],
  usersSuccess: false,
  usersFailure: [],
  body: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case notificationsConstants.GROUP_NOTIFICATION:
      return {
        ...state,

        usersSuccess: true,
        usersList: action.data
      };
    case notificationsConstants.GROUP_NOTIFICATION_SUCCESS:
      let value = [...state.usersComplete];
      value.push(action.data);

      return {
        ...state,
        usersComplete: value
      };
    case notificationsConstants.GROUP_NOTIFICATION_FAILURE:
      let valueFailure = [...state.usersFailure];
      valueFailure.push(action.data);

      return {
        ...state,
        usersFailure: valueFailure
      };

    case notificationsConstants.GROUP_NOTIFICATION_BODY:
      return {
        ...state,
        body: action.data
      };

    case notificationsConstants.GROUP_NOTIFICATION_RESET:
      return initialState;
    default:
      return state;
  }
}
