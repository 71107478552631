import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { openModal } from "../../actions/modal_actions";
import { onLogout } from "../../actions";

class User extends Component {
  state = {
    item: null,
    itemClassName: null,
  };

  render() {
    const { user } = this.props;

    return (
      <div className="user">
        <div className="info">
          <a
            data-toggle="collapse"
            href="#collapseExample"
            aria-expanded="true"
          >
            <span>
              {user && user.user && user.user.fullname}
              <span className="user-level">Administrator</span>
            </span>
          </a>
          <div className="clearfix"></div>
          <div className="collapse in show" id="collapseExample">
            <ul className="nav">
              {/*     <li>
                <span
                  style={{ cursor: "pointer" }}
                  className="link-collapse"
                  onClick={() => {
                    this.setState({
                      type: "exportUser",
                      itemClassName: "modal-lg"
                    });
                    this.props.openModal({
                      title: "Cambiar contraseña"
                    });
                  }}
                >
                  Cambiar contraseña
                </span>
                </li>*/}
              <li>
                <button
                  className="btn p-0"
                  style={{
                    background: "white",
                  }}
                  onClick={() => {
                    this.props.openModal({
                      title: "Cerrando sesion",
                    });
                    this.props.onLogout();
                  }}
                >
                  <span className="link-collapse">Cerrar Sesión</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ onLogout, openModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(User);
