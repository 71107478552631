import { medicalConstants } from "../constansts/medica.constants";

const initialState = {
  medicalList: [],
  medicalAll: 0,
  isFeching: true,
  status: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case medicalConstants.MEDICAL_LIST:
      return {
        ...state,
        medicalList: action.data,

        isFeching: false
      };

    case medicalConstants.MEDICAL_ALL:
      return {
        ...state,
        medicalAll: action.data
      };

    default:
      return state;
  }
}
