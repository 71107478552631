import  { Component } from "react"; 
export default class UsersAdmin extends Component {
  render() {
    /* return (
        <div>
            <ul className="nav nav-primary">
                <li className="nav-item submenu">
                    <Link to="/UsersAdmin">
                        <i className="fas fa-user-cog"></i>
                        <p>Administrador de Usuarios</p>
                    </Link>
                </li>
            </ul>
        </div>
        )*/
    return null;
  }
}
