import { userConstants } from "../constansts/user.constants";

import {
  loading,
  getSuccess as getSuccessModal,
  getFailure,
  openModal,
} from "../actions/modal_actions";
import { getPagSearch, getSuccess } from "../actions/paginator_actions";

import {
  getfilter,
  filterLoading,
  getfilterTotal,
} from "../actions/filter_actions";
import * as api from "../api/api_admin";
export const getUser = (data) => {
  return { type: userConstants.USER_ADMIN, data };
};

export const getUserRefresh = (data) => {
  return { type: userConstants.USER_LOADED, data };
};

export const getUsersList = (data) => {
  return { type: userConstants.LIST_USERS, data };
};

export const getUsersListUpdate = (data) => {
  return { type: userConstants.LIST_USERS_UPDATE, data };
};
export const getUserSuccessStatus = () => {
  return { type: userConstants.USER_ADMIN_STATUS };
};

export const getUserLoadingStatus = () => {
  return { type: userConstants.USER_LOADING_STATUS };
};
export const getCountriesList = (data) => {
  return { type: userConstants.LIST_COUNTRY, data };
};

export const getInsuranceList = (data) => {
  return { type: userConstants.LIST_INSURANCE, data };
};

export const getValidationLoading = () => {
  return { type: userConstants.USER_VALIDATION_LOADING };
};

export const getValidationSuccess = (data) => {
  return { type: userConstants.USER_VALIDATION_SUCCESS, data };
};

export const getValidationError = (data) => {
  return { type: userConstants.USER_VALIDATION_ERROR, data };
};

export const loginUser = (data) => {
  let url = "admin/login";

  return (dispatch, getState) => {
    dispatch(loading());
    dispatch(
      openModal({
        title: "Login",
      })
    );

    let userName = getState().user.userName;

    let body = {
      Login: {
        username: userName,
        password: data.password,
      },
      code: data.code,
    };

    const request = api.requestUser(body, url);
    dispatch({ type: userConstants.USER_LOADING });
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getUser(result.data));
          dispatch(getSuccessModal());
          dispatch(getSuccess());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {}
    });
  };
};

export const loginSendValidation = (data) => {
  let url = "admin/send-validation";

  return (dispatch, getState) => {
    dispatch(getValidationLoading());

    const request = api.requestUser(data, url);

    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getValidationSuccess(data.username));
        } else {
          dispatch(getValidationError(result.msg));
        }
      } catch (e) {}
    });
  };
};

export const refreshUser = () => async (dispatch, getState) => {
  dispatch({ type: userConstants.USER_LOADING });
  let url = "admin/refresh";

  if (getState().user.token !== null) {
    const request = api.requestToken(url, getState().user.token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getUserRefresh(result.data));
        } else {
          dispatch({
            type: userConstants.AUTH_ERROR,
          });
        }
      } catch (e) {
        dispatch({
          type: userConstants.AUTH_ERROR,
        });
      }
    });
  } else {
    dispatch({
      type: userConstants.AUTH_ERROR,
    });
  }
};

export const filterListUsers = (data) => {
  let url = "user/list";
  return (dispatch, getState) => {
    dispatch(filterLoading());
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getfilterTotal(result.data.total));
          dispatch(getfilter(result.data.list_user));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const deleteUsers = (data) => {
  let url = "user/hard-delete";
  return (dispatch, getState) => {
    dispatch(loading());
    dispatch(getUserLoadingStatus());

    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);

    let userList = getState().user.listUsers;
    let filterList = getState().filter.filterList;

    request.then((result) => {
      try {
        if (result.status === 200) {
          let user = [...userList];

          let resultUser = userList.findIndex((value) => {
            return value._id === data.user_id;
          });

          user.splice(resultUser, 1);

          if (filterList.length > 0) {
            let dataFilter = [...filterList];
            let resultFilter = dataFilter.findIndex((value) => {
              return value._id === data.user_id;
            });

            if (resultFilter > -1) dataFilter.splice(resultFilter, 1);
            dispatch(getfilter(dataFilter));
          }

          dispatch(getUsersListUpdate(user));
          dispatch(getFailure(result.msg));
          dispatch(getUserSuccessStatus());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};
export const listUsers = (data) => {
  let url = "user/list";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getUsersList(result.data));
          dispatch(getCountriesList(result.data.countries));
          dispatch(
            getInsuranceList(result.data.insurance_list.insurances_list[0])
          );
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const arrayPagination = (data) => {
  return (dispatch, getState) => {
    let pagSearch = getState().pagination.pagSearch;

    let search = getState().pagination.search;

    let arraySearch = [...search];

    pagSearch = pagSearch + 1;

    let values = {
      filter: {
        page: pagSearch,
        novem_id: null,
        fullname: "",
        email: null,
        age_start: 0,
        age_end: 0,
        country: null,
        status: null,
        insurance: null,
        limit: data,
      },
    };
    arraySearch.push(values);

    dispatch(getPagSearch(arraySearch));
  };
};

export const listUsersPagination = (data) => {
  let url = "user/list";
  return (dispatch, getState) => {
    let token = getState().user.token;

    let listUsers = getState().user.listUsers;

    const request = api.requestOfUserAdmin(data, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          let listPush = [...listUsers];

          listPush = listPush.concat(result.data.list_user);

          result.data.list_user = listPush;
          dispatch(getUsersList(result.data));
          dispatch(getSuccess());
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const activeSuspendUser = (body, type) => {
  let url = "user/" + type;

  return (dispatch, getState) => {
    dispatch(loading());
    dispatch(getUserLoadingStatus());
    let token = getState().user.token;
    let userList = getState().user.listUsers;
    let filterList = getState().filter.filterList;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          let user = [...userList];

          let resultUser = userList.findIndex((value) => {
            return value._id === result.data._id;
          });

          user[resultUser] = result.data;

          if (filterList.length > 0) {
            let dataFilter = [...filterList];
            let resultFilter = dataFilter.findIndex((value) => {
              return value._id === result.data._id;
            });
            dataFilter[resultFilter] = result.data;
            dispatch(getfilter(dataFilter));
          }

          dispatch(getUsersListUpdate(user));
          dispatch(getFailure("Status modificado con éxito"));
          dispatch(getUserSuccessStatus());
          // dispatch(getSuccess());
        }
      } catch (e) {}
    });
  };
};

export const passwordResetUser = (body, type) => {
  let url = "user/password-reset";

  return (dispatch, getState) => {
    dispatch(loading());
    dispatch(getUserLoadingStatus());
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {}
    });
  };
};

export const exportUsers = (data) => {
  let url = "export/cvs";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });

    return request;
  };
};
