export const stadisticConstants = {
  STADISTIC_USE: "STADISTIC_USE",
  STADISTIC_HEALTH: "STADISTIC_HEALTH",
  STADISTIC_HEALTH_LOADING: "STADISTIC_HEALTH_LOADING",
  LOADING: "LOADING",
  STADISTIC_TOKEN: "STADISTIC_TOKEN",
  STADISTIC_TOKEN_SUCCESS: "STADISTIC_TOKEN_SUCCESS",
  STADISTIC_TOKEN_ERROR: "STADISTIC_TOKEN_ERROR",
  STADISTIC_TOKEN_SEND: "STADISTIC_TOKEN_SEND"
};
