import React, { Component } from 'react';
import PanelHeader from '../PanelHeader';   
import GeneralTable from './GeneralTable';




export default class index extends Component {
    render() {
        return (
            <div className="content" >
              <div className="panel-header bg-primary-gradient">
                <PanelHeader/>
              </div>
              <div className="page-inner mt--5">
                <GeneralTable />

              </div>
            </div>
        )
    }
}
