import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { loginSendValidation } from "../../../actions/user_actions";
import { renderField } from "../renderField";

const FormClinicUpdatePrincipal = ({ handleSubmit, dispatch }) => {
  const onFormSubmit = (values) => {
    let body = {
      username: values.username,
    };
    dispatch(loginSendValidation(body));
  };
  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <Field
        className="form-control"
        name="username"
        component={renderField}
        type="text"
        placeholder="Usuario"
      />
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: 20,
        }}
      >
        <button
          className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
          type="submit"
        >
          Login
        </button>
      </div>
    </form>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ loginSendValidation }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "FormSendValidation",
  })
)(FormClinicUpdatePrincipal);
