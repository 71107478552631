import { connect } from "react-redux";
import { stadisticsUseInit } from "../../actions/stadistic_actions";
import { bindActionCreators } from "redux";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { renderField } from "../Forms/renderField";
import { validate } from "../Forms/validate";
import React from "react";
//import PanelHeader from "../PanelHeader";
import Tablet from "./statisticsTable";
import moment from "moment";
import "moment/locale/es";

moment.locale("es");

let Statistics = ({ stadisticsUseInit, start, handleSubmit }) => {
  const onSubmit = data => {
    if (!data.end_date) {
      data.end_date = null;
    } else {
      data.end_date = moment(data.end_date).format("YYYY-MM-DD HH:mm");
    }
    data.start_date = moment(data.start_date).format("YYYY-MM-DD HH:mm");

    stadisticsUseInit(data);
  };
  return (
    <div className="content">
      <div className="panel-header bg-primary-gradient">
        {/*<PanelHeader />*/}
        <div
          className="row justify-content-center"
          style={{ paddingTop: 15, paddingBottom: 15 }}
        >
          <div className="col-md-5">
            <div className="card">
              <div className="card-body p-0">
                <div className="row">
                  <div className="col-auto">
                    <div className="form-group">
                      <Field
                        name="start_date"
                        component={renderField}
                        max={moment().format("YYYY-MM-DD HH:mm")}
                        type="date"
                        placeholder={"Fecha de inicio"}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-auto">
                    <div className="form-group">
                      <Field
                        name="end_date"
                        component={renderField}
                        min={start}
                        max={moment().format("YYYY-MM-DD HH:mm")}
                        type="date"
                        placeholder={"Fecha de fin"}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div
                    className="col-auto"
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex"
                    }}
                  >
                    <div className="form-group">
                      <button
                        className="btn btn-sm"
                        style={{ backgroundColor: "#19769F", color: "#ffffff" }}
                        onClick={handleSubmit(onSubmit)}
                      >
                        Filtrar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-inner mt--5">
        <div className="row">
          <Tablet />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = state => ({
  use: state.stadistics.use
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ stadisticsUseInit }, dispatch);

const selector = formValueSelector("stadisticsUseInit");

Statistics = reduxForm({
  form: "stadisticsUseInit",
  validate
})(Statistics);

Statistics = connect(state => {
  const start = selector(state, "start_date");
  return {
    start
  };
})(Statistics);

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
