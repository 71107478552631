import React from "react";
import { Table } from "react-bootstrap";

let offices = (props) => {
  return (
    <div>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Telefono</th>
            <th>Correo</th>
            <th>Parentesco</th>
          </tr>
        </thead>
        <tbody>
          {props.data.Health.EmergencyContacts &&
            props.data.Health.EmergencyContacts.map((value, index) => {
              return (
                <tr key={index}>
                  <td>{value.fullname}</td>

                  <td>
                    {value.phones &&
                      value.phones.map((valueNumber, indexNumber) => {
                        return (
                          <tr key={indexNumber}>
                            <td>
                              {valueNumber.country_code} {valueNumber.number}
                            </td>
                          </tr>
                        );
                      })}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    {value.emails.length === 0 ? (
                      <> No posee </>
                    ) : (
                      value.emails.map((value, index) => {
                        return (
                          <p key={index} style={{ margin: "auto" }}>
                            {value.email}
                          </p>
                        );
                      })
                    )}
                  </td>
                  <td>{value.relationship}</td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

export default offices;
