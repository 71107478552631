import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { compose } from "redux";
import { openModal } from "../../../actions/modal_actions";

import View2 from "./View2";
import "../dashboard.css";

const TableStactis = ({
  value,
  index,
  height,
  width,
  title,
  details,
  selectItem,
  openModal,
}) => {
  const [include, setinclude] = useState([]);
  const [aux, setaux] = useState([]);
  useEffect(() => {
    if (include.length === 0 && value && value.length > 0) {
      setinclude(value);
    }
  }, [value, aux]);

  const changeAux = (v) => {
    let value = [...aux];

    if (value.length > 0) {
      value.push(v);
      setaux(value);
    } else setaux([v]);
  };

  if (title.length > 0)
    return (
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <ul
              className="breadcrumbs"
              style={{ paddingLeft: "0px", marginLeft: "0px" }}
            >
              <li
                className="nav-home"
                onClick={() => {
                  setaux([]);
                }}
              >
                <h4 className="card-title">{title}</h4>
              </li>
              {aux.length > 0 &&
                aux.map((value, index) => {
                  return (
                    <li key={index}>
                      <li className="separator">
                        <i className="flaticon-right-arrow"></i>
                      </li>
                      <li
                        className="nav-item"
                        onClick={() => {
                          let value = [...aux];

                          value.splice(index + 1, value.length);
                          setaux(value);
                        }}
                      >
                        <h4 className="card-title">{value.name}</h4>
                      </li>
                    </li>
                  );
                })}
            </ul>

            {include.length > 0 && (
              <div className=" ml-auto">
                <button
                  className="btn btn-primary btn-round mx-1"
                  onClick={() => {
                    selectItem({
                      item: title,
                      type: "export",
                      itemClassName: "modal-lg",
                    });

                    openModal({
                      title: "Exportar ( " + title + " )",
                    });
                  }}
                >
                  <i className="fa fa-file-export"></i>
                  Exportar
                </button>

                <button
                  className="btn btn-primary btn-round mx-1"
                  onClick={() => {
                    selectItem({
                      item: title,
                      type: "notification",
                      itemClassName: "modal-lg",
                    });

                    openModal({
                      title: "Notificacion ( " + title + " )",
                    });
                  }}
                >
                  <i className="far fa-paper-plane"></i>
                  Notificar
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            {include.length > 0 ? (
              //aux[aux.length - 1]

              <View2
                data={aux.length > 0 ? aux[aux.length - 1].details : include}
                dataAux={(e) => changeAux(e)}
                index={index}
                widthAll={width}
                heightAll={height}
              />
            ) : (
              <div className="pane py-5">
                <h3>No hay resultado para esta busquedad</h3>
              </div>
            )}
          </div>
        </div>
        {include.length > 0 && details && (
          <div className="card-footer p-0 mt-2">
            <ul className="breadcrumbs">
              <li
                className="nav-home"
                onClick={() => {
                  setaux([]);
                }}
              >
                <i className="flaticon-home"></i>
              </li>
              <li className="separator">
                <i className="flaticon-right-arrow"></i>
              </li>
              {details.map((value, index) => (
                <li className="nav-item" key={index}>
                  {value}
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    );
  else return null;
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openModal }, dispatch);

export default compose(connect(null, mapDispatchToProps))(TableStactis);
