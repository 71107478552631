import { paginatorConstants } from "../constansts/paginator.constants";
export const getPaginator = data => {
  return { type: paginatorConstants.PAGINATOR_LIST, data };
};

export const getPagSelect = data => {
  return { type: paginatorConstants.PAGINATOR_PAG_SELECT, data };
};

export const getPagSelectReset = data => {
  return { type: paginatorConstants.PAGINATOR_PAG_SELECT_RESET, data };
};

export const getPagSearch = data => {
  return { type: paginatorConstants.PAGINATOR_PAG_SEARCH, data };
};

export const getSuccess = data => {
  return { type: paginatorConstants.PAGINATOR_SUCCESS };
};

export const getNotSuccess = data => {
  return { type: paginatorConstants.PAGINATOR_NOT_SUCCESS };
};
