import React, { useEffect, useState } from "react";
import View2 from "./views/View2";
import "./dashboard.css";

const SignUpTable = ({ value, index, height, width }) => {
  const [include, setinclude] = useState([]);
  const title = value.module;
  useEffect(() => {
    if (include.length === 0 && value.sub) {
      let valueNew = value;

      let sub = valueNew.sub.sort(function (a, b) {
        return a.total - b.total;
      });

      setinclude(sub);
    }
  }, [value]);

  if (include.length > 0)
    return (
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <h4 className="card-title">Estadísticas de {title}</h4>
          </div>
        </div>
        <div className="card-body p-0">
          <div className="table-responsive">
            {include.length > 0 && (
              <View2
                data={include}
                index={index}
                widthAll={width}
                heightAll={height}
              />
            )}
          </div>
        </div>
      </div>
    );
  else return null;
};

export default SignUpTable;
