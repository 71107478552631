import { navegationConstants } from "../constansts/navegation.constants";

const initialState = {
  data: [],
  success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case navegationConstants.NAVIGATION_ALL:
      return {
        ...state,
        data: action.data,
        success: true
      };

    default:
      return state;
  }
}
