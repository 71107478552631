import React, {Component} from "react";
import {Link} from "react-router-dom";

class Generaltransactions extends Component {
  addingClass() {
    document.getElementById("div").className += " active";
  }

  render() {
    return (
      <div>
        <ul className="nav nav-primary">
          <li className="nav-item submenu" id="div">
            <a
              data-toggle="collapse"
              href="#transactions"
              className="collapsed"
              aria-expanded="false"
              onClick={this.addingClass.bind(this)}
            >
              <i className="fas fa-chart-line"></i>
              <p>Transaciones</p>
              <span className="caret"></span>
            </a>
            <div className="collapse" id="transactions">
              <ul className="nav nav-collapse">
                <li>
                  <Link to="/transactiondoctor">
                    <span className="sub-item">Doctores</span>
                  </Link>
                </li>
                <li>
                  <Link to="/transactionuser">
                    <span className="sub-item">Pacientes</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default Generaltransactions;
