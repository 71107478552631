import React, { useEffect, useState } from "react";
import "./view2.css";
import { Doughnut } from "react-chartjs-2";

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => {
    return {
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    };
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
const View2 = ({ index, widthAll, heightAll, data, dataAux }) => {
  const data2 = {
    labels: data.map((item) => {
      return item.name + "  " + item.count;
    }),
    datasets: [
      {
        weight: 500,
        steppedLine: "after",
        spanGaps: true,
        data: data.map((item) => {
          return item.count;
        }),
        // hidden: true,
        backgroundColor: [
          "steelblue",
          "LightBlue",
          "LightSteelBlue",
          "#FF6633",
          "#FFB399",
          "#FF33FF",
          "#FFFF99",
          "#00B3E6",
          "#E6B333",
          "#3366E6",
          "#999966",
          "#99FF99",
          "#B34D4D",
          "#80B300",
          "#809900",
          "#E6B3B3",
          "#6680B3",
          "#66991A",
          "#FF99E6",
          "#CCFF1A",
          "#FF1A66",
          "#E6331A",
          "#33FFCC",
          "#66994D",
          "#B366CC",
          "#4D8000",
          "#B33300",
          "#CC80CC",
          "#66664D",
          "#991AFF",
          "#E666FF",
          "#4DB3FF",
          "#1AB399",
          "#E666B3",
          "#33991A",
          "#CC9999",
          "#B3B31A",
          "#00E680",
          "#4D8066",
          "#809980",
          "#E6FF80",
          "#1AFF33",
          "#999933",
          "#FF3380",
          "#CCCC00",
          "#66E64D",
          "#4D80CC",
          "#9900B3",
          "#E64D66",
          "#4DB380",
          "#FF4D4D",
          "#99E6E6",
          "#6666FF",
        ],
        hoverBackgroundColor: [
          "steelblue",
          "LightBlue",
          "LightSteelBlue",
          "#FF6633",
          "#FFB399",
          "#FF33FF",
          "#FFFF99",
          "#00B3E6",
          "#E6B333",
          "#3366E6",
          "#999966",
          "#99FF99",
          "#B34D4D",
          "#80B300",
          "#809900",
          "#E6B3B3",
          "#6680B3",
          "#66991A",
          "#FF99E6",
          "#CCFF1A",
          "#FF1A66",
          "#E6331A",
          "#33FFCC",
          "#66994D",
          "#B366CC",
          "#4D8000",
          "#B33300",
          "#CC80CC",
          "#66664D",
          "#991AFF",
          "#E666FF",
          "#4DB3FF",
          "#1AB399",
          "#E666B3",
          "#33991A",
          "#CC9999",
          "#B3B31A",
          "#00E680",
          "#4D8066",
          "#809980",
          "#E6FF80",
          "#1AFF33",
          "#999933",
          "#FF3380",
          "#CCCC00",
          "#66E64D",
          "#4D80CC",
          "#9900B3",
          "#E64D66",
          "#4DB380",
          "#FF4D4D",
          "#99E6E6",
          "#6666FF",
        ],
      },
    ],
  };

  return (
    <div className="pane" key={index}>
      <div className="title">
        <Doughnut
          data={data2}
          index={index}
          width={widthAll}
          height={heightAll}
          getDatasetAtEvent={(dataset) => {}}
          onElementsClick={(elems) => {
            if (elems[0] && Object.entries(data[elems[0]._index]).length > 0) {
              if (data[elems[0]._index].history) {
                dataAux({
                  name: data[elems[0]._index].name,
                  details: data[elems[0]._index].history,
                });
              } else if (data[elems[0]._index].frecuencies) {
                dataAux({
                  name: data[elems[0]._index].name,
                  details: data[elems[0]._index].frecuencies,
                });
              } else if (data[elems[0]._index].type_insurances) {
                dataAux({
                  name: data[elems[0]._index].name,
                  details: data[elems[0]._index].type_insurances,
                });
              } else if (data[elems[0]._index].diagnostic) {
                dataAux({
                  name: data[elems[0]._index].name,
                  details: data[elems[0]._index].diagnostic,
                });
              } else if (data[elems[0]._index].type_medicament) {
                dataAux({
                  name: data[elems[0]._index].name,
                  details: data[elems[0]._index].type_medicament,
                });
              } else if (data[elems[0]._index].medicine) {
                dataAux({
                  name: data[elems[0]._index].name,
                  details: data[elems[0]._index].medicine,
                });
              }
            }
          }}
          options={{
            maintainAspectRatio: true,
            responsive: false,
            legend: {
              position: "left",
              labels: {
                boxWidth: 8,
              },
            },
          }}
        />
      </div>
    </div>
  );
};
export default View2;
