import React from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: block;
  margin: 0 auto;
`;

export const DashboardCards = ({
  userAll,
  isFechingUser,
  medicalAll,
  isFechingMedical,
  permissions,
}) => {
  const permissionsValidate = (Search) => {
    return permissions.findIndex((value) => {
      return value.module === Search;
    });
  };
  return (
    <div className="row">
      <div className="col-sm-6 col-md-3">
        <div className="card card-stats card-round">
          <div className="card-body ">
            <div className="row">
              <div className="col-5">
                <div className="icon-big text-center">
                  <i className="fas fa-user-plus"></i>
                </div>
              </div>
              <div className="col-7 col-stats">
                <div className="numbers">
                  <p className="card-category">Usuarios NovemApp</p>
                  <h4 className="card-title">
                    {!isFechingUser ? (
                      userAll
                    ) : (
                      <GridLoader
                        size={6}
                        css={override}
                        color={"#19769F"}
                        loading={true}
                      />
                    )}
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {permissionsValidate("medical") >= 0 && (
        <div className="col-sm-6 col-md-3">
          <div className="card card-stats card-round">
            <div className="card-body ">
              <div className="row">
                <div className="col-5">
                  <div className="icon-big text-center">
                    <i className="fas fa-user-md "></i>
                  </div>
                </div>
                <div className="col-7 col-stats">
                  <div className="numbers">
                    <p className="card-category">Usuarios Médicos</p>
                    <h4 className="card-title">
                      {!isFechingMedical ? (
                        medicalAll
                      ) : (
                        <GridLoader
                          size={6}
                          css={override}
                          color={"#19769F"}
                          loading={true}
                        />
                      )}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="col-sm-6 col-md-3">
        <div className="card card-stats card-round">
          <div className="card-body">
            <div className="row">
              <div className="col-5">
                <div className="icon-big text-center">
                  <i className="fas fa-file-medical-alt"></i>
                </div>
              </div>
              <div className="col-7 col-stats">
                <div className="numbers">
                  <p className="card-category">Clic en expediente</p>
                  <h4 className="card-title"> </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-sm-6 col-md-3">
        <div className="card card-stats card-round">
          <div className="card-body">
            <div className="row">
              <div className="col-5">
                <div className="icon-big text-center">
                  <i className="fas fa-briefcase-medical"></i>
                </div>
              </div>
              <div className="col-7 col-stats">
                <div className="numbers">
                  <p className="card-category">Citas Solicitadas</p>
                  <h4 className="card-title"> </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    userAll: state.user.userAll,
    medicalAll: state.medical.medicalAll,
    isFechingMedical: state.medical.isFeching,
    isFechingUser: state.user.isFeching,
    permissions: state.user.user.user.permissions,
  };
};

export default compose(connect(mapStateToProps))(DashboardCards);
