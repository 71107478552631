import React, { Component } from "react";
import NotificationAll from "../GeneralInquiry/notificationAll";
import { submit, reset } from "redux-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalGlobal from "../Modals";
import NotificacionModal from "./modalNotificationsGlobal";

import { openModal } from "../../actions/modal_actions";
import { resetNotifications } from "../../actions/notifications_actions";
class NotificationsTable extends Component {
  render() {
    return (
      <div className="row">
        <div className="col-md-6" style={{ margin: "auto" }}>
          <div className="card full-height">
            <div className="card-header">
              <div className="card-title">Enviar Notificaciones</div>
            </div>
            <div className="card-body">
              <NotificationAll />
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-link"
                style={{ backgroundColor: "#cecece" }}
                onClick={() => this.props.reset("notificationForm")}
              >
                Resetear
              </button>

              <button
                className="btn btn-sm"
                style={{ backgroundColor: "#19769F" }}
                onClick={() => {
                  this.props.openModal({
                    title: "Enviando notificación",
                    itemClassName: "modal-xl",
                    send: true,
                  });

                  //this.props.submit("notificationForm");
                }}
              >
                Enviar
              </button>
            </div>
          </div>
        </div>
        <ModalGlobal onClose={() => this.props.resetNotifications()}>
          <NotificacionModal />
        </ModalGlobal>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    { submit, reset, openModal, resetNotifications },
    dispatch
  );

export default connect(null, mapDispatchToProps)(NotificationsTable);
