import { stadisticConstants } from "../constansts/stadistic.constants";

const initialState = {
  use: [],
  loading: false,
  health: {},
  healthLoading: false,
  token: [],
  successToken: false,
  tokenSend: [],
  tokenError: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case stadisticConstants.STADISTIC_USE:
      return {
        ...state,
        use: action.data,
        loading: false
      };

    case stadisticConstants.STADISTIC_HEALTH:
      return {
        ...state,
        health: action.data,
        healthLoading: false
      };
    case stadisticConstants.STADISTIC_HEALTH_LOADING:
      return {
        ...state,
        healthLoading: true,
        health: {}
      };
    case stadisticConstants.STADISTIC_TOKEN:
      return {
        ...state,
        token: action.data,
        successToken: false
      };
    case stadisticConstants.STADISTIC_TOKEN_SUCCESS:
      return {
        ...state,
        token: [],
        tokenSend: [],
        tokenError: [],
        successToken: true
      };
    case stadisticConstants.STADISTIC_TOKEN_SEND:
      let tokenSend = [...state.tokenSend];

      tokenSend = tokenSend.concat(action.data);

      return {
        ...state,
        tokenSend
      };
    case stadisticConstants.STADISTIC_TOKEN_ERROR:
      let tokenError = [...state.tokenError];

      tokenError = tokenError.concat(action.data);

      return {
        ...state,
        tokenError: tokenError
      };
    case stadisticConstants.LOADING:
      return {
        ...state,
        use: {},
        loading: true
      };
    default:
      return state;
  }
}
