import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import SideBar from "./sidebar/Sidebar";

export default class Container extends Component {
  constructor() {
    super();
    this.state = {
      menu: true
    };
  }

  getResponse() {
    this.setState({ menu: !this.state.menu });
  }
  render() {
    return (
      <div className="wrapper">
        <Header callback={this.getResponse.bind(this)} />
        <SideBar menu={this.state.menu} />
        <div
          className="main-panel"
          style={!this.state.menu ? { width: "100%" } : {}}
        >
          {this.props.children}

          <Footer />
        </div>
      </div>
    );
  }
}
