import React, { Component } from "react";
import { Link } from "react-router-dom";

class Header extends Component {
  render() {
    return (
      <>
        <div className="main-header fixed-top">
          <div className="logo-header" data-background-color="blue">
            <Link to="/">
              <img
                src="../assets/img/logo.png"
                alt="navbar brand"
                className="navbar-brand"
              />
            </Link>

            <button
              onClick={() => {
                this.props.callback();
              }}
              className="navbar-toggler sidenav-toggler ml-auto"
              type="button"
              data-toggle="collapse"
              data-target="collapse"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <i className="icon-menu"></i>
              </span>
            </button>
            <button
              onClick={() => {
                this.props.callback();
              }}
              className="topbar-toggler more toggled"
            >
              <i className="icon-options-vertical"></i>
            </button>
            <div className="nav-toggle">
              <button
                className="btn btn-toggle toggle-sidebar"
                onClick={() => {
                  this.props.callback();
                }}
              >
                <i className="icon-menu"></i>
              </button>
            </div>
          </div>
          <nav
            className="navbar navbar-header navbar-expand-lg"
            data-background-color="blue2"
          >
            <div className="container-fluid">
              <div className="collapse" id="search-nav">
                <form className="navbar-left navbar-form nav-search mr-md-3">
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <button type="submit" className="btn btn-search pr-1">
                        <i className="fa fa-search search-icon"></i>
                      </button>
                    </div>
                    <input
                      type="text"
                      placeholder="Search ..."
                      className="form-control"
                    />
                  </div>
                </form>
              </div>
              <ul className="navbar-nav topbar-nav ml-md-auto align-items-center">
                <li className="nav-item toggle-nav-search hidden-caret">
                  <a
                    className="nav-link"
                    data-toggle="collapse"
                    href="#search-nav"
                    role="button"
                    aria-expanded="false"
                    aria-controls="search-nav"
                  >
                    <i className="fa fa-search"></i>
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </>
    );
  }
}

export default Header;
