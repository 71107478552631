import React, { useEffect, useState } from "react";
import "./view2.css";
import { Doughnut } from "react-chartjs-2";

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => {
    return {
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    };
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
const View2 = ({ index, widthAll, heightAll, data }) => {
  const data2 = {
    labels: data.map((item) => {
      return item.title + "  " + item.total;
    }),
    datasets: [
      {
        weight: 500,
        steppedLine: "after",
        spanGaps: true,
        data: data.map((item) => {
          return item.total;
        }),
        // hidden: true,
        backgroundColor: [
          "steelblue",
          "LightBlue",
          "LightSteelBlue",
          "#FF6633",
          "#FFB399",
          "#FF33FF",
          "#FFFF99",
          "#00B3E6",
          "#E6B333",
          "#3366E6",
          "#999966",
          "#99FF99",
          "#B34D4D",
          "#80B300",
          "#809900",
          "#E6B3B3",
          "#6680B3",
          "#66991A",
          "#FF99E6",
          "#CCFF1A",
          "#FF1A66",
          "#E6331A",
          "#33FFCC",
          "#66994D",
          "#B366CC",
          "#4D8000",
          "#B33300",
          "#CC80CC",
          "#66664D",
          "#991AFF",
          "#E666FF",
          "#4DB3FF",
          "#1AB399",
          "#E666B3",
          "#33991A",
          "#CC9999",
          "#B3B31A",
          "#00E680",
          "#4D8066",
          "#809980",
          "#E6FF80",
          "#1AFF33",
          "#999933",
          "#FF3380",
          "#CCCC00",
          "#66E64D",
          "#4D80CC",
          "#9900B3",
          "#E64D66",
          "#4DB380",
          "#FF4D4D",
          "#99E6E6",
          "#6666FF",
        ],
        hoverBackgroundColor: [
          "steelblue",
          "LightBlue",
          "LightSteelBlue",
          "#FF6633",
          "#FFB399",
          "#FF33FF",
          "#FFFF99",
          "#00B3E6",
          "#E6B333",
          "#3366E6",
          "#999966",
          "#99FF99",
          "#B34D4D",
          "#80B300",
          "#809900",
          "#E6B3B3",
          "#6680B3",
          "#66991A",
          "#FF99E6",
          "#CCFF1A",
          "#FF1A66",
          "#E6331A",
          "#33FFCC",
          "#66994D",
          "#B366CC",
          "#4D8000",
          "#B33300",
          "#CC80CC",
          "#66664D",
          "#991AFF",
          "#E666FF",
          "#4DB3FF",
          "#1AB399",
          "#E666B3",
          "#33991A",
          "#CC9999",
          "#B3B31A",
          "#00E680",
          "#4D8066",
          "#809980",
          "#E6FF80",
          "#1AFF33",
          "#999933",
          "#FF3380",
          "#CCCC00",
          "#66E64D",
          "#4D80CC",
          "#9900B3",
          "#E64D66",
          "#4DB380",
          "#FF4D4D",
          "#99E6E6",
          "#6666FF",
        ],
      },
    ],
  };

  return (
    <div className="pane" key={index}>
      <div className="title">
        <Doughnut
          data={data2}
          index={index}
          width={widthAll}
          height={heightAll}
          options={{
            maintainAspectRatio: true,
            responsive: false,
            legend: {
              position: "left",
              labels: {
                boxWidth: 8,
              },
            },
          }}
        />
      </div>
    </div>
  );
};
export default View2;

/*<Doughnut
  data={data1}
  index={index}
  width={widthAll}
  options={{
    responsive: true,

    legend: {
      labels: {
        boxWidth: 20
      },
      position: "chartArea",
      align: "center"
    }
  }}
/>
<Doughnut
  data={data2}
  index={index}
  width={widthAll}
  height={heightAll}
  options={{
    maintainAspectRatio: true,
    responsive: true,

    legend: {
      labels: {
        boxWidth: 20,
        padding: 0
      },
      position: "chartArea",
      align: "start",
      fullWidth: false,
      reverse: true,
      display: false
    }
  }}
/>

<Doughnut
  data={data2}
  index={index}
  width={widthAll}
  height={heightAll}
  options={{
    maintainAspectRatio: true,
    responsive: true,

    legend: {
      labels: {
        boxWidth: 20,
        padding: 0
      },
      position: "chartArea",
      align: "start",
      fullWidth: false,
      reverse: true
      //display: false
    }
  }}
/>*/

/*
export class View3 extends Component {
  render() {
   
    const { index } = this.props;
    const width = this.props.width;
    const height = this.props.height;
    const data2 = {
      labels: this.props.data.map(item => {
        return item.title + "  " + item.total;
      }),
      datasets: [
        {
          backgroundColor: [
            "steelblue",
            "LightBlue",
            "LightSteelBlue",
            "#FF6633",
            "#FFB399",
            "#FF33FF",
            "#FFFF99",
            "#00B3E6",
            "#E6B333",
            "#3366E6",
            "#999966",
            "#99FF99",
            "#B34D4D",
            "#80B300",
            "#809900",
            "#E6B3B3",
            "#6680B3",
            "#66991A",
            "#FF99E6",
            "#CCFF1A",
            "#FF1A66",
            "#E6331A",
            "#33FFCC",
            "#66994D",
            "#B366CC",
            "#4D8000",
            "#B33300",
            "#CC80CC",
            "#66664D",
            "#991AFF",
            "#E666FF",
            "#4DB3FF",
            "#1AB399",
            "#E666B3",
            "#33991A",
            "#CC9999",
            "#B3B31A",
            "#00E680",
            "#4D8066",
            "#809980",
            "#E6FF80",
            "#1AFF33",
            "#999933",
            "#FF3380",
            "#CCCC00",
            "#66E64D",
            "#4D80CC",
            "#9900B3",
            "#E64D66",
            "#4DB380",
            "#FF4D4D",
            "#99E6E6",
            "#6666FF"
          ],
          hoverBackgroundColor: [
            "steelblue",
            "LightBlue",
            "LightSteelBlue",
            "#FF6633",
            "#FFB399",
            "#FF33FF",
            "#FFFF99",
            "#00B3E6",
            "#E6B333",
            "#3366E6",
            "#999966",
            "#99FF99",
            "#B34D4D",
            "#80B300",
            "#809900",
            "#E6B3B3",
            "#6680B3",
            "#66991A",
            "#FF99E6",
            "#CCFF1A",
            "#FF1A66",
            "#E6331A",
            "#33FFCC",
            "#66994D",
            "#B366CC",
            "#4D8000",
            "#B33300",
            "#CC80CC",
            "#66664D",
            "#991AFF",
            "#E666FF",
            "#4DB3FF",
            "#1AB399",
            "#E666B3",
            "#33991A",
            "#CC9999",
            "#B3B31A",
            "#00E680",
            "#4D8066",
            "#809980",
            "#E6FF80",
            "#1AFF33",
            "#999933",
            "#FF3380",
            "#CCCC00",
            "#66E64D",
            "#4D80CC",
            "#9900B3",
            "#E64D66",
            "#4DB380",
            "#FF4D4D",
            "#99E6E6",
            "#6666FF"
          ]
        }
      ]
    };
    const data = {
      labels: this.props.data.map(item => {
        return item.title + "  " + item.total;
      }),
      datasets: [
        {
          data: this.props.data.map(item => {
            return item.total;
          }),
          // hidden: true,
          backgroundColor: [
            "steelblue",
            "LightBlue",
            "LightSteelBlue",
            "#FF6633",
            "#FFB399",
            "#FF33FF",
            "#FFFF99",
            "#00B3E6",
            "#E6B333",
            "#3366E6",
            "#999966",
            "#99FF99",
            "#B34D4D",
            "#80B300",
            "#809900",
            "#E6B3B3",
            "#6680B3",
            "#66991A",
            "#FF99E6",
            "#CCFF1A",
            "#FF1A66",
            "#E6331A",
            "#33FFCC",
            "#66994D",
            "#B366CC",
            "#4D8000",
            "#B33300",
            "#CC80CC",
            "#66664D",
            "#991AFF",
            "#E666FF",
            "#4DB3FF",
            "#1AB399",
            "#E666B3",
            "#33991A",
            "#CC9999",
            "#B3B31A",
            "#00E680",
            "#4D8066",
            "#809980",
            "#E6FF80",
            "#1AFF33",
            "#999933",
            "#FF3380",
            "#CCCC00",
            "#66E64D",
            "#4D80CC",
            "#9900B3",
            "#E64D66",
            "#4DB380",
            "#FF4D4D",
            "#99E6E6",
            "#6666FF"
          ],
          hoverBackgroundColor: [
            "steelblue",
            "LightBlue",
            "LightSteelBlue",
            "#FF6633",
            "#FFB399",
            "#FF33FF",
            "#FFFF99",
            "#00B3E6",
            "#E6B333",
            "#3366E6",
            "#999966",
            "#99FF99",
            "#B34D4D",
            "#80B300",
            "#809900",
            "#E6B3B3",
            "#6680B3",
            "#66991A",
            "#FF99E6",
            "#CCFF1A",
            "#FF1A66",
            "#E6331A",
            "#33FFCC",
            "#66994D",
            "#B366CC",
            "#4D8000",
            "#B33300",
            "#CC80CC",
            "#66664D",
            "#991AFF",
            "#E666FF",
            "#4DB3FF",
            "#1AB399",
            "#E666B3",
            "#33991A",
            "#CC9999",
            "#B3B31A",
            "#00E680",
            "#4D8066",
            "#809980",
            "#E6FF80",
            "#1AFF33",
            "#999933",
            "#FF3380",
            "#CCCC00",
            "#66E64D",
            "#4D80CC",
            "#9900B3",
            "#E64D66",
            "#4DB380",
            "#FF4D4D",
            "#99E6E6",
            "#6666FF"
          ]
        }
      ]
    };
    return (
      <div className="pane" key={index}>
        <div className="title" style={{ backgroundColor: "red" }}>
          <Doughnut
            data={data}
            index={index}
            width={width}
            options={{
              responsive: true,
              elements: {},
              legend: {
                labels: {
                  boxWidth: 20
                },
                position: "chartArea",
                align: "center"
              },
              scale: {
                position: "chartArea",
                display: false
              },
              scales: {
                display: false
              }
            }}
          />
        </div>

        <Doughnut
          data={data}
          index={index}
          width={width}
          height={300}
          options={{
            responsive: true,

            legend: {
              labels: {
                boxWidth: 20,
                padding: 0
              },
              position: "bottom",
              align: "center",

              display: false
            }
          }}
        />
      </div>
    );
  }
}
*/
