import { listUsers } from "./user_actions";
import { userConstants } from "../constansts/user.constants";
import { loading } from "./modal_actions";

import { listMedicals } from "./medical_actions";
import * as api from "../api/api_admin";
export const init = () => {
  return (dispatch) => {
    let values = {
      filter: {
        page: 1,
        novem_id: null,
        fullname: "",
        email: null,
        age_start: 0,
        age_end: 0,
        country: null,
        status: null,
        insurance: null,
        limit: 100,
        last_conection: null,
      },
    };

    let valuesMedical = {
      filter: {
        page: 1,
        novem_id: null,
        fullname: null,
        email: null,
        age_start: 0,
        age_end: 0,
        limit: 100,
        country: null,
        status: null,
        insurance: null,
        last_conection: null,
      },
    };

    dispatch(listUsers(values));
    dispatch(listMedicals(valuesMedical));
    //dispatch(NavegationAll());
  };
};

export const onLogout = () => {
  let url = "admin/logout";

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;
    const request = api.requestToken(url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          //history.push("/login");
          dispatch({ type: userConstants.DESTROY_SESSION });
        }
      } catch (e) {}
    });
  };
};
