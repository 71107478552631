import React, { useEffect, useState } from "react";
import { Field, reduxForm, Form } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";

import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import "moment/locale/es";
import { CSVLink } from "react-csv";
import { exportUsers } from "../../actions/user_actions";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";

const override = css`
  display: inline-block;
  margin: 0 auto;
`;
moment.locale("es");

const user_type = [
  {
    value: "all",
    title: "Todos"
  },
  {
    value: "user",
    title: "Usuarios"
  },
  {
    value: "contact_all",
    title: "Contactos de emergencia"
  },
  {
    value: "contact_unregister",
    title: "Contactos sin registrar"
  }
];
const type_list = [
  {
    value: "phones",
    title: "Telefonos"
  },
  {
    value: "emails",
    title: "Correos"
  }
];

let detailsFormDoctor = props => {
  const { handleSubmit, exportUsers } = props;
  const [loading, setloading] = useState(false);
  const [result, setresult] = useState(null);
  const [success, setsuccess] = useState(false);
  const [title, settitle] = useState("");
  useEffect(() => {
    let userType = user_type[0].value;
    if (props.type === "medical") userType = "medical";

    props.change("user_type", userType);

    props.change("list_type", type_list[0].value);
  }, []);

  const onSubmit = data => {
    setloading(true);
    setresult(null);
    setsuccess(false);
    let body = {
      user_type: data.user_type,
      list_type: data.list_type
    };
    settitle(data.list_type + moment().format("YYYY-MM-DD-HH-mm-ss"));

    exportUsers(body).then(values => {
      if (values.data) {
        let result = values.data;
        result = result.map(function(item) {
          return [item];
        });

        setresult(result);
      }
      setsuccess(true);
      setloading(false);
    });
  };

  return (
    <>
      {loading ? (
        <GridLoader size={12} css={override} color={"#19769F"} loading={true} />
      ) : !success ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            {props.type === "user" && (
              <Row>
                <Col>
                  <div className="select2-input py-2">
                    <div className="form-group">
                      <label className="placeholder">
                        <b>Tipo de usuario</b>
                      </label>
                      <div className="position-relative">
                        <Field
                          name={"user_type"}
                          component={"select"}
                          className={"form-control"}
                        >
                          {user_type &&
                            user_type.map((item, i) => {
                              return (
                                <option value={item.value} key={i}>
                                  {item.title}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <div className="select2-input py-2">
                  <div className="form-group">
                    <label className="placeholder">
                      <b>Tipo de lista</b>
                    </label>
                    <div className="position-relative">
                      <Field
                        name={"list_type"}
                        component={"select"}
                        className={"form-control"}
                      >
                        {type_list &&
                          type_list.map((item, i) => {
                            return (
                              <option value={item.value} key={i}>
                                {item.title}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      ) : result && result.length > 0 ? (
        <>
          <p>Generado con éxito </p>
          <CSVLink
            data={result}
            filename={title + ".csv"}
            className="btn btn-primary"
            target="_blank"
            enclosingCharacter={``}
            separator=";"
          >
            <button
              className="btn btn-sm"
              style={{ backgroundColor: "#19769F" }}
              type="button"
            >
              Descargar
            </button>
          </CSVLink>
        </>
      ) : (
        <p>No hay resultados para esta busqueda</p>
      )}
    </>
  );
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ exportUsers }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "notificationForm"
  })
)(detailsFormDoctor);
