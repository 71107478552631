import React, { useEffect } from "react";
import { reduxForm } from "redux-form"; 
import { Col, Container, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import moment from "moment";
import "moment/locale/es";
moment.locale("es");

let detailsFormDoctor = props => {
  const {
    handleSubmit, 
    data,
    change
  } = props;

  useEffect(() => { 

    if (data.medical) {
      if (data.medical.medical_number)
        change("medical_number", data.medical.medical_number);

      if (data.medical.medical_presenter) {
        if (data.medical.medical_presenter.email)
          change("email", data.medical.medical_presenter.email);
      }
    }
  }, [data]);

  const medical_number = () => {
    let medical_data = "No posee";
    if (data && data.medical && data.medical.medical_number)
      medical_data = data.medical.medical_number;

    return medical_data;
  };

  const governmental_number = () => {
    let governmental_data = "No posee";

    if (data && data.medical && data.medical.governmental_number)
      governmental_data = data.medical.governmental_number;

    return governmental_data;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={5} md={3}>
            <Image src={data.pictureUrl} thumbnail />
          </Col>
          <Col xs={11} md={9}>
            <Container>
              <div>Nombre: {data.name}</div>
              <div>Apellido: {data.lastname}</div>
              <div>Fecha de nacimiento: {data.birthdate}</div>
              <div>Sexo: {data.gender}</div>
              <div>Número de colegio de médicos: {medical_number()}</div>
              <div>Número de ministerio de salud: {governmental_number()}</div>

              <div>
                Teléfono:
                {data &&
                data.medical &&
                data.medical.medical_presenter &&
                data.medical.medical_presenter.phones.length > 0 ? (
                  data.medical.medical_presenter.phones.map(value => {
                    return (
                      <>
                        {value.country_code}({value.number})
                      </>
                    );
                  })
                ) : (
                  <> No posee</>
                )}
              </div>
              <div>
                Correo:{" "}
                {data && data.medical && data.medical.medical_presenter.email}
              </div>
            </Container>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

detailsFormDoctor = reduxForm({
  form: "initializeFromState"
})(detailsFormDoctor);

export default detailsFormDoctor;
