import React, { useEffect, useState } from "react";
import { renderField } from "../Forms/renderField";

import { Field, reduxForm, SubmissionError } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import { noticeSave } from "../../actions/notice_actions";
import { store } from "../../store/store";
import { connect } from "react-redux";
moment.locale("es");

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, error },
  ...props
}) => {
  return (
    <div>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
      {touched && error && (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            color: "#b83205",
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
};
const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export function onSubmitNotice(data) {
  return sleep(1000).then(() => {
    if (!data.title || data.title.length === 0) {
      throw new SubmissionError({
        title: "Requerido",
        _error: "Login failed!",
      });
    }
    if (!data.subtitle || data.subtitle.length === 0) {
      throw new SubmissionError({
        subtitle: "Requerido",
        _error: "Login failed!",
      });
    }
    if (!data.link || data.link.length === 0) {
      throw new SubmissionError({
        link: "Requerido",
        _error: "Login failed!",
      });
    }
    if (!data.typeNotice || data.typeNotice.length === 0) {
      throw new SubmissionError({
        typeNotice: "Requerido",
        _error: "Login failed!",
      });
    }

    if (!data.image) {
      throw new SubmissionError({
        image: "Requerido",
        _error: "Login failed!",
      });
    }

    let reader = new FileReader();
    reader.readAsDataURL(data.image);

    reader.onloadend = () => {
      let picture = reader.result.slice(22, reader.result.length);

      let body = {
        notice: {
          title: data.title,
          subtitle: data.subtitle,
          link: data.link,
          type_notice: data.typeNotice,
          release_date: null,
        },
        picture64: picture,
      };

      store.dispatch(noticeSave(body));
    };
  });
}

let detailsFormDoctor = (props) => {
  const { handleSubmit, dataform } = props;

  useEffect(() => {
    if (dataform && dataform.values && dataform.values.image) {
      let newstate = [];

      let file = dataform.values.image;
      let metadata = getFileMetadata(file);
      let url = URL.createObjectURL(file);
      newstate = { url, metadata };

      setFiles(newstate);
    }
  }, [dataform]);

  const [files, setFiles] = useState({});

  const getFileMetadata = (file) => {
    /**
     * The way we are handling uploads does not allow us to
     * turn the uploaded [object File] into JSON.
     *
     * Therefore, we have to write our own "toJSON()" method.
     */
    return {
      lastModified: file.lastModified,
      name: file.name,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
    };
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row>
          <Col xs={5}>
            {Object.keys(files).length > 0 && (
              <div>
                <img src={files.url} height="200" width="200" alt="test" />
              </div>
            )}
            <Field name="image" component={FileInput} type="file" />
          </Col>

          <Col xs={7} className="center-block" style={{ margin: "auto" }}>
            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="title"
                    component={renderField}
                    className="form-control"
                    type="text"
                    placeholder={"Titulo"}
                  />
                </div>
              </Col>
            </Row>

            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="subtitle"
                    component={renderField}
                    type="text"
                    className="form-control"
                    placeholder={"SubTitulo"}
                  />
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="link"
                    component={renderField}
                    type="text"
                    className="form-control"
                    placeholder={"Enlace / Link"}
                  />
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="typeNotice"
                    component={renderField}
                    type="text"
                    className="form-control"
                    placeholder={"Tipo / Categoria"}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const mapStateToProps = (state) => ({
  dataform: state.form.notificationForm,
});

detailsFormDoctor = reduxForm({
  form: "notificationForm",
  onSubmit: onSubmitNotice,
})(detailsFormDoctor);

export default connect(mapStateToProps)(detailsFormDoctor);
