import React, { useEffect } from "react";
import { reduxForm, Form } from "redux-form";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import { deleteUsers } from "../../actions/user_actions";

function deleteUser(props) {
  const { handleSubmit, deleteUsers, data, change } = props;

  const state = false;

  useEffect(() => {
    if (data._id) {
      change("_id", data._id);
    }
  }, []);

  const onSubmit = (data) => {
    if (
      window.confirm(
        "A un paso de completar esta acción. ¿Está seguro de que deseas continuar"
      )
    ) {
      let body = {
        user_id: data._id,
      };
      deleteUsers(body);
    }
  };

  let country = "Sin definir";
  if (data.country !== "{}") country = data.country;
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {state ? (
        <div>
          <h5 className="text-center">
            A un paso de completar esta acción. ¿Está seguro de que deseas
            eliminar al usuario {data.name} {data.lastname}?
          </h5>
        </div>
      ) : (
        <div>
          <h5 className="text-left">
            ¿Estás seguro de que deseas eliminar al usuario?
          </h5>

          <div className="container mt-4 alert">
            <div className="row">
              <div className="col font-weight-bold">Novem ID</div>
              <div className="col">{data.identificator}</div>
            </div>
            <div className="row">
              <div className="col font-weight-bold">Nombre</div>
              <div className="col"> {data.name} </div>
            </div>
            <div className="row">
              <div className="col font-weight-bold">Apellido</div>
              <div className="col">{data.lastname}</div>
            </div>
            <div className="row">
              <div className="col font-weight-bold">Email</div>
              <div className="col">
                {data.emails && data.emails.length > 0 && data.emails[0].email}
              </div>
            </div>
            <div className="row">
              <div className="col font-weight-bold">Pais</div>
              <div className="col">{country}</div>
            </div>
          </div>

          <h5 className="font-weight-bold text-center">NOTA:</h5>
          <h5 className="text-danger font-weight-bold">
            Esta acción debe ser utilizada unicamente para usuarios de prueba.
            No podrá deshacerse una vez completada.
          </h5>
        </div>
      )}
    </Form>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ deleteUsers }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "notificationForm",
  })
)(deleteUser);
