import { modalConstants } from "../constansts/modal.constants";

const initialState = {
  modal: false,
  isFeching: false,
  title: null,
  className: null,
  error: false, //before null,
  send: true,
  cancel: true,
  reset: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case modalConstants.SHOWMODAL:
      let send = state.send;

      if (typeof action.data.send !== "undefined") send = action.data.send;
      else send = true;

      return {
        ...state,
        modal: true,
        title: action.data.title,
        className: action.data.itemClassName,
        send: send
      };
    case modalConstants.UPDATEMODAL:
      let newObject = Object.assign(state, action.data);

      return newObject;
    case modalConstants.HIDDENMODAL:
      return initialState;
    case modalConstants.LOADING:
      return {
        ...state,
        isFeching: true
      };
    case modalConstants.FAILURE:
      return {
        ...state,
        //isFeching: false,
        error: true,
        message: action.message,
        option: null
      };
    case modalConstants.RESET:
      return {
        ...state,
        error: false,
        isFeching: false,
        message: null
      };
    case modalConstants.SUCCESS:
      return initialState;
    default:
      return state;
  }
}
