import React, { Component } from "react";
import { Link } from "react-router-dom";

class GeneralStatistics extends Component {
  addingClass() {
    document.getElementById("divSetting").className += " active";
  }

  render() {
    return (
      <div>
        <ul className="nav nav-primary">
          <li className="nav-item submenu" id="divSetting">
            <a
              data-toggle="collapse"
              href="#settings"
              className="collapsed"
              aria-expanded="false"
              onClick={this.addingClass.bind(this)}
            >
              <i className="fas fa-chart-line"></i>
              <p>Configuraciones</p>
              <span className="caret"></span>
            </a>
            <div className="collapse" id="settings">
              <ul className="nav nav-collapse">
                <li>
                  <Link to="/navegation">
                    <span className="sub-item">Navegacion</span>
                  </Link>
                </li>
              </ul>
            </div>
          </li>
        </ul>
      </div>
    );
  }
}

export default GeneralStatistics;
