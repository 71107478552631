import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalGlobal from "../../Modals";

import { openModal } from "../../../actions/modal_actions";
import { transactionAllUser } from "../../../actions/transactions_user_actions";
import { faTrash, faCheck } from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { permissionsValidate } from "../../../functions";
import AproverejectNotice from "./aprovereject";

const override = css`
  display: inline-block;
  margin: 0 auto;
`;

const ButtonIcon = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      data-toggle="tooltip"
      title={props.title}
      className="btn btn-link btn-primary btn-lg"
    >
      <FontAwesomeIcon icon={props.name} size="lg" color={props.color} />
    </button>
  );
};

const NewsTable = ({
  permissionsArray,
  transactionAllUser,
  success,
  data,
  openModal,
}) => {
  const [dataNotice, setdataNotice] = useState([]);
  const [item, setItem] = useState(null);
  const [typeModal, settypeModal] = useState(null);
  const [permissions, setpermissions] = useState([]);

  let result = -1;
  const buttonData = [
    {
      title: "Aprobar",
      type: "approve",
      status: "approved",
      name: faCheck,
      color: "#009688",
      itemClassName: "modal-dialog",
    },

    {
      title: "Rechazar",
      type: "reject",
      status: "rejected",
      name: faTrash,
      color: "#be2020",
      itemClassName: "modal-dialog",
    },
  ];

  useEffect(() => {
    if (permissions.length === 0) {
      let searchModule = permissionsValidate(permissionsArray, "notice");
      if (searchModule >= 0)
        setpermissions(permissionsArray[searchModule].action);
    }
    if (success || data.length !== dataNotice.length) {
      setdataNotice(data);
    } else {
      transactionAllUser();
    }
  }, [data, success]);

  const bodyModal = () => {
    if (typeModal && typeModal.type)
      switch (typeModal.type) {
        case "approve":
          return <AproverejectNotice data={item} type={typeModal} />;
        case "reject":
          return <AproverejectNotice data={item} type={typeModal} />;
        default:
          break;
      }
  };
  if (dataNotice.length > 0) {
    result = dataNotice.findIndex((value) => {
      return value.deleted_at === false;
    });
  }

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <h4 className="card-title">
              Transacciones Usuarios: Consultas online
            </h4>
          </div>
        </div>
        <div className="card-body">
          {!success ? (
            <div className="GridLoader">
              <GridLoader
                size={20}
                css={override}
                color={"#19769F"}
                loading={true}
              />
            </div>
          ) : (
            <div className="table-responsive">
              <table id="add-row" className="display table table-striped  ">
                <thead>
                  <tr>
                    <th className="text-center">Nombre</th>
                    <th className="text-center">Cantidad</th>
                    <th className="text-center">Fecha</th>
                    <th className="text-center">Titular</th>
                    <th className="text-center">Banco</th>
                    <th className="text-center">Referencia</th>
                    <th className="text-center">Tipo</th>
                    <th className="text-center">Estatus</th>
                    {permissions.includes("status_update") && (
                      <th className="text-center">Acciones</th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {dataNotice.length > 0 && result > -1 ? (
                    dataNotice.map((data, index) => {
                      if (!data.rejectd_at) {
                        let status;
                        switch (data.status) {
                          case "approved":
                            status = "Aprobado";

                            break;
                          case "rejected":
                            status = "Rechazado";

                            break;

                          default:
                            status = "Por definir";
                            break;
                        }
                        return (
                          <tr key={index}>
                            <td className="text-center">
                              {data.concept.origin_name}
                            </td>
                            <td className="text-center">
                              {data.payment_amount.usd_amount}
                            </td>
                            <td className="text-center">
                              {moment(data.created_at).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-center">
                              {data.payment_detail.headline}
                            </td>
                            <td className="text-center">
                              {data.payment_detail.bank}
                            </td>
                            <td className="text-center">
                              {data.payment_detail.reference}
                            </td>
                            <td className="text-center">
                              {data.type_transaction}
                            </td>
                            <td className="text-center">{status}</td>
                            <td className="text-center">
                              {buttonData.map((value, index) => {
                                if (
                                  data.status !== value.status &&
                                  data.status !== "rejected" &&
                                  data.status !== "approved"
                                )
                                  return (
                                    <ButtonIcon
                                      key={index}
                                      name={value.name}
                                      title={value.title}
                                      color={value.color}
                                      onClick={() => {
                                        settypeModal({
                                          type: value.type,
                                          title: value.title,
                                        });
                                        setItem(data);
                                        openModal({
                                          title: value.title,
                                          itemClassName: "modal-dialog",
                                        });
                                      }}
                                    />
                                  );
                                else return null;
                              })}
                            </td>
                          </tr>
                        );
                      } else return null;
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay transacciones actualmente
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <ModalGlobal>{bodyModal()}</ModalGlobal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.transactionuser.data,
  success: state.transactionuser.success,
  permissionsArray: state.user.user.user.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ transactionAllUser, openModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewsTable);
