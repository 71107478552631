import React, { useEffect, useState } from "react";
import { reduxForm } from "redux-form";
import { onSubmitSuspend } from "./onSubmit";

function suspendUser(props) {
  const { handleSubmit, data, change, type } = props;

  const [state, setstate] = useState("suspender");
  useEffect(() => {
    if (data._id) {
      change("_id", data._id);
      change("type", type);

      switch (type) {
        case "active":
          setstate("activar");
          break;
        case "suspend":
          setstate("suspender");
          break;
        case "reset":
          setstate("resetear la contraseña");
          break;

        default:
          break;
      }
    }
  }, [data]);
  return (
    <form onSubmit={handleSubmit}>
      <div>
        ¿Estás seguro de que deseas {state} al usuario {data.name}{" "}
        {data.lastname}?
      </div>
    </form>
  );
}

export default reduxForm({
  form: "notificationForm",
  onSubmit: onSubmitSuspend,
})(suspendUser);
