import React, { useEffect } from "react";
import { reduxForm } from "redux-form";
import { onSubmitSuspend } from "./onSubmit";

function suspendUser(props) {
  const { handleSubmit, data, change, type } = props;

  useEffect(() => {
    if (data._id) {
      change("_id", data._id);
      change("type", type.type);
    }
  }, [data]);
  return (
    <form onSubmit={handleSubmit}>
      <div>
        ¿Estás seguro de que deseas {type.title.toLowerCase()} la noticia:{" "}
        <b>{data.title} </b>
        {data.lastname}?
      </div>
    </form>
  );
}

 
export default reduxForm({
  form: "notificationForm",
  onSubmit: onSubmitSuspend,
})(suspendUser);
