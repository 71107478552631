import React, { Component } from "react";
import { Provider } from "react-redux";
import { store } from "./store/store";
import AppView from "./components";
import { refreshUser } from "./actions/user_actions";
class App extends Component {
  componentDidMount() {
    store.dispatch(refreshUser());
  }

  render() {
    return (
      <Provider store={store}>
        <AppView />
      </Provider>
    );
  }
}

export default App;
