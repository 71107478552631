import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { onSubmit } from "../GeneralInquiry/onSubmit";

import { getListNotification } from "../../actions/notifications_actions";

const modalNotificationsGlobal = ({
  user,
  dataform,
  userComplete,
  userFailure,
  getListNotification,
  usersSuccess,
}) => {
  const [userValue, setuserValue] = useState([]);
  useEffect(() => {
    if (user.length !== userValue.length) {
      if (user.length > 0) {
        /*let data = {
          _id: user[0]._id,
          message: dataform.message,
          navegation: dataform.navegation,
          tokens: user[0].access_devices,
          user: user[0]
        };*/
        dataform.user = user[0];
        dataform.tokens = user[0].access_devices;

        onSubmit(dataform);

        if (user.length === 100) {
          getListNotification();
        }
      }

      setuserValue(user);
    }
  }, [user, usersSuccess]);

  return (
    <div>
      {!usersSuccess ? (
        <div className="col ">
          <h4 className="font-weight-bold ">
            Valide los datos antes de continuar su proceso...
          </h4>
          <div className="w-50 p-3 m-auto">
            <div className="row">
              <h4 className="font-weight-bold">Sistema operativo: </h4>
              <h4>
                {dataform && dataform.type !== "all" ? dataform.type : "Todos"}
              </h4>
            </div>
            <div className="row">
              <h4 className="font-weight-bold">Tipo de notificación: </h4>
              <h4>
                {dataform && dataform.typeNotification !== "all"
                  ? dataform.typeNotification
                  : "Todos"}
              </h4>
            </div>
            <div className="row">
              <h4 className="font-weight-bold">País: </h4>
              <h4>
                {dataform && dataform.country ? dataform.country : "Todos"}
              </h4>
            </div>

            <div className="row">
              <h4 className="font-weight-bold">Edad mínima: </h4>
              <h4>
                {dataform && dataform.age_start > 0
                  ? dataform.age_start
                  : "Sin limite"}
              </h4>
            </div>
            <div className="row">
              <h4 className="font-weight-bold">Edad máxima: </h4>
              <h4>
                {dataform && dataform.age_end && dataform.age_end > 0
                  ? dataform.age_end
                  : "Sin limite"}
              </h4>
            </div>
            <div className="row">
              <h4 className="font-weight-bold">Seguro: </h4>
              <h4>
                {dataform && dataform.insurance ? dataform.insurance : "Todos"}
              </h4>
            </div>
            <div className="row">
              <h4 className="font-weight-bold">Texto de la notificación: </h4>
              <h4>{dataform && dataform.message}</h4>
            </div>
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="col-xs-6 col-sm-4">
            <div className="row d-flex justify-content-around">
              <h5 className="font-weight-bold">Por completar</h5>
              <h5 className="font-weight-bold">{userValue.length}</h5>
            </div>

            <div
              className="center-col overflow-auto"
              style={{ height: "60vh", background: "#f7f7f7" }}
            >
              {userValue.map((value, index) => (
                <p key={`item_${index}`}>{value.email}</p>
              ))}
            </div>
          </div>

          <div className="clearfix visible-xs"></div>
          <div className="col-xs-6 col-sm-4">
            <div className="row d-flex justify-content-around">
              <h5 className="font-weight-bold">Completado</h5>
              <h5 className="font-weight-bold">{userComplete.length}</h5>
            </div>
            <div
              className="center-col m-2 p-2"
              style={{ height: "60vh", background: "#f7f7f7" }}
            >
              {userComplete.length > 0 &&
                userComplete.map((value, index) => (
                  <div
                    className=" m-2 p-2"
                    key={`item_${index}`}
                    style={{ background: "#fff", border: "1px solid" }}
                  >
                    <div className="row">
                      <div className="col text-left">
                        {" "}
                        Nombre :{value.user.name} {value.user.lastname}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left">
                        Correo: {value.user.email}
                      </div>
                    </div>
                    {value.notification_response !== null && (
                      <div className="col">
                        <div className="col ">Notificación</div>
                        <div className="row">
                          <div className="col">
                            Enviadas:
                            {value.notification_response.send}
                          </div>
                          <div className="col">
                            Canceladas: {value.notification_response.cancell}
                          </div>
                        </div>
                      </div>
                    )}
                    {value.sms_response !== null && (
                      <div className="col">
                        <div className="col ">Sms</div>
                        <div className="row">
                          <div className="col">
                            Enviadas:
                            {value.sms_response.send}
                          </div>
                          <div className="col">
                            Canceladas: {value.sms_response.cancell}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ))}
            </div>
          </div>

          <div className="col-xs-6 col-sm-4">
            <div className="row d-flex justify-content-around">
              <h5 className="font-weight-bold">Error</h5>
              <h5 className="font-weight-bold">{userFailure.length}</h5>
            </div>

            <div
              className="center-col m-2 p-2"
              style={{ height: "60vh", background: "#f7f7f7" }}
            >
              {userFailure.length > 0 &&
                userFailure.map((value, index) => (
                  <div
                    className=" m-2 p-2"
                    key={`item_${index}`}
                    style={{ background: "#fff", border: "1px solid" }}
                  >
                    <div className="row">
                      <div className="col text-left">
                        Nombre :{value.user.name} {value.user.lastname}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col text-left">
                        Correono: {value.user.email}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col text-left">Mensaje: {value.msg}</div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.notification.usersList,
    usersSuccess: state.notification.usersSuccess,
    userComplete: state.notification.usersComplete,
    userFailure: state.notification.usersFailure,
    dataform: state.form.notificationForm.values,
  };
};
const mapDispatchToProps = { getListNotification };
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  modalNotificationsGlobal
);
