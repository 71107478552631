import { paginatorConstants } from "../constansts/paginator.constants";

const initialState = {
  pagSelect: 1,
  pagSearch: 1,
  pagLimit: 0,
  isFeching: false,
  search: [],
  success: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case paginatorConstants.PAGINATOR_PAG_SELECT:
      return {
        ...state,
        pagSelect: action.data
      };

    case paginatorConstants.PAGINATOR_PAG_SELECT_RESET:
      return {
        ...state,
        pagSelect: 1
      };
    case paginatorConstants.PAGINATOR_NOT_SUCCESS:
      return {
        ...state,
        success: false
      };
    case paginatorConstants.PAGINATOR_SUCCESS:
      let searchArray = [...state.search];
      searchArray.shift();
      return {
        ...state,
        success: true,
        search: searchArray
      };
    case paginatorConstants.PAGINATOR_PAG_SEARCH:
      return {
        ...state,
        pagSearch: state.pagSearch + 1,
        search: action.data
      };

    default:
      return state;
  }
}
