import React, { Component } from 'react';
import PanelHeader from '../PanelHeader';
import NotificationsTable from './NotificationsTable';



export default class Notifications extends Component {
    render() {
        return (
            <div className="content" >
              <div className="panel-header bg-primary-gradient">
                <PanelHeader/>
              </div>
              <div className="page-inner mt--5">
                <NotificationsTable />
              </div>
            </div>
        )
    }
}