import {
  individualSms,
  individualNotification,
  getListNotification,
  getBodyListNotification,
} from "../../actions/notifications_actions";
import { getSuccess } from "../../actions/modal_actions";
import {
  activeSuspendUser,
  filterListUsers,
  passwordResetUser,
} from "../../actions/user_actions";
import { filterListMedicals } from "../../actions/medical_actions";
import { getPagSelectReset } from "../../actions/paginator_actions";
import { store } from "../../store/store";
const { Expo } = require("expo-server-sdk");

/*const sendPushNotification = ({ title, tokens, message, navigate }) => {
  const notification = {
    somePushTokens: tokens,
    title: title,
    body: message,
    data: { navigate: navigate }
  };

  axios.post("http://192.168.1.112:3008/message", notification).then(res => {
    const result = res.data;
 
    let tokenError = [],
      tokenSuccess = [];

    if (result.status === 200) {
      result.tokens.map((item, i) => {
        if (item.status === "error") {
          tokenError.push(result.send[i]);
        } else {
          tokenSuccess.push(result.send[i]);
        }
        return item;
      });
 

      if (tokenError.length > 0) {
        store.dispatch(
          deleteTokens({
            list_token: tokenError
          })
        );
      }
    }

    if (result.status === 300) {
      result.tokens.map(item => {
        let data = result.notification;
        data.tokens = item.error;
        data.message = result.notification.body;

        sendPushNotification(data);
        return item;
      });
    }
  });
};*/

export function onSubmit(data) {
  if (data.typeNotification === "sms") {
    let body = {
      user_id: data.user._id,
      navegation_id: "5eb9d550eae75534f3c82512",
      options: {
        type_notification: data.typeNotification,
        type_destiny: "all",
        type_os: data.type,
      },
      msg: data.message,
    };

    store.dispatch(individualSms(body));
  } else {
    let tokens = [];

    data.tokens.map((item) => {
      if (item.token !== "") {
        if (Expo.isExpoPushToken(item.token)) {
          tokens.push(item.token);
        } else
          console.error(
            `Push token ${item.token} is not a valid Expo push token`
          );
      }
      return item;
    });

    data.tokens = tokens;

    store.dispatch(individualNotification(data));
  }
}

export function onSubmitAll(data) {
  let type = data.type;

  if (data.country === "Todos") data.country = null;

  if (data.insurance === "Todos") data.insurance = null;

  if (data.type === "all") type = null;
  /*let body = {
    filter: {
      page: 1,
      novem_id: null,
      fullname: null,
      email: null,
      age_start: parseInt(data.age_start),
      age_end: parseInt(data.age_end),
      country: data.country,
      status: null,
      insurance: data.insurance,
      limit: 0
    },

    options: {
      type_notification: data.typeNotification,
      type_destiny: "all",
      type_os: type
    },

    msg: data.message
  };*/

  let body = {
    filter: {
      page: 1,
      novem_id: null,
      fullname: null,
      email: null,
      age_start: parseInt(data.age_start),
      age_end: parseInt(data.age_end),
      country: data.country,
      status: null,
      insurance: data.insurance,
      limit: 100,
      os: type,
    },
  };

  store.dispatch(getBodyListNotification(body));
  store.dispatch(getListNotification(body));
}

export function onSubmitSuspend(data) {
  let body = {
    user_id: data._id,
  };

  if (data.type === "reset") store.dispatch(passwordResetUser(body, data.type));
  else store.dispatch(activeSuspendUser(body, data.type));
}

export function onSubmitFilter(data) {
  let type = data.type;

  let age_start = parseInt(data.age_start);
  let age_end = parseInt(data.age_end);
  if (data.country === "Todos") data.country = null;

  if (data.insurance === "Todos") data.insurance = null;

  if (data.last_conection === "Todos") data.last_conection = null;

  let body = {
    filter: {
      page: 1,
      novem_id: data.identificator ? data.identificator : null,
      fullname: data.name ? data.name.toLowerCase() : "",
      email: data.email ? data.email : "",
      age_start: age_start > 0 ? age_start : 0,
      age_end: age_end > 0 ? age_end : 0,
      country: data.country ? data.country : "",
      status: null,
      insurance: data.insurance ? data.insurance : "",
      limit: type === "user" ? 0 : 100,
      last_conection: data.last_conection ? data.last_conection : null,
      register_date: data.register_date ? data.register_date : null,
    },
  };
  store.dispatch(getPagSelectReset());
  store.dispatch(getSuccess());

  if (type === "user") {
    store.dispatch(filterListUsers(body));
  } else {
    //store.dispatch(listMedicals(body));

    store.dispatch(filterListMedicals(body));
  }
}
