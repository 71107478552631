import { noticeConstants } from "../constansts/notice.constants";
import {
  loading,
  getSuccess,
  getFailure, 
} from "../actions/modal_actions";

import * as api from "../api/api_admin";

export const getNoticeAll = data => {
  return { type: noticeConstants.NOTICE_ALL, data };
};

export const NoticeAllInit = () => {
  let url = "notice/all";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestToken(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getNoticeAll(result.data));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const noticeSave = body => {
  let url = "notice/save";

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then(result => {
      try {
        if (result.status === 200) {
          let notice = [...getState().notice.data];

          notice.push(result.data);

          dispatch(getNoticeAll(notice));
          dispatch(getSuccess());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {}
    });
  };
};

export const activeSuspendNotice = (body, type) => {
  let url = "notice/" + type;

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then(result => {
      try {
        if (result.status === 200) {
          let noticeList = [...getState().notice.data];

          let resultNotice = noticeList.findIndex(value => {
            return value._id === result.data._id;
          });

          noticeList[resultNotice] = result.data;

          dispatch(getNoticeAll(noticeList));
          dispatch(getSuccess());
        }
      } catch (e) {}
    });
  };
};
