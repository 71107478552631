import React, { useEffect, useState } from "react";
import { renderField } from "../Forms/renderField";
import { Field, reduxForm, Form } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import { navegationSave } from "../../actions/navegation_action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
moment.locale("es");

const adaptFileEventToValue = (delegate) => (e) => delegate(e.target.files[0]);

const FileInput = ({
  input: { value: omitValue, onChange, onBlur, ...inputProps },
  meta: { touched, error },
  ...props
}) => {
  return (
    <div>
      <input
        onChange={adaptFileEventToValue(onChange)}
        onBlur={adaptFileEventToValue(onBlur)}
        type="file"
        {...props.input}
        {...props}
      />
      {touched && error && (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 10,
            color: "#b83205",
          }}
        >
          {error}
        </span>
      )}
    </div>
  );
};

const validate = (values) => {
  const errors = {};
  if (!values.section) {
    errors.section = "Obligatorio";
  } else if (values.section.length < 3) {
    errors.section = "Debe tener mínimo 3 caracteres";
  }

  if (!values.link) {
    errors.link = "Obligatorio";
  } else if (values.link.length < 3) {
    errors.link = "Debe tener mínimo 3 caracteres";
  }

  if (!values.image) {
    errors.image = "Obligatorio";
  }

  return errors;
};

let detailsFormDoctor = (props) => {
  const { handleSubmit, dataform } = props;

  useEffect(() => {
    if (dataform && dataform.values && dataform.values.image) {
      let newstate = [];

      let file = dataform.values.image;
      let metadata = getFileMetadata(file);
      let url = URL.createObjectURL(file);
      newstate = { url, metadata };

      setFiles(newstate);
    }
  }, [dataform]);

  const onSubmitNotice = (data) => {
    let reader = new FileReader();
    reader.readAsDataURL(data.image);

    reader.onloadend = () => {
      let picture = reader.result.slice(22, reader.result.length);

      let body = {
        navegation: {
          section: data.section,
          link: data.link,
        },
        picture64: picture,
      };

      props.navegationSave(body);
    };
  };
  const [files, setFiles] = useState({});

  const getFileMetadata = (file) => {
    /**
     * The way we are handling uploads does not allow us to
     * turn the uploaded [object File] into JSON.
     *
     * Therefore, we have to write our own "toJSON()" method.
     */
    return {
      lastModified: file.lastModified,
      name: file.name,
      size: file.size,
      type: file.type,
      webkitRelativePath: file.webkitRelativePath,
    };
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitNotice)}>
      <Container>
        <Row>
          <Col xs={5}>
            {Object.keys(files).length > 0 && (
              <div>
                <img src={files.url} className="img-fluid" alt="test" />
              </div>
            )}
            <Field name="image" component={FileInput} type="file" />
          </Col>

          <Col xs={7} className="center-block" style={{ margin: "auto" }}>
            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="section"
                    component={renderField}
                    className="form-control"
                    type="text"
                    placeholder={"Sección"}
                  />
                </div>
              </Col>
            </Row>

            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="link"
                    component={renderField}
                    type="text"
                    className="form-control"
                    placeholder={"Enlace / Link"}
                  />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

const mapStateToProps = (state) => ({
  dataform: state.form.notificationForm,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ navegationSave }, dispatch);

detailsFormDoctor = reduxForm({
  form: "notificationForm",
  validate,
})(detailsFormDoctor);

export default connect(mapStateToProps, mapDispatchToProps)(detailsFormDoctor);
