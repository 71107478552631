import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class PanelHeader extends Component {
  render() {
    const { user } = this.props;
    return (
      <div className="page-inner py-5">
        <div className="d-flex align-items-left align-items-md-center flex-column flex-md-row">
          <div>
            <h2 className="text-white pb-2 fw-bold">
              Bienvenida {user && user.user && user.user.fullname}
            </h2>
            <h5 className="text-white op-7 mb-2">NovemApp Admin Dashboard</h5>
          </div>
          <div className="ml-md-auto py-2 py-md-0">
            <Link to="#" className="btn btn-secondary btn-round">
              Notificación Rápida
            </Link>
            <Link to="#" className="btn btn-secondary btn-round">
              Ver Usuarios
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps)(PanelHeader);
