import { combineReducers } from "redux";
import { reducer as form } from "redux-form";
import user from "./user_reducers";
import medical from "./medical_reducers";
import modal from "./modal_reducers";
import pagination from "./paginador_reducers";
import filter from "./filter";
import notification from "./notification_reducers";
import stadistics from "./stadistics_reducers";
import notice from "./notice_reducers";
import navegation from "./navegation_reducers";
import transaction from "./transaction_reducers";
import transactionuser from "./transaction_user_reducers";
import { userConstants } from "../constansts/user.constants";
const rootReducer = combineReducers({
  user,
  form,
  medical,
  modal,
  pagination,
  filter,
  notification,
  stadistics,
  notice,
  navegation,
  transaction,
  transactionuser
});

export default (state, action) => {
  if (action.type === userConstants.DESTROY_SESSION) {
    localStorage.removeItem("token");
    state = undefined;
  }

  return rootReducer(state, action);
};
