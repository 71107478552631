import { modalConstants } from "../constansts/modal.constants";

export const openModal = data => {
  return { type: modalConstants.SHOWMODAL, data };
};

export const updateModal = data => {
  return { type: modalConstants.UPDATEMODAL, data };
};

export const closeModal = () => {
  return { type: modalConstants.HIDDENMODAL };
};

export const loading = () => {
  return { type: modalConstants.LOADING };
};

export const getFailure = message => {
  return { type: modalConstants.FAILURE, message };
};

export const getSuccess = () => {
  return { type: modalConstants.SUCCESS };
};

export const reset = () => {
  return { type: modalConstants.RESET };
};
