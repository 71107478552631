import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verificateRejectDocuments } from "../../actions/medical_actions";
import { permissionsValidate } from "../../functions";

let offices = (props) => {
  const [state, setstate] = useState(props.data);
  const [select, setselect] = useState(null);
  const [permissions, setpermissions] = useState([]);

  useEffect(() => {
    if (permissions.length === 0) {
      let searchModule = permissionsValidate(props.permissions, "document");
      if (searchModule >= 0)
        setpermissions(props.permissions[searchModule].action);
    }

    let resultMedical = props.medicalList.find((value) => {
      return value._id === state.medical.medical_presenter.medical_id;
    });
    if (select !== null) {
      setstate(resultMedical);
      setselect(null);
    }
  }, [props.medicalList]);
  const actionsOffice = (value, status) => {
    setselect(value);
    let type;
    switch (status) {
      case "verificated":
        type = "reject";
        break;
      case "rejected":
        type = "verificate";

        break;
      default:
        break;
    }

    props.verificateRejectDocuments(
      value,
      state.medical.medical_presenter.medical_id,
      type
    );
  };

  return (
    <div>
      <Table responsive="xl">
        <thead>
          <tr>
            <th>Titulo</th>
            <th>Grado</th>
            <th>Instituto</th>
            <th>Certificado</th>
            <th>Estatus</th>
            {(permissions.includes("verificate") ||
              permissions.includes("reject")) && <th>Acción</th>}
          </tr>
        </thead>
        <tbody>
          {state &&
            state.medical &&
            state.medical.documents.map((value, index) => {
              let status; 

              switch (value.status) {
                case "verificated":
                  status = "Verificado"; 
                  break;
                case "rejected":
                  status = "Rechazado"; 
                  break;
                default:
                  status = "Sin verificar"; 
                  break;
              }
              let url = null;
              if (
                value.certificate &&
                value.certificate.url &&
                value.certificate.url.length > 0
              ) {
                url = value.certificate.url.slice(
                  19,
                  value.certificate.url.length
                );
                url = "https://dev.novemapp.com:8081" + url;
              }

              return (
                <tr key={index}>
                  <td>{value.title}</td>
                  <td>{value.grade}</td>
                  <td>{value.institute}</td>
                  <td>
                    {url !== null ? (
                      <Link
                        className="selectgroup-button"
                        to="route"
                        onClick={(event) => {
                          event.preventDefault();
                          window.open(url);
                        }}
                      >
                        ver
                      </Link>
                    ) : (
                      "No posee"
                    )}
                  </td>
                  <td>{status}</td>

                  <td>
                    <tr>
                      {permissions.includes("reject") &&
                        value.status !== "rejected" && (
                          <td>
                            <button
                              className={
                                url !== null
                                  ? "btn btn-sm btn-danger "
                                  : "btn btn-sm  btn-ligh"
                              }
                              onClick={() =>
                                actionsOffice(value, "verificated")
                              }
                              disabled={url !== null ? false : true}
                            >
                              Rechazar
                            </button>
                          </td>
                        )}

                      {permissions.includes("verificate") &&
                        value.status !== "verificated" && (
                          <td>
                            <button
                              className={
                                url !== null
                                  ? "btn btn-sm  btn-success"
                                  : "btn btn-sm  btn-ligh"
                              }
                              disabled={url !== null ? false : true}
                              onClick={() => actionsOffice(value, "rejected")}
                            >
                              Verificar
                            </button>
                          </td>
                        )}
                    </tr>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.user.user.user.permissions,
  medicalList: state.medical.medicalList,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ verificateRejectDocuments }, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(offices);
