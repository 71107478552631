import React from "react";
import PanelHeader from "../PanelHeader";
import NewTable from "./NewsTable";

const index = () => {
  return (
    <div className="content">
      <div className="panel-header bg-primary-gradient">
        <PanelHeader />
      </div>
      <div className="page-inner mt--5">
        <NewTable />
      </div>
    </div>
  );
};

export default index;
