import React, { useEffect, useState } from "react";
import { Field, reduxForm, Form } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import moment from "moment";
import "moment/locale/es";
import { notificationGetListByID } from "../../../actions/stadistic_actions";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import Notificationmessage from "./notificationmessage";
import Multiselect from "react-widgets/lib/Multiselect";
import "react-widgets/dist/css/react-widgets.css";

const override = css`
  display: inline-block;
  margin: 0 auto;
`;
moment.locale("es");
const renderMultiselect = ({ input, data, valueField, textField }) => (
  <Multiselect
    {...input}
    onBlur={() => input.onBlur()}
    value={input.value || []} // requires value to be an array
    data={data}
    valueField={valueField}
    textField={textField}
  />
);
const details = [
  { type: "families", details: ["history"] },
  { type: "habit", details: ["frecuencies"] },
  { type: "insurance", details: ["type_insurances"] },
  {
    type: "medicine_diagnostic_type",
    details: ["diagnostic", "type_medicament"],
  },
  { type: "type_medicine_diagnostic", details: ["medicine", "diagnostic"] },
  {
    type: "diagnostic_medicine_type",
    details: ["medicine", "type_medicament"],
  },
];

let detailsFormDoctor = (props) => {
  const {
    handleSubmit,
    notificationGetListByID,
    data,
    change,
    itemSelect,
  } = props;
  const [loading, setloading] = useState(false);
  const [result, setresult] = useState(null);
  const [success, setsuccess] = useState(false);
  const [item, setitem] = useState([]);
  const [show, setshow] = useState(false);
  const [isGoing, setisGoing] = useState(false);
  useEffect(() => {
    let resultMedical = details.find((value) => {
      return value.type === itemSelect;
    });

    if (resultMedical) {
      setshow(true);
    }
  }, []);

  const type_list_function = (e) => {
    let value = e.target.value;
    change("additional", []);
    if (isGoing) setisGoing(!isGoing);

    if (value > -1) {
      if (data[value].history) setitem(data[value].history);
      else if (data[value].frecuencies) setitem(data[value].frecuencies);
      else if (data[value].type_insurances)
        setitem(data[value].type_insurances);
      else if (data[value].medicine) setitem(data[value].medicine);
      else if (data[value].diagnostic) setitem(data[value].diagnostic);
    } else {
      setitem([]);
    }
  };

  const onSubmit = (dataOnSubmit) => {
    if (!loading) setloading(true);
    setresult(null);
    if (success) setsuccess(false);

    let body;

    let resultMedical = details.find((value) => {
      return value.type === itemSelect;
    });

    if (resultMedical) {
      let item = [];
      dataOnSubmit.additional.forEach((value) => {
        if (resultMedical.details.length > 1) {
          delete value.name;
          delete value.count;

          let valuesFinit = Object.values(value);

          valuesFinit[0].forEach((detailsValue) => {
            detailsValue.user_id.forEach((user_id) => {
              if (!item.includes(user_id)) item.push(user_id);
            });
          });
        } else {
          value.user_id.forEach((user_id) => {
            if (!item.includes(user_id)) item.push(user_id);
          });
        }
      });

      body = {
        title: dataOnSubmit.title,
        message: dataOnSubmit.message,
        list_ids: item,
      };
    } else {
      let value = dataOnSubmit.list_type;

      if (value > -1) {
        body = {
          title: dataOnSubmit.title,
          message: dataOnSubmit.message,
          list_ids: data[value].user_id,
        };
      }
    }

    notificationGetListByID(body)
      .then((values) => {
        if (values.data && values.data.length > 0) {
          let result = values.data;
          result = result.map(function (item) {
            return [item];
          });

          setresult(result);
        }
        setsuccess(true);
        setloading(false);
      })
      .catch((e) => {});
  };

  return (
    <>
      {loading ? (
        <GridLoader size={12} css={override} color={"#19769F"} loading={true} />
      ) : !success ? (
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Container>
            <Row>
              <Col>
                <div className="select2-input py-2">
                  <div className="form-group">
                    <label className="placeholder">
                      <b>Tipo</b>
                    </label>
                    <div className="position-relative">
                      <Field
                        name={"list_type"}
                        component={"select"}
                        onClick={(e) => type_list_function(e)}
                        className={"form-control"}
                      >
                        <option value={""} key={-1}>
                          Seleccione
                        </option>
                        {data &&
                          data.map((item, i) => {
                            return (
                              <option value={i} key={i}>
                                {item.name}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            {show && (
              <>
                <Row>
                  <Col>
                    <div className="select2-input py-2">
                      <div className="form-group">
                        <label className="placeholder">
                          <b>Seleccionar historia</b>
                        </label>
                        <label className="placeholder">
                          <input
                            name="isGoing"
                            type="checkbox"
                            checked={isGoing}
                            onChange={() => {
                              setisGoing(!isGoing);

                              let valueItem = item;
                              if (isGoing) {
                                valueItem = [];
                              }
                              change("additional", valueItem);
                            }}
                          />
                        </label>
                        <div className="position-relative">
                          <Field
                            name="additional"
                            className="form-control"
                            component={renderMultiselect}
                            valueField="name"
                            placeholder="Seleccione el días"
                            textField={(item) => {
                              return item.name;
                            }}
                            data={item}
                            messages={{
                              emptyFilter:
                                "La búsqueda no arrojó ningun resultado",
                              emptyList: "No hay más elementos en esta lista",
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
            <Row className="my-2">
              <Col>
                <label>Título</label>
                <div className="select2-input py-2">
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    placeholder="Título"
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="select2-input py-2">
                  <div className="form-group">
                    <label className="placeholder">
                      <b>Mensaje</b>
                    </label>
                    <div className="position-relative">
                      <Field
                        name="message"
                        component="textarea"
                        type="text"
                        placeholder="Mensaje"
                        className="form-control"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      ) : result && result.length > 0 ? (
        <Notificationmessage />
      ) : (
        <p>No hay resultados para esta busqueda</p>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ notificationGetListByID }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "notificationForm",
  })
)(detailsFormDoctor);
