import React, { useEffect } from "react";
import { reduxForm, Form } from "redux-form";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { transactionApproveOrRejectUser } from "../../../actions/transactions_user_actions";
function suspendUser(props) {
  const {
    handleSubmit,
    transactionApproveOrRejectUser,
    data,
    change,
    type,
  } = props;

  useEffect(() => {
    if (data._id) {
      change("_id", data._id);
      change("type", type.type);
    }
  }, [data]);

  const onSubmitSuspend = (dataOnSubmit) => {
    let value = dataOnSubmit._id;

    let body = {
      transaction_id: value,
    };

    transactionApproveOrRejectUser(body, dataOnSubmit.type);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmitSuspend)}>
      <div>
        ¿Estás seguro de que deseas {type.title.toLowerCase()} la transacion:{" "}
        <b>{data.title} </b>
        {data.lastname}?
      </div>
    </Form>
  );
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ transactionApproveOrRejectUser }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "notificationForm",
  })
)(suspendUser);
