import axios from "axios";
import { store } from "../store/store";

import { userConstants } from "../constansts/user.constants";

const api = "https://dev.novemapp.com:8082/";

// USER LOGIN AND REGISTER

export const requestUser = (data, url) =>
  axios.post(`${api}${url}`, data).then(res => {
    const result = res.data;
    return result;
  });

export const requestOfUserAdmin = (data, url, token) =>
  axios
    .post(`${api}${url}`, data, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    });

export const requestOfUserAdmin2 = (data, url) =>
  axios.post(url, data).then(res => {
    const result = res.data;
    return result;
  });

export const requestToken = (url, token) =>
  axios
    .post(`${api}${url}`, null, {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(res => {
      const result = res.data;
      return result;
    })
    .catch(e => {
      store.dispatch({
        type: userConstants.AUTH_ERROR
      });
      return e;
    });

// helper function
export const tokenConfig = getState => {
  // Get token

  const token = getState().user.token;

  // Headers
  const config = {
    headers: {
      "Content-Type": "application/json"
    }
  };

  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
  }

  return config;
};
