import { filterConstants } from "../constansts/filter.constants";

const initialState = {
  filterList: [],
  isFeching: false,
  success: false,
  filterAll: 0
};

export default function(state = initialState, action) {
  switch (action.type) {
    case filterConstants.FILTER_LIST:
      return {
        ...state,
        filterList: action.data,
        // filterAll: action.data.total,
        isFeching: false,
        success: true
      };
    case filterConstants.FILTER_TOTAL:
      return {
        ...state,
        filterAll: action.data
      };

    case filterConstants.FILTER_LOADING_LIST:
      return {
        ...state,
        isFeching: true,
        success: false
      };

    case filterConstants.FILTER_RESET:
      return initialState;
    default:
      return state;
  }
}
