import React, { useState } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Field, reduxForm } from "redux-form";
import { loginUser } from "../../../actions/user_actions";
import { renderField } from "../renderField";

const FormClinicUpdatePrincipal = ({ handleSubmit, dispatch }) => {
  const onFormSubmit = (values) => {
    let body = {
      code: values.code,
      password: values.password,
    };
    dispatch(loginUser(body));
  };
  const [hidden, sethidden] = useState(true);

  const toggleShow = () => {
    sethidden(!hidden);
  };

  return (
    <>
      <p>
        Te hemos enviado un código de validación por Email y SMS, ingresa el
        código a continuación y contraseña
      </p>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Field
          className="form-control"
          name="code"
          component={renderField}
          type="text"
          placeholder="Código"
        />

        <Field
          className="form-control"
          name="password"
          component={renderField}
          type={hidden ? "password" : "text"}
          placeholder="Contraseña"
        >
          <div className="show-password">
            <button
              style={{
                position: "absolute",
                top: -20,
                left: -30,
                right: 5,
                backgroundColor: "transparent",
                borderColor: "transparent",
                color: "#19769f",
              }}
              onClick={toggleShow}
              type="button"
            >
              <i className={hidden ? "far fa-eye" : "far fa-eye-slash"}></i>
            </button>
          </div>
        </Field>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 20,
          }}
        >
          <button
            className="btn btn-primary col-md-5 float-right mt-3 mt-sm-0 fw-bold"
            type="submit"
          >
            Continuar
          </button>
        </div>
      </form>
    </>
  );
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ loginUser }, dispatch);

export default compose(
  withRouter,
  connect(null, mapDispatchToProps),
  reduxForm({
    form: "FormSendValidationWithPassword",
  })
)(FormClinicUpdatePrincipal);
