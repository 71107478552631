import React from "react";

import {Link} from "react-router-dom";

import {connect} from "react-redux";
import UsersAdmin from "./UsersAdmin";
import GeneralStatistics from "./GeneralStatistics";
import Setting from "./setting";
import Transactions from "./Transactions";
const Maintainer = ({permissions}) => {
  const permissionsValidate = (Search) => {
    return permissions.findIndex((value) => {
      return value.module === Search;
    });
  };
  return (
    <div>
      {permissionsValidate("user") >= 0 && (
        <div>
          <ul className="nav nav-primary">
            <li className="nav-item submenu" id="div">
              <Link to="/user">
                <i className="fas fa-clipboard-list"></i>
                <p>Consulta General</p>
              </Link>
            </li>
          </ul>
        </div>
      )}
      {permissionsValidate("notification") >= 0 && (
        <div>
          <ul className="nav nav-primary">
            <li className="nav-item submenu">
              <Link to="/notification">
                <i className="far fa-paper-plane"></i>
                <p>Notificaciones</p>
              </Link>
            </li>
          </ul>
        </div>
      )}
      {permissionsValidate("medical") >= 0 && (
        <div>
          <ul className="nav nav-primary">
            <li className="nav-item submenu">
              <Link to="/medical">
                <i className="fas fa-user-md"></i>
                <p>Doctores</p>
              </Link>
            </li>
          </ul>
        </div>
      )}

      {permissionsValidate("notice") >= 0 && (
        <div>
          <ul className="nav nav-primary">
            <li className="nav-item submenu">
              <Link to="/notice">
                <i className="fas fa-user-md"></i>
                <p>Noticias</p>
              </Link>
            </li>
          </ul>
        </div>
      )}
      {permissionsValidate("analityc") >= 0 && <GeneralStatistics />}

      <UsersAdmin />
      {permissionsValidate("navegation") >= 0 && <Setting />}

      {permissionsValidate("analityc") >= 0 && <Transactions />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  permissions: state.user.user.user.permissions,
});

export default connect(mapStateToProps)(Maintainer);
