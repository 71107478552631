import { verificateRejectMedical } from "../../actions/medical_actions";
import { store } from "../../store/store";

export function onSubmitSuspend(data) {
  let body = {
    medical_id: data._id
  };

  store.dispatch(verificateRejectMedical(body, data.type));
}
