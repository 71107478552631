import { store } from "../../store/store";
import { activeSuspendNotice } from "../../actions/notice_actions";

export function onSubmitSuspend(data) {
  let body = {
    notice_id: data._id
  };

  store.dispatch(activeSuspendNotice(body, data.type));
}
