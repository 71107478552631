import React, { useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import { stadisticsHealthInit } from "../../../actions/stadistic_actions";
import { bindActionCreators } from "redux";
import moment from "moment";
import "moment/locale/es";

import { connect } from "react-redux";

moment.locale("es");

export const renderFieldSelect = ({
  input,
  name,
  className,
  options,
  meta: { touched, error }
}) => {
  return (
    <div>
      <div>
        <select className={className} {...input}>
          {options.map((item, i) => (
            <option value={item.value} key={i}>
              {item.name}
            </option>
          ))}
        </select>

        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let detailsFormDoctor = props => {
  const {
    handleSubmit,
    listGender,
    change,
    listCountry, 
    favoriteColorValue,
    stadisticsHealthInit
  } = props;

  useEffect(() => {
    change("country", null);

    change("age_start", 0);

    change("age_end", 0);
    change("gender", null);
  }, []);

  const age = [];
  for (let index = 0; index < 130; index++) {
    age.push(
      <option value={index} key={"age" + index + 1}>
        {index}
      </option>
    );
  }

  const ageFunctions = () => {
    let age_end = [];

    for (let index = favoriteColorValue; index < 130; index++) {
      age_end.push(
        <option value={index} key={"ageE" + index + 1}>
          {index}
        </option>
      );
    }

    return age_end;
  };
  const onSubmit = data => {
    if (data.country === "Todos") data.country = null;

    if (data.gender === "Todos") data.gender = null;

    let body = {
      filter: {
        age_start: parseInt(data.age_start),
        age_end: parseInt(data.age_end),
        country: data.country,
        insurance: null,
        gender: data.gender,
        ocupation: null
      }
    };

    stadisticsHealthInit(body);
  };
  return (
    <div
      className="row justify-content-center"
      style={{ paddingTop: 15, paddingBottom: 15 }}
    >
      <div className="col-md-7">
        <div className="card">
          <div className="card-body p-3">
            <div className="row">
              <Container>
                <form onSubmit={handleSubmit}>
                  <Row>
                    <Col>
                      <label>Pais</label>
                      <div className="select2-input py-2">
                        <Field
                          name={"country"}
                          component={"select"}
                          className={"form-control"}
                        >
                          {listCountry &&
                            listCountry.map((item, i) => {
                              let alph = /^[a-zA-Z ñÑ]+$/;

                              if (alph.test(item) || item.length === 0) {
                                let value = item;
                                let title = item;
                                if (item.length === 0) {
                                  value = null;
                                  title = "Todos";
                                }
                                return (
                                  <option value={value} key={i}>
                                    {title}
                                  </option>
                                );
                              } else return null;
                            })}
                        </Field>
                      </div>
                    </Col>
                    <Col>
                      <label>Edad</label>
                      <Row>
                        <Col>
                          <div className="select2-input py-2">
                            <Field
                              name={"age_start"}
                              component={"select"}
                              className={"form-control"}
                            >
                              <option value={0} key={0}>
                                Sin mín.
                              </option>
                              {age}
                            </Field>
                          </div>
                        </Col>
                        <Col>
                          <div className="select2-input py-2">
                            <Field
                              name={"age_end"}
                              component={"select"}
                              className={"form-control"}
                            >
                              <option value={0} key={0}>
                                Sin máx.
                              </option>

                              {ageFunctions()}
                            </Field>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <label>Sexo</label>
                      <div className="select2-input py-2">
                        <Field
                          name={"gender"}
                          component={"select"}
                          className={"form-control"}
                        >
                          <option value={null} key={-1}>
                            Todos
                          </option>
                          {listGender &&
                            listGender.map((item, i) => {
                              let alph = /^[a-zA-Z ñÑ]+$/;

                              if (alph.test(item) || item.length === 0) {
                                let value = item;
                                let title = item;
                                if (item.length === 0) {
                                  value = null;
                                  title = "Todos";
                                }
                                return (
                                  <option value={value} key={i}>
                                    {title}
                                  </option>
                                );
                              } else return null;
                            })}
                        </Field>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <button
                      className="btn btn-sm ml-auto mr-3"
                      style={{ backgroundColor: "#19769F", color: "#ffffff" }}
                      onClick={handleSubmit(onSubmit)}
                    >
                      Filtrar
                    </button>
                  </Row>
                </form>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    listCountry: state.user.listCountry,
    listInsurance: state.user.listInsurance,

    listGender: state.user.user.gender_list,
    navegationData: state.navegation.data
  };
};

detailsFormDoctor = reduxForm({
  form: "statisticsFilterForm"
})(detailsFormDoctor);

const selector = formValueSelector("statisticsFilterForm");
detailsFormDoctor = connect(state => {
  const favoriteColorValue = selector(state, "age_start");
  const typeNotification = selector(state, "typeNotification");
  return {
    favoriteColorValue,
    typeNotification
  };
})(detailsFormDoctor);

const mapDispatchToProps = dispatch =>
  bindActionCreators({ stadisticsHealthInit }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(detailsFormDoctor);
