import {transactionUserConstants} from "../constansts/transaction.user.constants";

const initialState = {
  data: [],
  success: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case transactionUserConstants.TRANSTATION_LIST_USER:
      return {
        ...state,
        data: action.data,
        success: true,
      };

    default:
      return state;
  }
}
