import React, {Component} from "react";
import {Router, Switch, Route, Redirect} from "react-router-dom";

import history from "../store/browserHistory";
import Main from "./Main";
import GeneralInquiry from "./GeneralInquiry/GeneralInquiry";
import Notifications from "./Notifications/Notifications";
import Doctor from "./Doctor/Doctor";
import UsersAdmin from "./UsersAdmin/UsersAdmin";
import Login from "../pages/user/Login";
import GeneralStatistics from "./GeneralStatistics/Statistics";
import GeneralStatisticsMedic from "./GeneralStatistics/medic";
import NavigateComponent from "./Settings/navigate";
import PrivateRoute from "./common/PrivateRoute";
import News from "./News";
import TransactionDoctor from "./Transaction/doctor";
import TransactionUser from "./Transaction/user";
class AppView extends Component {
  render() {
    return (
      <Router history={history}>
        <Switch>
          <PrivateRoute exact path="/" component={Main} pathValidate="/Main" />
          <Route exact path="/login" render={(props) => <Login {...props} />} />

          <PrivateRoute
            exact
            path="/Main"
            component={Main}
            pathValidate="/Main"
          />
          <PrivateRoute
            exact
            path="/user"
            component={GeneralInquiry}
            pathValidate="/user"
          />
          <PrivateRoute
            exact
            path="/notification"
            pathValidate="/notification"
            component={Notifications}
          />
          <PrivateRoute
            exact
            path="/medical"
            component={Doctor}
            pathValidate="/medical"
          />
          <PrivateRoute
            exact
            path="/notice"
            component={News}
            pathValidate="/notice"
          />

          <PrivateRoute
            exact
            path="/UsersAdmin"
            component={UsersAdmin}
            pathValidate="/UsersAdmin"
          />

          <PrivateRoute
            exact
            path="/analityc"
            pathValidate="/analityc"
            component={GeneralStatistics}
          />

          <PrivateRoute
            exact
            path="/analitycmedic"
            pathValidate="/analityc"
            component={GeneralStatisticsMedic}
          />
          <PrivateRoute
            exact
            path="/navegation"
            pathValidate="/navegation"
            component={NavigateComponent}
          />

          <PrivateRoute
            path="/transactiondoctor"
            component={TransactionDoctor}
            pathValidate="/user"
          />

          <PrivateRoute
            exact
            path="/transactionuser"
            component={TransactionUser}
            pathValidate="/user"
          />

          <Redirect from="*" to="/" />
        </Switch>
      </Router>
    );
  }
}

export default AppView;
