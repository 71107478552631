import React, { useEffect, useState, useRef, useCallback } from "react";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { stadisticsHealthInit } from "../../../actions/stadistic_actions";
import ExportStatistcs from "./exportStatistcs";
import NotificationStatistcs from "./notificationStatistcs";
import { exportGetListByID } from "../../../actions/stadistic_actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ModalGlobal from "../../Modals";
import TableStactis from "./tableStactis";
const override = css`
  display: inline-block;
  margin: 0 auto;
`;

export const useContainerDimensions = (myRef) => {
  const getDimensions = () => {
    return {
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    };
  };

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};

const details = [
  { type: "families", details: ["history"] },
  { type: "habit", details: ["frecuencies"] },
  { type: "insurance", details: ["type_insurances"] },
  {
    type: "medicine_diagnostic_type",
    details: ["diagnostic", "type_medicament"],
  },
  { type: "type_medicine_diagnostic", details: ["medicine", "diagnostic"] },
  {
    type: "diagnostic_medicine_type",
    details: ["medicine", "type_medicament"],
  },
];
const MedicamentTable = ({
  stadisticsHealthInit,
  exportGetListByID,
  health,
}) => {
  const [dataUse, setdataUse] = useState({});
  const [dataUseInit, setdataUseInit] = useState(false);
  let loading = true;
  const componentRef = useRef();
  const { width, height } = useContainerDimensions(componentRef);
  const [heightWithUser, setheightWithUser] = useState(
    useContainerDimensions(componentRef)
  );

  const [selectItem, setselectItem] = useState();
  useEffect(() => {
    if (Object.entries(health).length !== Object.entries(dataUse).length) {
      setdataUse(health);
    }

    if (Object.entries(health).length === 0 && !dataUseInit) {
      let body = {
        filter: {
          age_start: 0,
          age_end: 0,
          country: null,
          insurance: null,
          gender: null,
          ocupation: null,
        },
      };
      setdataUseInit(!dataUseInit);
      stadisticsHealthInit(body);
    }

    if (width !== heightWithUser.width && width !== 0) {
      setheightWithUser({
        width,
      });
    }
  }, [health, width]);

  const memoizedHandleClick = useCallback((i) => {
    setselectItem(i);
  }, []);

  const renderObject = () => {
    let table = [];

    for (let i in dataUse) {
      if (dataUse.hasOwnProperty(i)) {
        let value = dataUse[i].slice(0, 10);
        if (value.total) value = [{ name: i, count: value.total }];

        let resultMedical = details.find((value) => {
          return value.type === i;
        });

        let detailsValue = null;
        if (resultMedical) {
          detailsValue = resultMedical.details;
        }

        table.push(
          <div className="col" style={{ zIndex: 1000 }}>
            <TableStactis
              selectItem={(i) => memoizedHandleClick(i)}
              value={value}
              title={i}
              index={1}
              key={1}
              details={detailsValue}
              width={width / 2.2}
              height={width / 3.5}
            />
          </div>
        );
      }
    }
    return table;
  };

  const bodyModal = () => {
    if (selectItem && selectItem.type)
      switch (selectItem.type) {
        case "export":
          return (
            <ExportStatistcs
              data={dataUse[selectItem.item]}
              itemSelect={selectItem.item}
            />
          );

        case "notification":
          return (
            <NotificationStatistcs
              data={dataUse[selectItem.item]}
              itemSelect={selectItem.item}
            />
          );
        default:
          break;
      }
  };
  return (
    <div className="col">
      {Object.entries(dataUse).length > 0 && (
        <div
          style={{
            display: "grid",
            zIndex: 2,
            gridTemplateColumns:
              (width / 2).toString() + "px " + (width / 2).toString() + "px",
          }}
        >
          {renderObject()}
        </div>
      )}
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "auto ",
          marginTop:
            Object.entries(dataUse).length > 0
              ? -height.toString() + "px"
              : "0px",
          zIndex: 1,
        }}
        ref={componentRef}
      >
        <div className="GridLoader">
          <GridLoader
            size={20}
            css={override}
            color={Object.entries(dataUse).length > 0 ? "#f9fbfd" : "#19769F"}
            loading={loading}
          />
        </div>
      </div>

      <ModalGlobal> {bodyModal()}</ModalGlobal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  health: state.stadistics.health,
  loading: state.stadistics.healthLoading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ stadisticsHealthInit, exportGetListByID }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MedicamentTable);
