import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalGlobal from "../Modals";
import { openModal } from "../../actions/modal_actions";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { NavegationAll } from "../../actions/navegation_action";
import Form from "./form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DeleteNavigate from "./deleteNavigate";
import moment from "moment";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { permissionsValidate } from "../../functions";

const override = css`
  display: inline-block;
  margin: 0 auto;
`;

const ButtonIcon = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      data-toggle="tooltip"
      title={props.title}
      className="btn btn-link btn-primary btn-lg"
    >
      <FontAwesomeIcon icon={props.name} size="lg" color={props.color} />
    </button>
  );
};

const NewsTable = ({
  NavegationAll,
  permissionsArray,
  success,
  data,
  openModal,
}) => {
  const [dataNotice, setdataNotice] = useState([]);
  const [item, setItem] = useState(null);
  const [typeModal, settypeModal] = useState(null);
  const [permissions, setpermissions] = useState([]);

  let result = -1;
  const buttonData = [
    {
      title: "Eliminar",
      type: "delete",
      status: "delete",
      name: faTrash,
      color: "#be2020",
      itemClassName: "modal-dialog",
    },
  ];

  useEffect(() => {
    if (permissions.length === 0) {
      let searchModule = permissionsValidate(permissionsArray, "navegation");
      if (searchModule >= 0)
        setpermissions(permissionsArray[searchModule].action);
    }

    if (!success) NavegationAll();
    else if (success || data.length !== dataNotice.length) {
      setdataNotice(data);
    }
  }, [data, success]);

  const bodyModal = () => {
    if (typeModal && typeModal.type)
      switch (typeModal.type) {
        case "newNotice":
          return <Form />;

        case "detailsImg":
          let url = item.picture;

          url = url.replace("http", "https");

          return <img src={url} className="img-fluid" alt="test" />;
        case "delete":
          return <DeleteNavigate data={item} type={typeModal} />;
        default:
          break;
      }
  };
  if (dataNotice.length > 0) {
    result = dataNotice.findIndex((value) => {
      return value.deleted_at === false;
    });
  }

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <h4 className="card-title">Navegación</h4>
            {permissions.includes("save") && (
              <div className=" ml-auto">
                <button
                  className="btn btn-primary btn-round ml-auto"
                  onClick={() => {
                    settypeModal({ type: "newNotice" });
                    openModal({
                      title: "Crear navegación",
                      itemClassName: "modal-lg",
                    });
                  }}
                >
                  <i className="fa fa-plus"></i>
                  Agregar
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          {!success ? (
            <div className="GridLoader">
              <GridLoader
                size={20}
                css={override}
                color={"#19769F"}
                loading={true}
              />
            </div>
          ) : (
            <div className="table-responsive">
              <table id="add-row" className="display table table-striped  ">
                <thead>
                  <tr>
                    <th className="text-center">Titulo</th>
                    <th className="text-center">Direccion</th>
                    <th className="text-center">Imagen</th>
                    <th className="text-center">Fecha de creacion</th>
                    {(permissions.includes("reject") ||
                      permissions.includes("verificate") ||
                      permissions.includes("delete")) && (
                      <th className="text-center">Acciones</th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {dataNotice.length > 0 && result > -1 ? (
                    dataNotice.map((data, index) => {
                      if (!data.deleted_at) {
                        return (
                          <tr key={index}>
                            <td className="text-center">{data.section}</td>
                            <td className="text-center">{data.link}</td>
                            <td className="text-center">
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                                onClick={() => {
                                  setItem(data);
                                  settypeModal({ type: "detailsImg" });
                                  openModal({
                                    title: "Detalle de imagen",
                                    itemClassName: "modal-dialog",
                                    send: false,
                                  });
                                }}
                              >
                                Ver
                              </button>
                            </td>
                            <td className="text-center">
                              {moment(data.created_at).format("DD-MM-YYYY")}
                            </td>

                            <td className="text-center">
                              {buttonData.map((value, index) => {
                                if (data.status !== value.status)
                                  return (
                                    <ButtonIcon
                                      key={index}
                                      name={value.name}
                                      title={value.title}
                                      color={value.color}
                                      onClick={() => {
                                        settypeModal({
                                          type: value.type,
                                          title: value.title,
                                        });
                                        setItem(data);
                                        openModal({
                                          title: value.title,
                                          itemClassName: "modal-dialog",
                                        });
                                      }}
                                    />
                                  );
                                else return null;
                              })}
                            </td>
                          </tr>
                        );
                      } else return null;
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay ruta de navegación actualmente
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <ModalGlobal>{bodyModal()}</ModalGlobal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.navegation.data,
  success: state.navegation.success,
  permissionsArray: state.user.user.user.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openModal, NavegationAll }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewsTable);
