import { notificationsConstants } from "../constansts/notifications.constants";

import {
  loading,
  getSuccess,
  getFailure,
  reset,
  updateModal
} from "../actions/modal_actions";

import * as api from "../api/api_admin";

export const getMedicalList = data => {
  return { type: notificationsConstants.INDIVIDUAL_NOTIFICATION, data };
};

export const getGroupNotification = data => {
  return { type: notificationsConstants.GROUP_NOTIFICATION, data };
};

export const getGroupNotificationSuccess = data => {
  return { type: notificationsConstants.GROUP_NOTIFICATION_SUCCESS, data };
};

export const getGroupNotificationFailure = data => {
  return { type: notificationsConstants.GROUP_NOTIFICATION_FAILURE, data };
};

export const getBodyListNotification = data => {
  return { type: notificationsConstants.GROUP_NOTIFICATION_BODY, data };
};
export const resetNotifications = () => {
  return { type: notificationsConstants.GROUP_NOTIFICATION_RESET };
};

export const deleteTokens = data => {
  let url = "user/token-device-delete";

  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);
    request.then(result => {
      try {
        if (result.status === 200) {
        } else {
        }
      } catch (e) {}
    });
  };
};

export const individualSms = body => {
  let url = "notification/individual";

  return (dispatch, getState) => {
    let usersList = getState().notification.usersList;

    if (usersList.length === 0) dispatch(loading());
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(body, url, token);
    request.then(result => {
      /* let value = [...usersList];
     value.shift();*/
      try {
        let value = [...getState().notification.usersList];
        result.data.user = value[0];
        value.shift();
        if (result.status === 200) {
          if (getState().notification.usersList.length === 0)
            dispatch(getSuccess());
          else {
            dispatch(getGroupNotification(value));

            dispatch(getGroupNotificationSuccess(result.data));
          }
        } else {
          if (getState().notification.usersList.length === 0)
            dispatch(getFailure(result.msg));
          else {
            dispatch(getGroupNotificationFailure(result.data));
            dispatch(getGroupNotification(value));
          }
        }
      } catch (e) {
        if (usersList.length === 0) dispatch(getFailure(e));

        //dispatch(getGroupNotification(value));
        if (usersList.length > 0) result.user = usersList[0];

        dispatch(getGroupNotificationFailure(result));
      }
    });
  };
};

export const individualNotification = ({
  title,
  tokens,
  message,
  navigate,
  user
}) => {
  let url = "https://novem-notifications.herokuapp.com/message";

  const notification = {
    somePushTokens: tokens,
    title: title,
    body: message,
    data: { navigate: navigate },
    user: user
  };

  return (dispatch, getState) => {
    let usersList = getState().notification.usersList;

    if (usersList.length === 0) dispatch(loading());

    const request = api.requestOfUserAdmin2(notification, url);
    request.then(result => {
      try {
        let value = [...getState().notification.usersList];
        result.data.user = value[0];
        value.shift();

        let tokenError = [];

        if (result.status === 200) {
          result.tokens.map((item, i) => {
            if (item.status === "error") {
              tokenError.push(result.send[i]);
            }
            return item;
          });
          if (getState().notification.usersList.length === 0) {
            dispatch(getSuccess());

            if (tokenError.length > 0) {
              dispatch(
                deleteTokens({
                  list_token: tokenError
                })
              );
            }
          } else {
            dispatch(getGroupNotification(value));
            dispatch(getGroupNotificationSuccess(result.data));
          }
        } else if (result.status === 300) {
          result.tokens.map(item => {
            let data = {
              title: result.notification.title,
              message: result.notification.body,
              tokens: item.error,
              navigate: result.notification.data.navigate,
              user: result.data.user
            };
            dispatch(individualNotification(data));

            return item;
          });
        } else {
          if (getState().notification.usersList.length === 0)
            dispatch(getFailure(result.msg));
          else {
            dispatch(getGroupNotificationFailure(result.data));
            dispatch(getGroupNotification(value));
          }
        }
      } catch (e) {
        console.log(e);

        if (usersList.length === 0) dispatch(getFailure(e));

        //dispatch(getGroupNotification(value));
        if (usersList.length > 0) result.user = usersList[0];

        dispatch(getGroupNotificationFailure(result));
      }
    });
  };
};

export const groupNotification = body => {
  let url = "notification/group";
  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(body, url, token);
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getSuccess());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {
        dispatch(getFailure(e));
        console.log("error groupNotification", e);
      }
    });
  };
};

export const getListNotification = body => {
  let url = "notification/get-list";
  return (dispatch, getState) => {
    let usersList = getState().notification.usersList;
    let bodyRedycer = getState().notification.body;

    if (usersList.length === 0) dispatch(loading());
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(bodyRedycer, url, token);
    request.then(result => {
      try {
        dispatch(updateModal({ send: false, reset: true }));
        if (result.status === 200) {
          if (result.data.length > 0) {
            bodyRedycer.filter.page = bodyRedycer.filter.page + 1;
            dispatch(getBodyListNotification(bodyRedycer));

            let data = getState().notification.usersList.concat(result.data);

            dispatch(getGroupNotification(data));
          }

          dispatch(reset());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {
        dispatch(getFailure(e));
        console.log("error groupNotification", e);
      }
    });
  };
};
