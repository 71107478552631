import React from "react";

export const renderField = ({
  input,
  name,
  className,
  type,
  placeholder,
  max,
  min,
  children,
  meta: { touched, error }
}) => {
  return (
    <div className="form-group">
      <label className="placeholder">
        <b>{placeholder}</b>
      </label>
      <div className="position-relative">
        <input
          className={className}
          {...input}
          max={max}
          min={min}
          type={type}
          placeholder={placeholder}
        />
        {children}
      </div>

      {touched &&
        (error && (
          <span
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 10,
              color: "#b83205"
            }}
          >
            {error}
          </span>
        ))}
    </div>
  );
};
