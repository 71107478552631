export const userConstants = {
  USER_ADMIN: "USER_ADMIN",
  USER_LOADING: "USER_LOADING",
  USER_LOADED: "USER_LOADED",
  LIST_USERS: "LIST_USERS",
  LIST_USERS_UPDATE: "LIST_USERS_UPDATE",
  LIST_COUNTRY: "LIST_COUNTRY",
  USER_ADMIN_STATUS: "USER_ADMIN_STATUS",
  USER_LOADING_STATUS: "USER_LOADING_STATUS",
  LIST_INSURANCE: "LIST_INSURANCE",
  AUTH_ERROR: "AUTH_ERROR",
  USER_VALIDATION_LOADING: "USER_VALIDATION_LOADING",
  USER_VALIDATION_SUCCESS: "USER_VALIDATION_SUCCESS",
  USER_VALIDATION_ERROR: "USER_VALIDATION_ERROR",
  DESTROY_SESSION: "DESTROY_SESSION"
};
