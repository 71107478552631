import React from "react";
import Image from "react-bootstrap/Image";

import { Col, Container, Row } from "react-bootstrap";
import logo from "../../assets/img/iconsGradient.png";

const detailsUser = ({ data }) => {
  let phone = "Sin definir";
  let dni = "Sin definir";
  if (data.phones && data.phones[0])
    phone = data.phones[0].country_code + "(" + data.phones[0].number + ")";

  if (data.dni.length > 0) dni = data.dni;

  let country = "Sin definir";
  if (data.country !== "{}") country = data.country;

  let email = "Sin definir";
  if (data.email !== null) email = data.email;

  let pictureUrl = logo;
  if (data.pictureUrl && data.pictureUrl.length > 0)
    pictureUrl = data.pictureUrl;

  return (
    <Container>
      <Row>
        <Col xs={5}>
          <Image
            src={pictureUrl}
            thumbnail
            style={{ height: "150px", width: "150px" }}
          />
        </Col>

        <Col xs={7} className="center-block" style={{ margin: "auto" }}>
          <Row>
            Nombre: {data.name} {data.lastname}
          </Row>
          <Row> Dni: {dni}</Row>
          <Row> Pais: {country}</Row>
          <Row> Correo: {email}</Row>
          <Row>Telefono: {phone}</Row>
        </Col>
      </Row>
    </Container>
  );
};

export default detailsUser;
