import React, { useEffect } from "react";
import { Field, reduxForm } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import { renderFieldSelect } from "./notificationAll";
import { onSubmit } from "./onSubmit";
moment.locale("es");

export const navigation = [
  { id: 1, name: "Ninguna", value: null },
  { id: 2, name: "Expediente", value: "expediente" },
  { id: 3, name: "Medicamentos", value: "medicamentos" },
  { id: 4, name: "Póliza de seguro", value: "InsurancePolicy" },
  { id: 5, name: "Covid", value: "DashboardCovid" },
  { id: 6, name: "Últimas Noticias", value: "NewsCovid" }
];

let detailsFormDoctor = props => {
  const { handleSubmit, data, change, type } = props;
  useEffect(() => {
    if (data._id) {
      change("_id", data._id);
      change("type", type);
      change("tokens", data.access_devices);
      change("user", data);
    }
  }, [data]);

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        {type === "notification" && (
          <Row className="my-2">
            <Col>
              <label>Título</label>
              <Row>
                <Col>
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    placeholder="Título"
                    className="form-control"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <br />
        <Row className="my-2">
          <Col>
            <label>Mensaje</label>
            <Row>
              <Col>
                <Field
                  name="message"
                  component="textarea"
                  type="text"
                  placeholder="Mensaje"
                  className="form-control"
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        {type === "notification" && (
          <Row className="my-2">
            <Col>
              <label>Navegación</label>
              <Row>
                <Col>
                  <Field
                    name="navigate"
                    component={renderFieldSelect}
                    className="form-control"
                    options={navigation}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      </Container>
    </form>
  );
};

detailsFormDoctor = reduxForm({
  form: "notificationForm",
  onSubmit: onSubmit
})(detailsFormDoctor);

export default detailsFormDoctor;
