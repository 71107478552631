import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import logger from "redux-logger";
import promise from "redux-promise";
import thunk from "redux-thunk";

import rootReducer from "../reducers";
const middleware = [thunk, promise];
middleware.push(logger);
export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);
