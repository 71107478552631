import React, { Component } from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { compose } from "redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ModalGlobal from "../Modals";
import {
  faAddressBook,
  faUserTimes,
  faAddressCard,
  faEye,
  faUserAltSlash,
  faUserAlt,
  faBell,
  faEnvelope,
  faRedoAlt,
} from "@fortawesome/free-solid-svg-icons";
import NotificationUser from "./notificationUser";
import NotificationAll from "./notificationAll";
import FilterFormModal from "./filterAll";
import SuspendActiveUser from "./suspendActiveUser";
import DeleteUser from "./deleteUser";
import ContactUser from "./contactListUser";
import InsuranceUser from "./insuranceUser";
import DetailsUser from "./detailsUser";
import ExportUser from "./exportUser";
import { bindActionCreators } from "redux";
import Pagination from "./pagination";
import { openModal } from "../../actions/modal_actions";
import { filterReset } from "../../actions/filter_actions";
import { getPagSelectReset } from "../../actions/paginator_actions";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import moment from "moment";
import "moment/locale/es";
import { permissionsValidate } from "../../functions";
moment.locale("es");

const override = css`
  display: inline-block;
  margin: 0 auto;
`;

const ButtonIcon = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      data-toggle="tooltip"
      title={props.title}
      className="btn btn-link btn-primary btn-lg"
    >
      <FontAwesomeIcon
        icon={props.name}
        size="lg"
        color={props.delete ? "#be2020" : "#1b779d"}
      />
    </button>
  );
};

class GeneralTable extends Component {
  state = {
    count: 0,
    item: null,
    delete: false,
    limitPag: 10,
    pag: 0,
    isFechingFilter: false,
    isFechingUser: false,
    listUsers: [],
    success: false,
    status: false,
    permissions: [],
  };

  componentDidMount() {
    let searchModule = permissionsValidate(this.props.permissions, "user");
    if (searchModule >= 0)
      this.setState({
        permissions: this.props.permissions[searchModule].action,
      });

    this.props.getPagSelectReset();
    this.props.filterReset();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isFechingFilter !== prevState.isFechingUser) {
      let obj = { isFechingUser: nextProps.isFechingFilter };

      if (nextProps.success) {
        obj.listUsers = nextProps.filterList;
      } else {
        obj.listUsers = nextProps.listUsers;
      }

      return obj;
    } else if (
      nextProps.isFechingUser !== prevState.isFechingUser &&
      !nextProps.isFechingFilter
    ) {
      return { isFechingUser: nextProps.isFechingUser };
    } else if (
      nextProps.listUsers.length !== prevState.listUsers.length &&
      !nextProps.success
    ) {
      let obj = { listUsers: nextProps.listUsers };
      if (nextProps.isFechingUser !== prevState.isFechingUser)
        obj.isFechingUser = nextProps.isFechingUser;

      return obj;
    } else if (nextProps.status !== prevState.status) {
      let obj = { status: nextProps.status };
      if (nextProps.status) {
        if (!nextProps.success) {
          obj.listUsers = nextProps.listUsers;
        } else {
          obj.listUsers = nextProps.filterList;
        }
      }

      return obj;
    }
    return null;
  }

  bodyModal() {
    switch (this.state.type) {
      case "notification":
        return (
          <NotificationUser data={this.state.item} type={"notification"} />
        );
      case "detailsUser":
        return <DetailsUser data={this.state.item} />;
      case "notificationAll":
        return <NotificationAll />;
      case "filterAll":
        return <FilterFormModal type={"user"} />;

      case "notificationSms":
        return <NotificationUser data={this.state.item} type={"sms"} />;

      case "activeUser":
        return <SuspendActiveUser data={this.state.item} type={"active"} />;
      case "suspendUser":
        return <SuspendActiveUser data={this.state.item} type={"suspend"} />;

      case "contactUser":
        return <ContactUser data={this.state.item} />;

      case "exportUser":
        return <ExportUser data={this.state.item} type="user" />;

      case "insurancesUser":
        return <InsuranceUser data={this.state.item} />;

      case "passwordReset":
        return <SuspendActiveUser data={this.state.item} type={"reset"} />;

      case "deleteUser":
        return <DeleteUser data={this.state.item} />;

      default:
        break;
    }
  }

  increment() {
    this.setState({ count: this.state.count + this.state.limitPag });
  }

  decrement() {
    this.setState({ count: this.state.count - this.state.limitPag });
  }

  permissionsAction(Search) {
    //let searchModule = this.permissionsValidate("user");
    let action = -1;

    if (this.state.permissions.length > 0) {
      action = this.state.permissions.findIndex((value) => {
        return value === Search;
      });
    }

    return action;
  }

  render() {
    const { pag } = this.props;

    let pagInit = (pag - 1) * 10;
    let pagEnd = pag * 10;

    return (
      <div className="col-md-12">
        <div className="card">
          <div className="card-header">
            <div className="d-flex align-items-center">
              <h4 className="card-title">Consulta General</h4>
              <div className=" ml-auto">
                {this.state.permissions.includes("export") && (
                  <button
                    className="btn btn-primary btn-round ml-auto"
                    onClick={() => {
                      this.setState({
                        type: "exportUser",
                        itemClassName: "modal-lg",
                      });
                      this.props.openModal({
                        title: "Exportar usuarios",
                      });
                    }}
                  >
                    <i className="fa fa-file-export"></i>
                    Exportar
                  </button>
                )}

                {this.state.permissions.includes("list") && (
                  <button
                    className="btn btn-primary btn-round ml-auto"
                    onClick={() => {
                      this.setState({
                        type: "filterAll",
                        itemClassName: "modal-lg",
                      });
                      this.props.openModal({
                        title: "Filtrar",
                        itemClassName: "modal-lg",
                      });
                    }}
                  >
                    <i className="fa fa-plus"></i>
                    Filtrar
                  </button>
                )}
                {this.props.success && (
                  <button
                    className="btn btn-danger btn-round ml-auto"
                    onClick={() => {
                      this.props.getPagSelectReset();
                      this.props.filterReset();
                    }}
                  >
                    <i className="fa fa-times" />
                    Limpiar busqueda
                  </button>
                )}
              </div>
            </div>
          </div>
          <div
            className="card-body"
            style={{
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {this.state.isFechingUser ? (
              <div className="GridLoader">
                <GridLoader
                  size={20}
                  css={override}
                  color={"#19769F"}
                  loading={true}
                />
              </div>
            ) : this.state.permissions.includes("list") ? (
              <div className="table-responsive">
                <table
                  id="add-row"
                  className="display table table-striped table-hover"
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <thead>
                    <tr>
                      <th>Novem ID</th>
                      <th>Nombre y Apellido</th>
                      <th>Email</th>
                      <th>Edad</th>
                      <th>País</th>
                      <th>Status</th>

                      <th>Contactos</th>
                      <th>Seguros</th>
                      <th>Última conexión</th>
                      {(this.state.permissions.includes("view") ||
                        this.state.permissions.includes("status_update")) && (
                        <th style={{ textAlign: "center" }}>Acciones</th>
                      )}
                    </tr>
                  </thead>

                  <tbody>
                    {this.state.listUsers.length > 0 &&
                    this.state.listUsers.slice(pagInit, pagEnd).length > 0 ? (
                      this.state.listUsers
                        .slice(pagInit, pagEnd)
                        .map((item, i) => {
                          let status;

                          switch (item.status) {
                            case "unconfirmed":
                              status = "Por validar";
                              break;
                            case "suspended":
                              status = "Suspendido";

                              break;
                            case "active":
                              status = "Activado";

                              break;
                            default:
                              status = "Por validar";
                              break;
                          }

                          let age = "No registrado";
                          let country = "Sin definir";
                          if (item.country !== "{}") country = item.country;
                          if (item.age !== null) age = item.age + " años";

                          return (
                            <tr key={i}>
                              <td>{item.identificator}</td>
                              <td>
                                {item.name} {item.lastname}
                              </td>
                              <td>
                                {item.emails &&
                                  item.emails.length > 0 &&
                                  item.emails[0].email}
                              </td>
                              <td>{age}</td>
                              <td>{country}</td>
                              <td>{status}</td>

                              {item.Health.EmergencyContacts.length > 0 ? (
                                this.state.permissions.includes("view") ? (
                                  <td className="form-button-action">
                                    <ButtonIcon
                                      name={faAddressBook}
                                      title={"Ver contactos"}
                                      color={"#1b779d"}
                                      onClick={() => {
                                        this.setState({
                                          item,
                                          type: "contactUser",
                                        });
                                        this.props.openModal({
                                          title: "Lista de contactos",
                                          itemClassName: "modal-lg",
                                          send: false,
                                        });
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td>Si posee</td>
                                )
                              ) : (
                                <td>Sin contactos </td>
                              )}
                              {item &&
                              item.insurances &&
                              item.insurances.length > 0 ? (
                                this.state.permissions.includes("view") ? (
                                  <td>
                                    <ButtonIcon
                                      name={faAddressCard}
                                      title={"Ver seguros"}
                                      color={"#1b779d"}
                                      onClick={() => {
                                        this.setState({
                                          item,
                                          type: "insurancesUser",
                                        });
                                        this.props.openModal({
                                          title: "Lista de seguros",
                                          itemClassName: "modal-lg",
                                          send: false,
                                        });
                                      }}
                                    />
                                  </td>
                                ) : (
                                  <td>Si posee</td>
                                )
                              ) : (
                                <td>Sin poliza</td>
                              )}
                              <td>
                                {item.last_conection
                                  ? moment(item.last_conection).format(
                                      "DD-MM-YYYY, hh:mm A"
                                    )
                                  : "Sin definir"}
                              </td>
                              <td>
                                <div className="form-button-action">
                                  {this.state.permissions.includes("view") && (
                                    <ButtonIcon
                                      name={faEye}
                                      title={"Ver"}
                                      color={"#1b779d"}
                                      onClick={() => {
                                        this.setState({
                                          item,
                                          type: "detailsUser",
                                        });
                                        this.props.openModal({
                                          title: "Detalles de usuario",
                                          itemClassName: "modal-dialog",
                                          send: false,
                                        });
                                      }}
                                    />
                                  )}
                                  {this.state.permissions.includes(
                                    "notification"
                                  ) && (
                                    <>
                                      <ButtonIcon
                                        name={faBell}
                                        title={"Enviar Notificación"}
                                        color={"#1b779d"}
                                        onClick={() => {
                                          this.setState({
                                            item,
                                            type: "notification",
                                          });
                                          this.props.openModal({
                                            title: "Enviar notificacion",
                                            itemClassName: "modal-dialog",
                                          });
                                        }}
                                      />
                                      <ButtonIcon
                                        name={faEnvelope}
                                        title={"Enviar SMS"}
                                        color={"#1b779d"}
                                        onClick={() => {
                                          this.setState({
                                            item,
                                            type: "notificationSms",
                                          });
                                          this.props.openModal({
                                            title: "Enviar sms",
                                            itemClassName: "modal-dialog",
                                          });
                                        }}
                                      />
                                    </>
                                  )}
                                  {this.state.permissions.includes(
                                    "status_update"
                                  ) && (
                                    <>
                                      <ButtonIcon
                                        name={faRedoAlt}
                                        title={"Resetear contraseña"}
                                        color={"#1b779d"}
                                        onClick={() => {
                                          this.setState({
                                            item,
                                            type: "passwordReset",
                                          });
                                          this.props.openModal({
                                            title: "Resetear contraseña",
                                            itemClassName: "modal-dialog",
                                          });
                                        }}
                                      />

                                      {item.status !== "suspended" && (
                                        <ButtonIcon
                                          name={faUserAltSlash}
                                          title={"Suspender usuario"}
                                          delete={true}
                                          color={"#1b779d"}
                                          onClick={() => {
                                            this.setState({
                                              item,
                                              type: "suspendUser",
                                            });
                                            this.props.openModal({
                                              title: "Suspender usuario",
                                              itemClassName: "modal-dialog",
                                            });
                                          }}
                                        />
                                      )}

                                      {item.status !== "active" && (
                                        <ButtonIcon
                                          name={faUserAlt}
                                          title={"Activar usuario"}
                                          color={"#1b779d"}
                                          onClick={() => {
                                            this.setState({
                                              item,
                                              type: "activeUser",
                                            });
                                            this.props.openModal({
                                              title: "Activar usuario",
                                              itemClassName: "modal-dialog",
                                            });
                                          }}
                                        />
                                      )}

                                      <ButtonIcon
                                        name={faUserTimes}
                                        title={"Eliminar usuario"}
                                        color={"#1b779d"}
                                        delete={true}
                                        onClick={() => {
                                          this.setState({
                                            item,
                                            type: "deleteUser",
                                          });
                                          this.props.openModal({
                                            title: "Eliminar usuario",
                                            itemClassName: "modal-dialog",
                                          });
                                        }}
                                      />
                                    </>
                                  )}
                                </div>
                              </td>
                            </tr>
                          );
                        })
                    ) : this.props.success &&
                      this.props.filterList.length === 0 ? (
                      <tr>
                        <td colSpan={10}>
                          <h3 className="text-center">
                            No hay resultados en esta búsqueda
                          </h3>
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <div className="GridLoader">
                            <GridLoader
                              size={20}
                              css={override}
                              color={"#19769F"}
                              loading={true}
                            />
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="row">
                  <div className="col-sm-12 col-md-7">
                    <div
                      className="dataTables_paginate paging_simple_numbers"
                      id="add-row_paginate"
                    >
                      <Pagination
                        data={this.state.listUsers}
                        all={this.props.userAll}
                        type={"user"}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div>
                <h3 className="text-center">
                  No posee los permisos correspondiente para visualizar esta
                  información
                </h3>
              </div>
            )}
          </div>
        </div>
        <ModalGlobal dialogClassName={this.state.itemClassName}>
          {this.bodyModal()}
        </ModalGlobal>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    listUsers: state.user.listUsers,
    pag: state.pagination.pagSelect,
    isFechingUser: state.user.isFeching,
    isFechingFilter: state.filter.isFeching,
    success: state.filter.success,
    permissions: state.user.user.user.permissions,
    userAll: state.user.userAll,
    filterList: state.filter.filterList,
    status: state.user.status,
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ openModal, filterReset, getPagSelectReset }, dispatch);

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "LIST_USER" })
)(GeneralTable);
