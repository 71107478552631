import { stadisticConstants } from "../constansts/stadistic.constants";
import { deleteTokens } from "./notifications_actions";
import * as api from "../api/api_admin";
const { Expo } = require("expo-server-sdk");
export const getStadisticsUse = data => {
  return { type: stadisticConstants.STADISTIC_USE, data };
};

export const getStadisticsHealth = data => {
  return { type: stadisticConstants.STADISTIC_HEALTH, data };
};

export const loading = () => {
  return { type: stadisticConstants.LOADING };
};

export const loadingHealth = () => {
  return { type: stadisticConstants.STADISTIC_HEALTH_LOADING };
};
export const tokensStadistic = data => {
  return { type: stadisticConstants.STADISTIC_TOKEN, data };
};

export const tokensStadisticSuccess = () => {
  return { type: stadisticConstants.STADISTIC_TOKEN_SUCCESS };
};
export const tokensStadisticSend = data => {
  return { type: stadisticConstants.STADISTIC_TOKEN_SEND, data };
};

export const tokensStadisticError = data => {
  return { type: stadisticConstants.STADISTIC_TOKEN_ERROR, data };
};

export const stadisticsUseInit = data => {
  let url = "stadistic/use";

  return (dispatch, getState) => {
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(data, url, token);
    dispatch(loading());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getStadisticsUse(result.data[0].details));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const stadisticsHealthInit = data => {
  let url = "stadistic/health";

  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);
    dispatch(loadingHealth());
    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getStadisticsHealth(result.data));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const exportGetListByID = data => {
  let url = "export/get-list-by-id";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(data, url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });

    return request;
  };
};

export const notificationGetListByID = value => {
  let body = {
    list_ids: value.list_ids
  };
  let url = "notification/get-list-by-id";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(body, url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          let data = {
            title: value.title,
            message: value.message
          };
          let tokens = [];

          result.data.map(item => {
            if (item !== "") {
              if (Expo.isExpoPushToken(item)) {
                if (!tokens.includes(item)) tokens.push(item);
              } else
                console.error(
                  `Push token ${item} is not a valid Expo push token`
                );
            }
            return item;
          });

          data.tokens = tokens;
          dispatch(tokensStadistic(data.tokens));

          dispatch(individualNotificationStadistic(data));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });

    return request;
  };
};

export const individualNotificationStadistic = ({
  title,
  tokens,
  message,
  navigate,
  user
}) => {
  let url = "https://novem-notifications.herokuapp.com/message";

  const notification = {
    somePushTokens: tokens,
    title: title,
    body: message,
    data: { navigate: navigate },
    user: user
  };

  return (dispatch, getState) => {
    const request = api.requestOfUserAdmin2(notification, url);
    request.then(result => {
      //navegationList.splice(resultNavegation, 1);

      let tokenError = [];
      try {
        if (result.status === 200) {
          if (result.send.length > 0) {
            dispatch(tokensStadisticSend(result.send));
          }
          if (result.error.length > 0) {
            dispatch(tokensStadisticError(result.error));
          }

          if (result.send.length > 0 || result.error.length > 0) {
            let token = [...getState().stadistics.token];
            let tokenSend = [...getState().stadistics.tokenSend];
            let tokenError = [...getState().stadistics.tokenError];

            if (token.length === tokenSend.length + tokenError.length) {
              dispatch(tokensStadisticSuccess());
            } else {
            }
          }
          result.tokens.map((item, i) => {
            if (item.status === "error") {
              tokenError.push(result.send[i]);
            }
            return item;
          });

          if (tokenError.length > 0) {
            dispatch(
              deleteTokens({
                list_token: tokenError
              }).then(value => {
                console.log("tokenError.length value", value);
              })
            );
          }
        } else if (result.status === 300) {
          result.tokens.map(item => {
            let data = {
              title: result.notification.title,
              message: result.notification.body,
              tokens: item.error,
              navigate: result.notification.data.navigate,
              user: result.data.user
            };
            dispatch(individualNotificationStadistic(data));

            return item;
          });
        }
      } catch (e) {}
    });
  };
};
