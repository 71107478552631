export const validate = values => {
  const errors = {};

  if (!values.email) {
    errors.email = "Obligatorio";
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values && values.email)
  ) {
    errors.email = "Dirección de correo electrónico no válida";
  }

  if (!values.username) {
    errors.username = "Obligatorio";
  }

  if (!values.password) {
    errors.password = "Obligatorio";
  } else if (values.password.length < 6) {
    errors.password = "Debe tener 6 caracteres o mas";
  }

  if (!values.start_date) {
    errors.start_date = "Obligatorio";
  } else if (values.end_date && values.end_date < values.start_date) {
    errors.start_date = "Fechas incorrectas";
  }

  return errors;
};
