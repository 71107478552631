import React from "react";

export const AuthenticationComponent = props => {
  const { children } = props;
  return (
    <div className="login">
      <div className="wrapper wrapper-login wrapper-login-full p-0">
        <div className="login-aside w-50 d-flex flex-column align-items-center justify-content-center text-center">
          <div className="crossfade">
            <figure></figure>
            <figure></figure>
            <figure></figure>
            <figure></figure>
            <figure></figure>
          </div>
        </div>

        {children}
      </div>
    </div>
  );
};
