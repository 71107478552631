import { userConstants } from "../constansts/user.constants";

const initialState = {
  user: [],
  listUsers: [],
  listCountry: [],
  listInsurance: [],
  token: localStorage.getItem("token"),
  isLoading: true,
  isAuthenticated: null,
  userAll: 0,
  isFeching: true,
  status: false,
  validation: false,
  validationSuccess: false,
  validationError: false,
  validationMsg: null,
  userName: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case userConstants.USER_ADMIN:
      localStorage.setItem("token", action.data.token.access_token);
      return {
        ...state,
        user: action.data,
        token: action.data.token.access_token,
        isLoading: false,
        isAuthenticated: true
      };
    case userConstants.USER_LOADING:
      return {
        ...state,
        isLoading: true
      };
    case userConstants.USER_LOADING_STATUS:
      return {
        ...state,
        status: false
      };
    case userConstants.USER_VALIDATION_LOADING:
      return {
        ...state,
        validation: true,
        validationError: false,
        validationSuccess: false,
        userName: null
      };
    case userConstants.USER_VALIDATION_SUCCESS:
      return {
        ...state,
        userName: action.data,
        validation: false,
        validationSuccess: true
      };
    case userConstants.USER_VALIDATION_ERROR:
      return {
        ...state,
        validation: false,
        validationError: true,
        validationMsg: action.data
      };
    case userConstants.USER_LOADED:
      localStorage.setItem("token", action.data.token.access_token);
      return {
        ...state,
        user: action.data,
        isLoading: false,
        isAuthenticated: true
      };

    case userConstants.AUTH_ERROR:
      localStorage.removeItem("token");
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        user: null,
        token: null
      };

    case userConstants.LIST_USERS:
      return {
        ...state,
        listUsers: action.data.list_user,
        userAll: action.data.total,
        isFeching: false
      };

    case userConstants.LIST_USERS_UPDATE:
      return {
        ...state,
        listUsers: action.data,
        status: true,
        isFeching: false
      };

    case userConstants.LIST_COUNTRY:
      return {
        ...state,
        listCountry: action.data
      };

    case userConstants.LIST_INSURANCE:
      return {
        ...state,
        listInsurance: action.data
      };
    default:
      return state;
  }
}
