import React, { useEffect } from "react";
import { renderField } from "../Forms/renderField";

import { Field, reduxForm, formValueSelector } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import { onSubmitFilter } from "./onSubmit";
import { connect } from "react-redux";

moment.locale("es");

let detailsFormDoctor = (props) => {
  const {
    handleSubmit,
    change,
    listCountry,
    listInsurance,
    ageStartValue,
  } = props;

  useEffect(() => {
    change("type", props.type);
    /*change("country", null);
    change("type", "all");
    change("insurance", null);
    change("age_start", 0);
    change("typeNotification", "all");
    change("age_end", 0);*/
  }, []);

  const last_conection_select = [
    {
      value: null,
      title: "Todos",
    },
    {
      value: "24h",
      title: "24 horas",
    },
    {
      value: "48h",
      title: "48 horas",
    },
    {
      value: "1w",
      title: "1 semana",
    },
    {
      value: "+1w",
      title: "Más de 1 semana",
    },
  ];
  const last_conection = [];
  const age = [];
  for (let index = 0; index < 130; index++) {
    age.push(
      <option value={index} key={"age" + index + 1}>
        {index}
      </option>
    );
  }
  for (let index = 0; index < last_conection_select.length; index++) {
    last_conection.push(
      <option
        value={last_conection_select[index].value}
        key={"last_conection" + index + 1}
      >
        {last_conection_select[index].title}
      </option>
    );
  }

  const ageFunctions = () => {
    let age_end = [];

    for (let index = ageStartValue; index < 130; index++) {
      age_end.push(
        <option value={index} key={"ageE" + index + 1}>
          {index}
        </option>
      );
    }

    return age_end;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container
        style={{
          display: "grid",
          zIndex: 2,
          gridTemplateColumns: "auto auto",
          gridColumnGap: 20,
        }}
      >
        {props.type === "user" && (
          <Row className="my-2">
            <Col>
              <div className="select2-input py-2">
                <Field
                  name="identificator"
                  component={renderField}
                  className="form-control"
                  type="text"
                  placeholder={"Novem ID"}
                />
              </div>
            </Col>
          </Row>
        )}
        <Row className="my-2">
          <Col>
            <div className="select2-input py-2">
              <Field
                name="name"
                component={renderField}
                type="text"
                className="form-control"
                placeholder={"Nombre y Apellido"}
              />
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <div className="select2-input py-2">
              <Field
                name="email"
                component={renderField}
                type="email"
                placeholder={"Email"}
                className="form-control"
              />
            </div>
          </Col>
        </Row>
        {props.type === "user" && (
          <Row className="my-2">
            <Col>
              <div className="select2-input py-2">
                <div className="form-group">
                  <label className="placeholder">
                    <b>Última conexión</b>
                  </label>
                  <div className="position-relative">
                    <Field
                      name={"last_conection"}
                      component={"select"}
                      className={"form-control"}
                    >
                      {last_conection}
                    </Field>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        {props.type === "user" && (
          <Row className="my-2">
            <Col>
              <div className="select2-input py-2">
                <div className="form-group">
                  <label className="placeholder">
                    <b>País</b>
                  </label>
                  <div className="position-relative">
                    <Field
                      name={"country"}
                      component={"select"}
                      className={"form-control"}
                    >
                      {listCountry &&
                        listCountry.map((item, i) => {
                          let alph = /^[a-zA-Z ñÑ]+$/;

                          if (alph.test(item) || item.length === 0) {
                            let value = item;
                            let title = item;
                            if (item.length === 0) {
                              value = null;
                              title = "Todos";
                            }
                            return (
                              <option value={value} key={i}>
                                {title}
                              </option>
                            );
                          } else return null;
                        })}
                    </Field>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Row className="my-2">
          <Col>
            <div className="select2-input py-2">
              <div className="form-group">
                <label className="placeholder">
                  <b>Edad</b>
                </label>
                <div className="position-relative">
                  <Row>
                    <Col>
                      <Field
                        name={"age_start"}
                        component={"select"}
                        className={"form-control"}
                      >
                        <option value={0} key={0}>
                          Sin mín.
                        </option>
                        {age}
                      </Field>
                    </Col>
                    <Col>
                      <Field
                        name={"age_end"}
                        component={"select"}
                        className={"form-control"}
                      >
                        <option value={0} key={0}>
                          Sin máx.
                        </option>

                        {ageFunctions()}
                      </Field>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </Col>
        </Row>

        {props.type === "user" && (
          <>
            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <div className="form-group">
                    <label className="placeholder">
                      <b>Seguro</b>
                    </label>
                    <div className="position-relative">
                      <Field
                        name={"insurance"}
                        component={"select"}
                        className={"form-control"}
                      >
                        <option value={null} key={0}>
                          Todos
                        </option>
                        {listInsurance &&
                          listInsurance.map((item, i) => {
                            return (
                              <option value={item.value} key={i}>
                                {item.label}
                              </option>
                            );
                          })}
                      </Field>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name="register_date"
                    component={renderField}
                    max={moment().format("YYYY-MM-DD")}
                    type="date"
                    placeholder={"Fecha de registro"}
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>{" "}
          </>
        )}
      </Container>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    listCountry: state.user.listCountry,
    listInsurance: state.user.listInsurance,
  };
};
detailsFormDoctor = reduxForm({
  form: "notificationForm",
  onSubmit: onSubmitFilter,
})(detailsFormDoctor);

const selector = formValueSelector("notificationForm");
detailsFormDoctor = connect((state) => {
  const ageStartValue = selector(state, "age_start");
  return {
    ageStartValue,
  };
})(detailsFormDoctor);

export default connect(mapStateToProps)(detailsFormDoctor);
