import React, { useEffect } from "react";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { Col, Container, Row } from "react-bootstrap";
import moment from "moment";
import "moment/locale/es";
import { onSubmitAll } from "./onSubmit";
import { connect } from "react-redux";
import { navigation } from "./notificationUser";
moment.locale("es");

const type = [
  { id: 1, name: "Todos", value: "all" },
  { id: 2, name: "Android", value: "android" },
  { id: 3, name: "iOS", value: "ios" },
];

const typeNotifycation = [
  { id: 1, name: "", value: "" },
  { id: 2, name: "Notificación", value: "notification" },
  { id: 3, name: "Sms", value: "sms" },
];

export const renderFieldSelect = ({
  input, 
  className,
  options,
  meta: { touched, error },
}) => {
  return (
    <div>
      <div>
        <select className={className} {...input}>
          {options.map((item, i) => (
            <option value={item.value} key={i}>
              {item.name}
            </option>
          ))}
        </select>

        {touched && error && <span>{error}</span>}
      </div>
    </div>
  );
};

let detailsFormDoctor = (props) => {
  const {
    handleSubmit, 
    typeNotification,
    change,
    listCountry,
    listInsurance,
    favoriteColorValue, 
  } = props;

  useEffect(() => {
    change("country", null);
    change("type", "all");
    change("insurance", null);
    change("age_start", 0);
    change("typeNotification", "all");
    change("age_end", 0);
  }, []);

  const age = [];
  for (let index = 0; index < 130; index++) {
    age.push(
      <option value={index} key={"age" + index + 1}>
        {index}
      </option>
    );
  }

  const ageFunctions = () => {
    let age_end = [];

    for (let index = favoriteColorValue; index < 130; index++) {
      age_end.push(
        <option value={index} key={"ageE" + index + 1}>
          {index}
        </option>
      );
    }

    return age_end;
  };

  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <Row className="my-2">
          <Col>
            <label>Sistema operativo</label>
            <div className="select2-input py-2">
              <Field
                name="type"
                component={renderFieldSelect}
                className="form-control"
                options={type}
              />
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <label>Tipo de notificación</label>
            <div className="select2-input py-2">
              <Field
                name="typeNotification"
                component={renderFieldSelect}
                className="form-control"
                options={typeNotifycation}
              />
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <label>Pais</label>
            <div className="select2-input py-2">
              <Field
                name={"country"}
                component={"select"}
                className={"form-control"}
              >
                {listCountry &&
                  listCountry.map((item, i) => {
                    let alph = /^[a-zA-Z ñÑ]+$/;

                    if (alph.test(item) || item.length === 0) {
                      let value = item;
                      let title = item;
                      if (item.length === 0) {
                        value = null;
                        title = "Todos";
                      }
                      return (
                        <option value={value} key={i}>
                          {title}
                        </option>
                      );
                    } else return null;
                  })}
              </Field>
            </div>
          </Col>
        </Row>
        <Row className="my-2">
          <Col>
            <label>Edad</label>
            <Row>
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name={"age_start"}
                    component={"select"}
                    className={"form-control"}
                  >
                    <option value={0} key={0}>
                      Sin mín.
                    </option>
                    {age}
                  </Field>
                </div>
              </Col>
              <Col>
                <div className="select2-input py-2">
                  <Field
                    name={"age_end"}
                    component={"select"}
                    className={"form-control"}
                  >
                    <option value={0} key={0}>
                      Sin máx.
                    </option>

                    {ageFunctions()}
                  </Field>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="my-2">
          <Col>
            <label>Seguro</label>
            <div className="select2-input py-2">
              <Field
                name={"insurance"}
                component={"select"}
                className={"form-control"}
              >
                <option value={null} key={0}>
                  Todos
                </option>
                {listInsurance &&
                  listInsurance.map((item, i) => {
                    return (
                      <option value={item.value} key={i}>
                        {item.label}
                      </option>
                    );
                  })}
              </Field>
            </div>
          </Col>
        </Row>

        {typeNotification === "notification" && (
          <>
            <Row className="my-2">
              <Col>
                <label>Navegación</label>
                <div className="select2-input py-2">
                  <Field
                    name="navigate"
                    component={renderFieldSelect}
                    className="form-control"
                    options={navigation}
                  />
                </div>
              </Col>
            </Row>
            <Row className="my-2">
              <Col>
                <label>Título</label>
                <div className="select2-input py-2">
                  <Field
                    name="title"
                    component="input"
                    type="text"
                    placeholder="Título"
                    className="form-control"
                  />
                </div>
              </Col>
            </Row>
          </>
        )}
        <Row className="my-2">
          <Col>
            <label>Mensaje de la Notificación</label>
            <div className="select2-input py-2">
              <Field
                name="message"
                component="textarea"
                type="text"
                placeholder="Mensaje"
                className="form-control"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    listCountry: state.user.listCountry,
    listInsurance: state.user.listInsurance,
    navegationData: state.navegation.data,
  };
};
/*
export default compose(
  connect(mapStateToProps),
  reduxForm({ form: "notificationForm", onSubmit: onSubmitAll })
)(detailsFormDoctor);*/

detailsFormDoctor = reduxForm({
  form: "notificationForm",
  onSubmit: onSubmitAll,
})(detailsFormDoctor);

const selector = formValueSelector("notificationForm");
detailsFormDoctor = connect((state) => {
  const favoriteColorValue = selector(state, "age_start");
  const typeNotification = selector(state, "typeNotification");
  return {
    favoriteColorValue,
    typeNotification,
  };
})(detailsFormDoctor);

export default connect(mapStateToProps)(detailsFormDoctor);
