import React, {Component} from "react";
import PanelHeader from "../../PanelHeader";
import Table from "./table";

export default class index extends Component {
  render() {
    return (
      <div className="content">
        <div className="panel-header bg-primary-gradient">
          <PanelHeader />
        </div>
        <div className="page-inner mt--5">
          <Table />
        </div>
      </div>
    );
  }
}
