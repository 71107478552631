import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Container from "../Container";
import { listUsers } from "../../actions/user_actions";
import { listMedicals } from "../../actions/medical_actions";
import { init } from "../../actions";
const PrivateRoute = ({
  component: Component,
  auth,
  listUsers,
  listMedicals,
  init,
  token,
  pathValidate,
  ...rest
}) => {
  useEffect(() => {
    if (auth.isAuthenticated) {
      init();
    }
  }, [auth.isAuthenticated]);
  const permissionsValidate = Search => {
    let permissions = auth.user.user.permissions;
    let validate = permissions.findIndex(value => {
      return value.module === Search;
    });

    return validate;
  };

  return (
    <Route
      {...rest}
      render={props => {
        if (auth.isLoading && token !== null) {
          return <div>Loading...</div>;
        } else if (!auth.isAuthenticated || token === null) {
          return <Redirect to="/login" />;
        } else if (
          permissionsValidate(pathValidate.slice(1)) < 0 &&
          pathValidate.slice(1) !== "Main"
        ) {
          return <Redirect to="/Main" />;
        } else {
          return (
            <Container>
              <Component {...props} />;
            </Container>
          );
        }
      }}
    />
  );
};

const mapStateToProps = state => ({
  auth: state.user,
  token: state.user.token
});

const mapDispatchToProps = { listUsers, listMedicals, init };

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
