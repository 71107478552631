import React  from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
const notificationmessage = ({ token, successToken }) => {
  return (
    <div>
      {successToken
        ? "Enviada con exito"
        : "Enviando notificacion, por favor espere"}
    </div>
  );
};

function mapStateToProps(state, ownProps) {
  return {
    token: state.stadistics.token,
    successToken: state.stadistics.successToken
  };
}

export default compose(
  withRouter,
  connect(mapStateToProps)
)(notificationmessage);
