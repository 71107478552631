import { navegationConstants } from "../constansts/navegation.constants";
import {
  loading,
  getSuccess,
  getFailure, 
} from "../actions/modal_actions";

import * as api from "../api/api_admin";

export const getNavigateAll = data => {
  return { type: navegationConstants.NAVIGATION_ALL, data };
};

export const NavegationAll = () => {
  let url = "navegation/all";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestToken(url, token);

    request.then(result => {
      try {
        if (result.status === 200) {
          dispatch(getNavigateAll(result.data));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const navegationSave = body => {
  let url = "navegation/save";

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(body, url, token);
    request.then(result => {
      try {
        if (result.status === 200) {
          let navegationList = [...getState().navegation.data];

          navegationList.push(result.data);

          dispatch(getNavigateAll(navegationList));
          dispatch(getSuccess());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {}
    });
  };
};

export const deleteNavegation = body => {
  let url = "navegation/delete";

  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;
    const request = api.requestOfUserAdmin(body, url, token);
    request.then(result => {
      try {
        if (result.status === 200) {
          let navegationList = [...getState().navegation.data];

          let resultNavegation = navegationList.findIndex(value => {
            return value._id === result.data._id;
          });

          navegationList.splice(resultNavegation, 1);

          dispatch(getNavigateAll(navegationList));
          dispatch(getSuccess());
        }
      } catch (e) {}
    });
  };
};
