import * as api from "../api/api_admin";
import {transactionUserConstants} from "../constansts/transaction.user.constants";
import {loading, getSuccess, getFailure} from "../actions/modal_actions";

export const getTransaction = (data) => {
  return {type: transactionUserConstants.TRANSTATION_LIST_USER, data};
};

export const transactionAllUser = () => {
  let url = "transaction-consultation-online/all";
  return (dispatch, getState) => {
    let token = getState().user.token;
    const request = api.requestToken(url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          dispatch(getTransaction(result.data));
        } else {
          console.log("error 500", result.msg);
        }
      } catch (e) {
        console.log("error servidor", e);
      }
    });
  };
};

export const transactionApproveOrRejectUser = (body, type) => {
  let url = "transaction-consultation-online/" + type;

 
  return (dispatch, getState) => {
    dispatch(loading());
    let token = getState().user.token;

    const request = api.requestOfUserAdmin(body, url, token);

    request.then((result) => {
      try {
        if (result.status === 200) {
          let noticeList = [...getState().transactionuser.data];
          let status;
          switch (type) {
            case "approve":
              status = "approved";

              break;
            case "reject":
              status = "rejected";

              break;

            default:
              status = "Por definir";
              break;
          }

          let resultNotice = noticeList.findIndex((value) => {
            return value._id === body.transaction_id;
          });

          noticeList[resultNotice].status = status;

          dispatch(getTransaction(noticeList));

          dispatch(getSuccess());
        } else {
          dispatch(getFailure(result.msg));
        }
      } catch (e) {
        dispatch(getFailure(e));
      }
    });
  };
};
