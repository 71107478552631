import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ModalGlobal from "../Modals";

import { openModal } from "../../actions/modal_actions";
import { NoticeAllInit } from "../../actions/notice_actions";
import { faTrash, faCheck, faBan } from "@fortawesome/free-solid-svg-icons";

import Form from "./form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SuspendActiveDeleteNotice from "./suspendActiveNotice";
import moment from "moment";
import { GridLoader } from "react-spinners";
import { css } from "@emotion/core";
import { permissionsValidate } from "../../functions";

const override = css`
  display: inline-block;
  margin: 0 auto;
`;

const ButtonIcon = (props) => {
  return (
    <button
      onClick={props.onClick}
      type="button"
      data-toggle="tooltip"
      title={props.title}
      className="btn btn-link btn-primary btn-lg"
    >
      <FontAwesomeIcon icon={props.name} size="lg" color={props.color} />
    </button>
  );
};

const NewsTable = ({
  permissionsArray,
  NoticeAllInit,
  success,
  data,
  openModal,
}) => {
  const [dataNotice, setdataNotice] = useState([]);
  const [item, setItem] = useState(null);
  const [typeModal, settypeModal] = useState(null);
  const [permissions, setpermissions] = useState([]);

  let result = -1;
  const buttonData = [
    {
      title: "Activar",
      type: "active",
      status: "active",
      name: faCheck,
      color: "#009688",
      itemClassName: "modal-dialog",
    },
    {
      title: "Suspender",
      type: "suspend",
      status: "suspended",
      name: faBan,
      color: "#e8982c",
      itemClassName: "modal-dialog",
    },
    {
      title: "Eliminar",
      type: "delete",
      status: "delete",
      name: faTrash,
      color: "#be2020",
      itemClassName: "modal-dialog",
    },
  ];

  useEffect(() => {
    if (permissions.length === 0) {
      let searchModule = permissionsValidate(permissionsArray, "notice");
      if (searchModule >= 0)
        setpermissions(permissionsArray[searchModule].action);
    }
    if (success || data.length !== dataNotice.length) {
      setdataNotice(data);
    } else {
      NoticeAllInit();
    }
  }, [data, success]);

  const bodyModal = () => {
    if (typeModal && typeModal.type)
      switch (typeModal.type) {
        case "newNotice":
          return <Form />;
        case "active":
          return <SuspendActiveDeleteNotice data={item} type={typeModal} />;
        case "suspend":
          return <SuspendActiveDeleteNotice data={item} type={typeModal} />;
        case "delete":
          return <SuspendActiveDeleteNotice data={item} type={typeModal} />;
        default:
          break;
      }
  };
  if (dataNotice.length > 0) {
    result = dataNotice.findIndex((value) => {
      return value.deleted_at === false;
    });
  }

  return (
    <div className="col-md-12">
      <div className="card">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <h4 className="card-title">Noticias</h4>
            {permissions.includes("save") && (
              <div className=" ml-auto">
                <button
                  className="btn btn-primary btn-round ml-auto"
                  onClick={() => {
                    settypeModal({ type: "newNotice" });
                    openModal({
                      title: "Crear noticia",
                      itemClassName: "modal-lg",
                    });
                  }}
                >
                  <i className="fa fa-plus"></i>
                  Nueva noticia
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="card-body">
          {!success ? (
            <div className="GridLoader">
              <GridLoader
                size={20}
                css={override}
                color={"#19769F"}
                loading={true}
              />
            </div>
          ) : (
            <div className="table-responsive">
              <table id="add-row" className="display table table-striped  ">
                <thead>
                  <tr>
                    <th className="text-center">Titulo</th>
                    <th className="text-center">Tipo</th>
                    <th className="text-center">Fecha</th>
                    <th className="text-center">Vista</th>
                    <th className="text-center">Status</th>
                    {permissions.includes("status_update") && (
                      <th className="text-center">Acciones</th>
                    )}
                  </tr>
                </thead>

                <tbody>
                  {dataNotice.length > 0 && result > -1 ? (
                    dataNotice.map((data, index) => {
                      if (!data.deleted_at) {
                        let status;
                        switch (data.status) {
                          case "active":
                            status = "Activado";

                            break;
                          case "suspended":
                            status = "Suspendido";

                            break;

                          default:
                            status = "Por definir";
                            break;
                        }
                        return (
                          <tr key={index}>
                            <td className="text-center">{data.title}</td>
                            <td className="text-center">{data.type_notice}</td>
                            <td className="text-center">
                              {moment(data.created_at).format("DD-MM-YYYY")}
                            </td>
                            <td className="text-center">{data.view}</td>
                            <td className="text-center">{status}</td>
                            <td className="text-center">
                              {buttonData.map((value, index) => {
                                if (data.status !== value.status)
                                  return (
                                    <ButtonIcon
                                      key={index}
                                      name={value.name}
                                      title={value.title}
                                      color={value.color}
                                      onClick={() => {
                                        settypeModal({
                                          type: value.type,
                                          title: value.title,
                                        });
                                        setItem(data);
                                        openModal({
                                          title: value.title,
                                          itemClassName: "modal-dialog",
                                        });
                                      }}
                                    />
                                  );
                                else return null;
                              })}
                            </td>
                          </tr>
                        );
                      } else return null;
                    })
                  ) : (
                    <tr>
                      <td colSpan={6} className="text-center">
                        No hay noticias actualmente
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
      <ModalGlobal>{bodyModal()}</ModalGlobal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.notice.data,
  success: state.notice.success,
  permissionsArray: state.user.user.user.permissions,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ NoticeAllInit, openModal }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(NewsTable);
